import React from "react";
import Classes from "../Classes";

class ColorSettings extends React.Component {

    render = () => {
        const node = this.props.node;
       return <div className="d-flex align-items-center">
           <input
               type="color"
               name="color" group="style"
               onChange={this.props.edit}
               onBlur={this.props.save}
               value={node.style?.color ? node.style.color : ""} />
               <label className="btn btn-sm btn-danger">
                   <input name="color" group="style" value={false} onChange={this.props.save}
                        style={{visibility: "hidden", width: 2}} type="checkbox" />
                   <i className="fa fa-close" />
               </label>
           <div> &nbsp; ili &nbsp; </div>
           <Classes
               name="fontColor"
               classes={node.classes}
               onChange={this.props.save} />
       </div>
    };
}

export default ColorSettings;
