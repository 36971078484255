import React from "react";
import LiLink from "../../../views/elements/LiLink";
import routes from "../../../app/routes";
import {logoutUser} from "../../../providers/userProvider";


class LeftMenu extends React.Component {

    logout = (e) => {
        e.preventDefault();
        logoutUser(true);
        this.props.setUser(false);
    };

    render = () => {
        return <div>
            <div className="builder_title mb-3">
                <span> MOJ NALOG </span>
            </div>
            <div className="link_button">
                <div>
                    <LiLink to={routes.userDashboard.route}>Informacije o nalogu</LiLink>
                </div>
                <div>
                    <LiLink to={routes.userOrders.route}>Porudžbine</LiLink>
                </div>
                <div>
                    <LiLink to={routes.userNewsletter.route}>Prijave za newsletter</LiLink>
                </div>
                <div className="mb-2">
                    <button className="btn btn-link btn-block text-decoration-none text-left" onClick={this.logout}>
                        Izlogujte se
                    </button>
                </div>
            </div>
        </div>
    }
}

export default LeftMenu;