import React from "react";
import {storage} from "../../../providers/helpers";
import {getProducts} from "../../../providers/hooks";
import routes from "../../../app/routes";
import {Link} from "react-router-dom";


class RenderOptionProduct extends React.Component {

    state = {
        product: false
    }

    render = () => {
        if(!this.state.product) return "";
        return <Link to={routes.singleProduct.route(this.state.product)} target="_blank">
            <div className="d-flex align-items-center">
                <div style={{width: 80}} className="mr-2">
                    <div className="bcg_image" style={{backgroundImage: `url(${storage(this.state.product.image_name)})`, backgroundSize: "cover"}} />
                </div>

                {this.state.product.name}
            </div>
        </Link>
    };

    componentDidMount() {
        getProducts({ids: [this.props.item.name]}).then((response) => {
            this.setState({...this.state, product: response.data.data[0]});
        });
    }
}
export default RenderOptionProduct;
