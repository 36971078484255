export const SET_GLOBALS = "SET GLOBALS";
export const INSERT_NODE = "INSERT GLOBALS NODE";

export function setGlobals(value) {
    return {
        type: SET_GLOBALS,
        payload: value
    }
}
export function insertGlobalsNode(value) {
    return {
        type: INSERT_NODE,
        payload: value
    }
}
