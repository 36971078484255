import React from 'react';
import {setSelected} from "../../store/actions/selectedActions";
import {connect} from "react-redux";
import {randString} from "../../providers/helpers";

class SettingsButton extends React.Component   {

    state = {
        id: randString()
    }

    render = () => {
        if(!this.props.admin) return <div id={"bb-" + this.state.id} />;
        if(this.props.preview) return <div id={"bb-" + this.state.id} />;
        return  <button
            id={"bb-" + this.state.id}
            className="btn btn-success btn-sm builder_settings_btn"
            title="Podešavanja"
            onClick={() => this.props.setSelected(this.props.path)}>
            <i className="fa fa-cog" />
        </button>
    };
    toggleClass = () => {
        if(this.props.admin && !this.props.preview) {
            document.getElementById("bb-" + this.state.id).parentNode.classList.add("builder_settings_hoverable");
        } else  {
            document.getElementById("bb-" + this.state.id).parentNode.classList.remove("builder_settings_hoverable");
        }
    }
    componentDidMount() {
        this.toggleClass();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.preview !== prevProps.preview)        {
            this.toggleClass();
        }
    }
}
const mapStateToProps = state => ({
    admin: state.admin,
    preview: state.preview
});
const mapDispatchToProps = {
    setSelected: setSelected
};
export default connect(mapStateToProps, mapDispatchToProps)(SettingsButton);
