import React from "react";
import Slider from "../Slider";

class SeoSettings extends React.Component {

    render = () => {
        const node = this.props.node;
       return <Slider open={this.props.open} header="SEO" className="mb-3">

           <div className="mb-3">
               Title:
               <input
                   name="seo_title"
                   onChange={this.props.edit}
                   onBlur={this.props.save}
                   type="text" className="form-control" value={node.seo_title || ""}
               />
           </div>

           <div className="mb-3">
               Description:
               <textarea
                   name="seo_description"
                   onChange={this.props.edit}
                   onBlur={this.props.save}
                  className="form-control" value={node.seo_description || ""}
               />
           </div>

           <div className="mb-3">
               Keywords:
               <textarea
                   name="seo_keywords"
                   onChange={this.props.edit}
                   onBlur={this.props.save}
                   className="form-control" value={node.seo_keywords || ""}
               />
           </div>
       </Slider>
    };
}

export default SeoSettings;
