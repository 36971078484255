import React from "react";
import {putOption} from "../../../providers/hooks";



class AddOption extends React.Component {

    state = {
        block: false,
        name: ""
    }

    add = () => {
        if(!this.state.name || this.state.name === "")    {
            alert("Morate uneti ime bloka.");
            return false;
        }
        putOption({name: this.state.name}).then((response) => {
            this.setState({...this.state, block: false, name: ""});
            this.props.reload();
        })
    }

    render = () => {
        return <div>

            {this.state.block &&
            <div className="dark_block mb-2">
                <strong> Unesite ime bloka: </strong>
                <div className="form-inline">
                    <input type="text" className="form-control mr-2" style={{minWidth: "50%"}}
                           onChange={(e) => this.setState({...this.state, name: e.target.value})}
                           value={this.state.name} />
                    <button
                        onClick={this.add}
                        className="btn btn-success"> Dodajte </button>
                </div>
            </div>}

            <button
                onClick={() => this.setState({...this.state, block: !this.state.block})}
                className="btn btn-primary">
                {this.state.block ? "Odustani" : "Dodajte novi blok"}
            </button>
        </div>
    };

}
export default AddOption;
