import React from "react";
import {patchPath} from "../../../providers/hooks";
import {storage} from "../../../providers/helpers";
import {filesDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";


class Image extends React.Component {

    state =  {
        imageName: this.props.value
    }

    change = (files) => {
        if(files.length < 1) return false;
        const file = files[0];
        this.setState({...this.state, imageName: file.name});
        if(this.props.path)     {
            patchPath({path: this.props.path, value: file.id}).then((response) => {

            })
        }
        if(this.props.onChange)    {
            this.props.onChange({target: {name: this.props.name, value: file.id}});
        }
    }

    render = () => {
        return <>
            <img
                onClick={() => this.props.filesDrawer({status: true, callback: this.change})}
                src={storage(this.state.imageName)}
                alt={this.state.imageName} className="img-fluid" />
            </>
    };
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    filesDrawer: filesDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(Image);
