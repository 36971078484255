import React from "react";
import {storage} from "../../providers/helpers";


class Popup extends React.Component {

    render = () => {
        let classname = "";
        if(this.props.node.classes)     {
            classname += Object.values(this.props.node.classes).join(" ");
        }
        const mobileSrc = this.props.node.mobileSrc || this.props.node.src;
        const backgroundSize = this.props.backgroundSize || "cover";
        const backgroundPosition = this.props.backgroundPosition || "center";
        const paddingTop = this.props.paddingTop || 100;
        return <div className={classname}>
            <div className="bcg_image d-none d-md-block" onClick={() => this.props.onClick(this.props.position)}
                 style={{backgroundImage: `url(${storage(this.props.node.src, this.props.node.big)})`,
                     backgroundSize: backgroundSize, backgroundPosition: backgroundPosition, paddingTop: paddingTop + "%"}} />
            <div className="bcg_image d-md-none"  onClick={() => this.props.onClick(this.props.position)}
                 style={{backgroundImage: `url(${storage(mobileSrc)})`,
                     backgroundSize: backgroundSize, backgroundPosition: backgroundPosition, paddingTop: paddingTop + "%"}} />
        </div>
    };

}
export default Popup;
