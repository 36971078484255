import React from "react";
import { useHistory } from "react-router-dom";

function LiLink(props) {
    const history = useHistory();

    function handleClick(e, href) {
        e.preventDefault();
        let delay = props.delay ? props.delay : 15;
        setTimeout(() => {
            history.push(href);
            if(props.onClick)   {
                props.onClick();
            }
        }, delay);
    }

    let spread = {};
    spread.href = props.to;
    spread.onClick = (e) => handleClick(e, spread.href);
    spread.className = props.className ? props.className : "";
    return (
        <a {...spread}>
            {props.children}
        </a>
    );
}
export default LiLink;
