import React from "react";
import MarginSettings from "../../fragments/settings/MarginSettings";

class MapSet extends React.Component {

    render = () => {
        const node = this.props.node;
        return <>
            <div className="mb-3">
                Visina:
                <input
                    name="height"
                    onChange={this.props.edit}
                    onBlur={this.props.save}
                    type="text" className="form-control" value={node.height ? node.height : ""}
                    placeholder="" />
            </div>
            <div className="mb-3">
                Zoom:
                <input
                    name="zoom"
                    onChange={this.props.edit}
                    onBlur={this.props.save}
                    type="text" className="form-control" value={node.zoom ? node.zoom : ""}
                    placeholder="" />
            </div>
            <div className="mb-3">
                Latitude:
                <input
                    name="latitude"
                    onChange={this.props.edit}
                    onBlur={this.props.save}
                    type="text" className="form-control" value={node.latitude ? node.latitude : ""}
                    placeholder="" />
            </div>
            <div className="mb-3">
                Longitude:
                <input
                    name="longitude"
                    onChange={this.props.edit}
                    onBlur={this.props.save}
                    type="text" className="form-control" value={node.longitude ? node.longitude : ""}
                    placeholder="" />
            </div>
            <MarginSettings edit={this.props.edit} save={this.props.save} node={this.props.node}/>
        </>
    };

}

export default MapSet;
