import React from "react";
import MarginSettings from "../../../fragments/settings/MarginSettings";

class OrdersSet extends React.Component {

    render = () => {
        return <>
            <MarginSettings edit={this.props.edit} save={this.props.save} node={this.props.node}/>
        </>
    };

}

export default OrdersSet;
