import React from "react";
import Loader from "../../elements/Loader";
import {getCategories, postCategoriesOrder} from "../../../providers/hooks";
import Nestable from "react-nestable";
import CategoriesItem from "./CategoriesItem";
import AddCategory from "./AddCategory";


class Categories extends React.Component {

    state = {
        loading: true,
        categories: []
    }

    changedOrder = (items) => {
        postCategoriesOrder({items: items}).then((response) => {
            this.load();
        })
    }

    collapseIcons = ({ isCollapsed }) => {
        if(isCollapsed) return <span className="nestable_icon"><i className="fa fa-plus" /></span>
        return <span className="nestable_icon"><i className="fa fa-minus" /></span>;
    };

    renderItem = (props) => {
        return <CategoriesItem {...props} reload={this.load} />
    };

    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }

        return <div className="container mt-5 mb-5">
            <div className="mb-3">
                <AddCategory reload={this.load} />
            </div>

            <Nestable
                collapsed={true}
                maxDepth={3}
                onChange={this.changedOrder}
                renderCollapseIcon={this.collapseIcons}
                handler={<span className="nestable_icon"><i className="fa fa-arrows" /></span>}
                items={this.state.categories}
                renderItem={this.renderItem} />
        </div>
    };

    load = () => {
        getCategories({}).then((response) => {
            this.setState({...this.state, loading: false, categories: response.data});
        });
    }

    componentDidMount() {
        this.load();
    }
}

export default Categories;
