import React from "react";
import {connect} from "react-redux";
import SettingsButton from "../../../fragments/SettingsButton";
import Looper from "../../../Looper";
import {getUserOrders} from "../../../../providers/hooks";
import LeftMenu from "../LeftMenu";
import Loader from "../../../../views/elements/Loader";
import {order_status} from "../../../../providers/orderOptions";
import moment from "moment";
import routes from "../../../../app/routes";
import LiLink from "../../../../views/elements/LiLink";
import Price from "../../../theme/Price";
const sqlFrmat = 'Do MMMM YYYY, HH:mm';


class Orders extends React.Component {

    state = {
        loading: true,
        orders: {},
        form: {page: 1}
    };


    render = () => {
        return <div>
                <SettingsButton path={this.props.path} />

            <div className="row">
                <div className="col-md-3">
                    <LeftMenu />
                </div>
                <div className="col-md-9">
                    <Looper path={`${this.props.path}.title`} />

                    <div className="mb-5">
                        {this.state.loading
                            ? <Loader height={300} />
                            : this.state.orders.data.length === 0
                                ? <div> Nemate porudžbina </div>
                                : <table className="table-bordered table">
                                    <tbody>
                                    {this.state.orders.data.map((item) => {
                                        const status = order_status(item.status);
                                        return <tr key={item.id}>
                                            <td>
                                                <LiLink to={routes.orderStatus.route(item.code)}>
                                                    <div className="mb-2"> Šifra: <strong>#{item.code}</strong></div>
                                                </LiLink>
                                                <div className="mb-2">
                                                    <strong>Cena: <Price price={item.full_price} /></strong>
                                                </div>

                                                {moment.utc(item.created_at).local().format(sqlFrmat)}
                                            </td>
                                            <td>
                                                <strong>{item.address}</strong> <br />
                                                {item.zip} {item.city}
                                                <div className="mt-2 d-md-none">
                                                    <div className="mb-2 text-center" style={{color: status.color}}>
                                                        {status.title}
                                                    </div>
                                                    <LiLink to={routes.orderStatus.route(item.code)} className="btn btn-primary btn-sm">
                                                        Detaljnije
                                                    </LiLink>
                                                </div>
                                            </td>
                                            <td width={120} className="align-middle d-none d-md-table-cell text-center">
                                                <div className="mb-2" style={{color: status.color}}>
                                                    {status.title}
                                                </div>
                                                <LiLink to={routes.orderStatus.route(item.code)}>
                                                    Detaljnije
                                                </LiLink>
                                            </td>
                                        </tr>
                                    })}
                                    </tbody>
                                </table>}
                    </div>
                </div>
            </div>
        </div>
    }

    componentDidMount() {
        getUserOrders().then((response) => {
            this.setState({...this.state, loading: false, orders: response.data});
        });
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Orders);