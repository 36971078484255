import React from "react";

function Page404(props) {
  return (
    <div className="container mt-5 mb-5 text-center font_4">
        Ne postoji stranica!
    </div>
  );
}

export default Page404;
