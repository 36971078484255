import React from "react";
import {connect} from "react-redux";
import {deletePath, getOptions} from "../../../providers/hooks";
import Loader from "../../elements/Loader";
import AddOption from "./AddOption";
import AddElement from "./AddElement";
import elements from "./elements/elements";
import Checkbox from "../../elements/edit/Checkbox";
import Input from "../../elements/edit/Input";
import Price from "../../../builder/theme/Price";
import {filesDrawer} from "../../../store/actions/drawersActions";


class Options extends React.Component {

    state = {
        loading: true,
        options: [],
        form: {
            page: 1
        }
    }

    delete = (id) => {
        var r = window.confirm("Želite da obrišete blok?");
        if (r !== true) {
            return false;
        }
        deletePath({path: "options." + id}).then((response) => {
            this.load();
        })
    }

    deleteElement = (id) => {
        var r = window.confirm("Želite da obrišete element?");
        if (r !== true) {
            return false;
        }
        deletePath({path: "option_elements." + id}).then((response) => {
            this.load();
        })
    }

    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }
        return <div className="container mt-5 mb-5">
            <div className="mb-3">
                <button
                    onClick={() => this.props.filesDrawer({status: true, bucket: "options"})}
                    className="btn btn-info mb-2">
                    <i className="fa fa-image" /> Uploadovane slike korisnika (za gift box...)
                </button>
                <AddOption reload={this.load} />
            </div>

            {this.state.options.length > 0
                ? this.state.options.map((item) => {
                return <div key={item.id} className="dark_block mb-2">
                    <div className="d-flex justify-content-between align-items-center border-bottom mb-3 pb-1">
                        <div className="font-weight-bold"> {item.name} </div>

                        <button onClick={() => this.delete(item.id)} className="btn btn-danger btn-sm">
                            Obrišite blok
                        </button>
                    </div>

                    {item.elements.map((element) => {
                        const El = elements[element.element].component;
                        return <div key={element.id} className="mb-3 p-2" style={{background: "#f6f1ff"}}>
                            <div className="mb-3">
                                <El item={element} admin={true} reload={this.load} deleteElement={this.deleteElement}
                                    edit={<>
                                        <Checkbox
                                            path={`option_elements.${element.id}.required`}
                                            checked={element.required} name="required"
                                            onChange={() => this.load()}
                                            appendTrue={<span>Polje je obavezno</span>}
                                            appendFalse={<span>Polje je obavezno</span>} />

                                        <div className="mb-2">
                                            Cena opcije:
                                            <Input
                                                nullable={true}
                                                price={true}
                                                path={`option_elements.${element.id}.price`}
                                                onBlur={() => this.load()}
                                                value={element.price} name="price"
                                                maxLength={199} autoComplete="off"  />
                                        </div>
                                    </>}
                                    show={<>
                                        <div className="mb-2">
                                            <strong>
                                                Polje {!element.required && "ni"}je obavezno
                                                <span className="red"> {element.required && "*"} </span>
                                            </strong>
                                        </div>
                                        <div> Cena: <strong><Price price={element.price} /> </strong> </div>

                                    </>}
                                    buttons={<>
                                        <div className="mb-1">
                                            <button onClick={() => this.deleteElement(element.id)} className="btn btn-danger btn-sm btn-block">Obrišite</button>
                                        </div>
                                        <AddElement
                                            title="Dodajte podelement"
                                            reload={this.load}
                                            parent={element.id} />
                                    </>}
                                />
                            </div>

                            {element.children.map((child) => {
                                const Child = elements[child.element].component;
                                return <div key={child.id}
                                    className="ml-5 pt-2 pb-2" style={{borderTop: "1px solid grey"}}>
                                <Child item={child} admin={true} reload={this.load}
                                       edit={<>
                                           <Checkbox
                                               path={`option_elements.${child.id}.required`}
                                               onChange={() => this.load()}
                                               checked={child.required} name="required"
                                               appendTrue={<span>Polje je obavezno</span>}
                                               appendFalse={<span>Polje je obavezno</span>} />

                                           <div className="mb-2">
                                               Cena opcije:
                                               <Input
                                                   nullable={true}
                                                   price={true}
                                                   path={`option_elements.${child.id}.price`}
                                                   onBlur={() => this.load()}
                                                   value={child.price} name="price"
                                                   maxLength={191} autoComplete="off"  />
                                           </div>
                                       </>}
                                       show={<>
                                           <div className="mb-2">
                                               <strong> Polje {!child.required && "ni"}je obavezno </strong>
                                           </div>
                                           <div> Cena: <strong><Price price={child.price} /> </strong> </div>

                                       </>}
                                       buttons={<>
                                           <div className="mb-1">
                                               <button onClick={() => this.deleteElement(child.id)} className="btn btn-danger btn-sm btn-block">Obrišite</button>
                                           </div>
                                       </>}
                                />
                                </div>
                            })}
                        </div>
                    })}
                    <AddElement item={item.id} reload={this.load} />
                </div>
                })
                : <div className="font-weight-bold"> Nema opcija </div>}

        </div>
    };

    load = (form) => {
        if(!form) form = this.state.form;
        getOptions(form).then((response) => {
            this.setState({...this.state, options: response.data, loading: false});
        })
    }

    componentDidMount() {
        this.load();
    }
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    filesDrawer: filesDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Options);
