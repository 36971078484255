import React from "react";
import {connect} from "react-redux";
import Loader from "../../../../views/elements/Loader";
import Page404 from "../../../../views/elements/Page404";
import SettingsButton from "../../../fragments/SettingsButton";
import {getBlog} from "../../../../providers/hooks";
import {Link} from "react-router-dom";
import routes from "../../../../app/routes";
import moment from "moment";
import RichTextEditor from "../../../../app/Editor/RichTextEditor";



class Product extends React.Component {

    state = {
        loading: false,
        blog: null,
    }


    render = () => {
        if(this.state.loading)      {
            return <Loader height={300} />
        }
        if(!this.state.blog)     {
            return <Page404 />
        }
        const blog = this.state.blog;

        return <div>
            <SettingsButton path={this.props.path} />

            <div className="mb-4 mt-3">
                <span className="mr-2">
                    <Link to={routes.index.route}>
                        Naslovna <i className="fa fa-angle-right ml-2" />
                    </Link>
                </span>
                <span className="mr-2">
                    <Link to={routes.blogs.route}>
                        Vesti <i className="fa fa-angle-right ml-2" />
                    </Link>
                </span>
                <span className="font_08">{blog.title}</span>
            </div>

            <h1 className="font_12 mb-3">
                {blog.title}
            </h1>

            <div className="gray mb-3">
                <i className="fas fa-calendar mr-2" aria-hidden="true" />
                {moment.utc(blog.created_at).local().format('DD.MM.YYYY HH:mm')}
            </div>


            <div>
                <RichTextEditor content={blog.content} readOnly={true} />
            </div>
        </div>
    };

    load = async () => {
        const id = this.props.match.params.id;
        if (!id) return false;
        this.setState({...this.state, loading: true, blog: false});
        getBlog({id: id}).then((response) => {
            this.setState({...this.state, loading: false, blog: response.data});
        })

    }
    componentDidMount() {
        this.load();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.match.params.id !== prevProps.match.params.id)            {
            this.load();
        }
    }
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(Product);
