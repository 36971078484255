import React from "react";

import {getPromoCodes, deletePath} from "../../../providers/hooks";
import moment from "moment";
import Loader from "../../elements/Loader";
import AddCategory from "./AddPromo";

class Promo extends React.Component {
    
    state = {
        loading: true,
        codes: {},
    }

    delete = (item) => {
        var r = window.confirm("Želite da obrišete promo kod?");
        if (r !== true) {
            return false;
        }
        deletePath({path: `promo_codes.${item.id}`}).then((response) => {
            this.load();
        });
    }

    renderItem = (item) => {
        return  <div key={item.id} className="dark_block mb-2">
            {item.expires_at && moment.utc(item.expires_at).local().isBefore(moment()) &&
            <div className="red font-weight-bold mb-3"> Kod je istekao. </div>}
            {(item.count < 1) &&
            <div className="red font-weight-bold mb-3"> Kod je iskorišćen. </div>}

            <div className="d-flex align-items-center">
                <div className="flex-grow-1">

                    <table className="promo_codes_table">
                        <tbody>
                            <tr>
                                <td colSpan={2}>
                                <h5 className="font-weight-bold">
                                    {item.code}
                                </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>popust:</td><td><span className="red font-weight-bold">{item.discount}%</span></td>
                            </tr>
                            <tr>
                            <td>preostalo:</td><td><b>{item.count || 0}</b></td>
                          
                            </tr>
                            <tr>
                            <td>kreirano:</td><td>{moment.utc(item.created_at).local().format('DD.MM.YYYY HH:mm')}</td>
                            </tr>
                            <tr>
                                <td>ističe:</td>
                                <td>
                                    {item.expires_at 
                                        ? moment.utc(item.expires_at).local().format('DD.MM.YYYY HH:mm')
                                        : 'Neograničeno'}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="me-2" style={{flex: "120px 0 0 "}}>
                <button
                    onClick={() => this.delete(item)}
                    className="btn btn-sm btn-danger ml-3">
                    <i className="fa fa-trash" />  Obrišite
                </button>
                </div>
            </div>
        </div>
    }

    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }
        return <div className="container mb-5 mt-5">
               <div className="mb-3">
                <AddCategory reload={this.load} />
            </div>
             {this.state.codes.length > 0
                ? <div>
                    {this.state.codes.map(item => {
                        return this.renderItem(item);
                    })}
                </div>
                : <strong> Nema kodova </strong>}
            </div>

    }

    load = () => {
        getPromoCodes().then((response) => {
            this.setState({...this.state, loading: false, codes: response.data});
        });
    }

    componentDidMount() {
        this.load();
    }
}
export default Promo;