import React from "react";
import {Route, Redirect} from "react-router-dom";
import { connect } from "react-redux";
import routes from "../app/routes";
import CmsLayout from "../views/cms/layout/CmsLayout";


const AdminMiddleware = ({ component: Component, layout: Layout, ...rest }) => {
    if (!Layout)  Layout = CmsLayout;
    return <Route {...rest} render={(props) => (
        rest.admin === false
            ? <Redirect to={routes.adminLogin.route} />
            : <Layout {...{component: Component, ...props}} />
    )}/>
};

const mapStateToProps = state =>    ({
    admin: state.admin
});

export default connect(mapStateToProps)(AdminMiddleware);
