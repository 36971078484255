import React from "react";
import Loader from "../../elements/Loader";
import {getOptions, putCategoryOption} from "../../../providers/hooks";


class OptionsModal extends React.Component {

    state = {
        loading: true,
        options: []
    }

    add = (id) => {
        putCategoryOption({category: this.props.category, option: id}).then((response) => {
            this.props.reload();
            this.props.close();
        })
    }

    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }

        return <div>
            {this.state.options.map((item) => {
                return <div key={item.id} className="font-weight-bold mb-3">
                    <button
                        onClick={() => this.add(item.id)}
                        className="btn btn-primary btn-block text-left">
                        {item.name}
                    </button>
                </div>
            })}
        </div>
    };

    load = () => {
        getOptions({}).then((response) => {
            this.setState({...this.state, loading: false, options: response.data});
        });
    }

    componentDidMount() {
        this.load();
    }
}

export default OptionsModal;
