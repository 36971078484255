import React from "react";
import {connect} from "react-redux";
import SettingsButton from "../../../fragments/SettingsButton";
import Looper from "../../../Looper";
import {deleteUser, getUser} from "../../../../providers/hooks";
import {setUser} from "../../../../store/actions/userActions";
import Field from "./Field";
import {logoutUser} from "../../../../providers/userProvider";
import LeftMenu from "../LeftMenu";


class Info extends React.Component {

    state = {
        user: {}
    };

    delete = () => {
        var r = window.confirm("Želite da obrišete nalog?");
        if (r !== true) {
            return false;
        }
        deleteUser({}).then((response) => {
            logoutUser(true);
        });
    }

    render = () => {
        return <div>
                <SettingsButton path={this.props.path} />

                <div className="row">
                    <div className="col-md-3">
                        <LeftMenu />
                    </div>
                    <div className="col-md-9">
                        <Looper path={`${this.props.path}.title1`} />

                        <div className="mb-5 p-3 user_bcg">
                            <div className="row">

                                <div className="col-md-6">
                                    <div className="mb-2">E-mail </div>
                                    <input
                                        name="email"
                                        onChange={this.changeField}
                                        value={this.state.user.email || ""}
                                        placeholder="E-mail"
                                        type="email"
                                        readOnly={true}
                                        className="form-control form-control"
                                    />
                                </div>

                                {[{field:'name', name:'Ime'},
                                    {field:'lastname', name:'Prezime'},
                                    {field:'phone', name:'Telefon'}].map((item, i) => {
                                    return <div key={i} className="col-md-6">
                                        <Field item={item} value={this.state.user[item.field]} />
                                    </div>
                                })}
                            </div>
                        </div>

                        <div className="mb-4">
                            <Looper path={`${this.props.path}.title2`} />
                        </div>

                        <div className="p-3 mb-5 user_bcg">
                            <div className="row">
                                {[{field:'address', name:'Vaša adresa'},
                                    {field:'zip', name:'Zip kod'},
                                    {field:'city', name:'Grad'},
                                ].map((item, i) => {
                                    return <div key={i} className="col-md-6">
                                        <Field item={item} value={this.state.user[item.field]} />
                                    </div>
                                })}
                            </div>
                        </div>

                        <div className="mb-4">

                        </div>

                        <div className="p-3 mb-5 bcg-2">
                            <button onClick={this.delete} className="btn btn-danger">
                                Obrišite nalog
                            </button>
                        </div>

                    </div>
                </div>
        </div>
    }

    componentDidMount() {
        getUser().then((response) => {
            this.setState({...this.state, user: response.data});
        });
    }
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    setUser: setUser
};
export default connect(mapStateToProps, mapDispatchToProps)(Info);