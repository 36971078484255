import React from "react";
import {patchPath} from "../../../providers/hooks";
import slugify from "slugify";
import {connect} from "react-redux";
import {openDrawer} from "../../../store/actions/drawersActions";

class Input extends React.Component {

    state = {
        value: this.props.value || "",
        changed: false
    }

    changed = (e) => {
        var val = e.target.value;
        if(this.props.slugify) {
            if(val[val.length-1] === " ") val = val + "-"
            val = slugify(val);
        }
        if(this.props.link) {
            val = val.replace("https:", "");
            val = val.replace("http:", "");
        }
        if(val === this.state.value) return false;
        this.setState({...this.state, value: val, changed: true});
        if(this.props.onChange)  {
            this.props.onChange(e);
        }
    }

    blured = (e) => {
        if(this.props.path && this.state.changed)     {
            var val = e.target.value;
            if(this.props.price)        {
                val = val.replace(" ", "").replace(",", ".");
            }
            if(this.props.nullable && val === "") val = null;
            patchPath({path: this.props.path, value: val}).then((response) => {
                this.setState({...this.state, changed: false});
                if(this.props.onBlur)   this.props.onBlur();
                this.props.openDrawer("alert");
            })
        }
    }

    keyDown = (e) => {
        let code = e.keyCode;
        if(code === 13)     {
            this.blured(e);
        }
    }

    render = () => {
        return <>
            <input
                disabled={this.props.disabled}
                value={this.state.value}
                placeholder={this.props.placeholder || ""}
                onChange={this.changed}
                onBlur={this.blured}
                onKeyDown={this.keyDown}
                name={this.props.name}
                type={this.props.type || "text"}
                maxLength={this.props.maxLength || ""}
                className={this.props.size ? "form-control form-control-" + this.props.size : "form-control"}
                autoComplete={this.props.autoComplete || ""}
                required={this.props.required || false} />
            </>
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.update !== this.props.update)      {
            let val = this.props.update;
            if(this.props.slugify) {
                val = slugify(val).toLowerCase();
            }
            this.setState({...this.state, value: val});
            if(this.props.onChange)  {
                this.props.onChange({target: {name: this.props.name, value: val}});
            }
        }
    }
}
const mapStateToProps = state => ({
    drawers: state.drawers
});
const mapDispatchToProps = {
    openDrawer: openDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Input);
