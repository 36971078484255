import React from 'react';


class Arrows extends React.Component {

    render = () => {
        return  <div className="arrows">
            {this.props.showLeft &&
            <button onClick={this.props.left} className="btn arrow_left">
                <i className="fa fa-chevron-left" />
            </button>}
            {this.props.showRight &&
            <button onClick={this.props.right} className="btn arrow_right">
                <i className="fa fa-chevron-right" />
            </button>}
        </div>
    };


}
export default Arrows;
