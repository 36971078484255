import React from "react";
import {connect} from "react-redux";
import Looper from "../Looper";


class Socials extends React.Component {

    render = () => {
        let classname = " text-center d-flex justify-content-center ";
        if(this.props.node.classes)     {
            classname += Object.values(this.props.node.classes).join(" ");
        }
        if(!this.props.node.classes?.buttonColor)       {
            classname += " ";
        }
        return <div className={classname}>
            {this.props.globals.structure.facebook &&
            <a href={this.props.globals.structure.facebook} className="mr-2" target="_blank" rel="noopener noreferrer">
                <Looper
                    path="globals.structure.socials facebook" />
            </a>}
            {this.props.globals.structure.instagram &&
            <a href={this.props.globals.structure.instagram} className="mr-2" target="_blank" rel="noopener noreferrer">
                <Looper
                    path="globals.structure.socials instagram" />
            </a>}
            {this.props.globals.structure.youtube &&
            <a href={this.props.globals.structure.youtube} className="mr-2" target="_blank" rel="noopener noreferrer">
                <Looper
                    path="globals.structure.socials youtube" />
            </a>}
            {this.props.globals.structure.twitter &&
            <a href={this.props.globals.structure.twitter} className="" target="_blank" rel="noopener noreferrer">
                <Looper
                    path="globals.structure.socials twitter" />
            </a>}
        </div>
    };

}
const mapStateToProps = state => ({
    globals: state.globals
});
export default connect(mapStateToProps)(Socials);
