
export function loginAdmin(response, remember = false) {
    if(!response.headers.authorization) {
        return false;
    }
    if(!response.headers.authorization.startsWith('Bearer '))    {
        return false;
    }
    var jwt = response.headers.authorization.replace('Bearer ', '');
    var expires = null;
    if(remember)       {
        expires = 30;
    }
    setCookie('ajwt', jwt, expires);
    let parsed = parseJwt(jwt);
    parsed['ajwt'] = jwt;
   return parsed;
}

export function checkAdmin() {
    var name = "ajwt=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            var jwt = c.substring(name.length, c.length);
            var parsed = parseJwt(jwt);
            parsed['ajwt'] = jwt;
            return parsed;
        }
    }
    return false;
}
export function logoutAdmin(restart = false) {
    setCookie('ajwt', '', -1);
    if(restart) window.location.href = "/";
}

function setCookie(name, data, exdays=null) {
    var expires = '';
    if(exdays !== null)     {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        expires = "expires="+ d.toUTCString() + ";";
    }
    document.cookie = name + "=" + data + ";" + expires + "path=/";
}

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}