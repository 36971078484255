import React from "react";
import MarginSettings from "../../fragments/settings/MarginSettings";
import DeleteNodeSettings from "../../fragments/settings/DeleteNodeSettings";
import {filesDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";

class InstagramSet extends React.Component {

    render = () => {
        return <>
            <div className="mb-3">
                <button
                    onClick={() => this.props.filesDrawer({status: true, callback:
                            (files) => this.props.save({target: {name: "bcgImage", value: files[0].name}})
                    })}
                    className="btn btn-sm btn-success">
                    <i className="fa fa-image mr-1" />  Promenite pozadinsku sliku
                </button>
            </div>

            <MarginSettings edit={this.props.edit} save={this.props.save} node={this.props.node}/>
            <DeleteNodeSettings save={this.props.save} node={this.props.node}/>
        </>
    };

}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    filesDrawer: filesDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(InstagramSet);
