import React from "react";
import Classes from "../Classes";
import Slider from "../Slider";
import ColorSettings from "./ColorSettings";

class TextSettings extends React.Component {

    render = () => {
        const node = this.props.node;
       return <Slider open={this.props.open} header="TEKST" className="mb-3">
           <div className="mb-3">
               <div className="font-weight-bold mb-1"> Unesite tekst: </div>
               <textarea
                    className="form-control"
                    name="text" value={node.text}
                    onChange={this.props.edit}
                    onBlur={this.props.save} />
           </div>

           <Classes
               className="mb-3"
               title="Veličina fonta:"
               name="fontSize"
               classes={node.classes}
               onChange={this.props.save} />

           <Classes
               className="mb-3"
               title="Pozicija teksta:"
               name="textAlign"
               classes={node.classes}
               onChange={this.props.save} />

           <Classes
               className="mb-3"
               title="Debljina teksta:"
               name="fontWeight"
               classes={node.classes}
               onChange={this.props.save} />

           <div>
               <div className="font-weight-bold mb-1"> Boja teksta: </div>
               <ColorSettings
                   edit={this.props.edit}
                   save={this.props.save}
                   node={node} />
           </div>
       </Slider>
    };
}

export default TextSettings;
