import React from "react";
import {connect} from "react-redux";
import GoogleMapReact from 'google-map-react';
import SettingsButton from "../../fragments/SettingsButton";

class Map extends React.Component {

    state = {
        loading: false
    }

    renderMarkers = (map, maps) => {
        const node = this.props.node;
        const lat = node.latitude ? parseFloat(node.latitude) : 43.890274;
        const lng = node.longitude ? parseFloat(node.longitude) : 20.350113;
        let marker = new maps.Marker({
            position: { lat: lat, lng: lng },
            map,
        });
        return marker;
    };

    render = () => {
        if(this.state.loading) return "";
        const node = this.props.node;
        const height = node.height ? parseInt(node.height) : 300;
        const lat = node.latitude ? parseFloat(node.latitude) : 43.890274;
        const lng = node.longitude ? parseFloat(node.longitude) : 20.350113;
        return <div style={{height: height}}>
            <SettingsButton path={this.props.path} />

            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyBEiSYtxrrCDlI6cobIM1bu81stEhA79hs"}}
                defaultCenter={{lat: lat, lng: lng}}
                defaultZoom={node.zoom ? parseInt(node.zoom) : 16}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}>

            </GoogleMapReact>

        </div>
    };

    componentDidMount() {

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.node.zoom !== prevProps.node.zoom || this.props.node.height !== prevProps.node.height
            || this.props.node.latitude !== prevProps.node.latitude || this.props.node.longitude !== prevProps.node.longitude)        {
            this.setState({...this.state, loading: true});
            setTimeout(() => {
                this.setState({...this.state, loading: false});
            }, 1);
        }
    }
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(Map);
