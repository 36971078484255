import React from "react";
import TextIconSettings from "../fragments/settings/TextIconSettings";
import LinkSettings from "../fragments/settings/LinkSettings";
import MarginSettings from "../fragments/settings/MarginSettings";
import PaddingSettings from "../fragments/settings/PaddingSettings";
import DeleteNodeSettings from "../fragments/settings/DeleteNodeSettings";
import TextSettings from "../fragments/settings/TextSettings";

class TextSet extends React.Component {

    render = () => {
        return <>
            <TextSettings edit={this.props.edit} save={this.props.save} node={this.props.node} open={true}/>
            <TextIconSettings edit={this.props.edit} save={this.props.save} node={this.props.node}/>
            <LinkSettings edit={this.props.edit} save={this.props.save} node={this.props.node}/>
            <MarginSettings edit={this.props.edit} save={this.props.save} node={this.props.node}/>
            <PaddingSettings edit={this.props.edit} save={this.props.save} node={this.props.node}/>
            <DeleteNodeSettings save={this.props.save} node={this.props.node}/>
        </>
    };

}

export default TextSet;
