import React from "react";
import {connect} from "react-redux";
import {setUser} from "../../../../store/actions/userActions";
import {patchUser} from "../../../../providers/hooks";
import {loginUser} from "../../../../providers/userProvider";


class Field extends React.Component {

    state = {
        value: "",
        error: {},
        success: "",
        disabled: true,
    }

    changeField = (e) => {
        const value = e.target.value;
        this.setState({...this.state, value: value, disabled: false});
    };

    submit = (e) => {
        e.preventDefault();
        this.setState({...this.state, disabled: true, error: {}, success: false});
        patchUser({field: this.props.item.field, value: this.state.value}).then((response) => {
            this.setState({...this.state, success: true, error: false});
            let user = loginUser(response);
            if(user) {
                this.props.setUser(user);
            }
            setTimeout(() => {
                this.setState({...this.state, success: false});
            }, 3000);
        }).catch((error) => {
            this.setState({...this.state, disabled: false, error: error, success: false});
        });
    }

    render = () => {
        const item = this.props.item;
        return <form action="#" method="POST" onSubmit={this.submit}>
            <div className="mb-2">{item.name}</div>
            <div className="input-group mb-3">
                <input
                    name={item.field}
                    onChange={this.changeField}
                    value={this.state.value}
                    placeholder={item.name}
                    type="text"
                    className="form-control form-control"
                />
                <div className="input-group-append">
                    <button disabled={this.state.disabled} type="submit" className="pl-3 pr-3 btn btn-success btn-sm">
                        <i className="fas fa-check" />
                    </button>
                </div>
            </div>

            {this.state.error[item.field] &&
            <div className="alert alert-danger">{this.state.error[item.field]}</div>}

            {this.state.error.message &&
            <div className="alert alert-danger">{this.state.error.message}</div>}

            {this.state.success &&
            <div className="alert alert-success">Prepravljeno.</div>}
        </form>
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.value)    {
            if(this.props.value !== prevProps.value)      {
                this.setState({...this.state, value: this.props.value});
            }
        }
    }
}
const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    setUser: setUser
};
export default connect(mapStateToProps, mapDispatchToProps)(Field);