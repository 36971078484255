import React from "react";
import Classes from "../Classes";
import Slider from "../Slider";

class MarginSettings extends React.Component {

    render = () => {
        const node = this.props.node;
       return <Slider header="MARGINE" className="mb-3">
           <Classes
               className="mb-3"
               title="Gornja margina:"
               name="marginTop"
               classes={node.classes}
               onChange={this.props.save} />

           <Classes
               className="mb-3"
               title="Donja margina:"
               name="marginBottom"
               classes={node.classes}
               onChange={this.props.save} />

           <Classes
               className="mb-3"
               title="Leva margina:"
               name="marginLeft"
               classes={node.classes}
               onChange={this.props.save} />

           <Classes
               title="Desna margina:"
               name="marginRight"
               classes={node.classes}
               onChange={this.props.save} />
       </Slider>
    };
}

export default MarginSettings;
