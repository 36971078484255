export default [
    {"name": "Srbija", "delivery_price": 380},
    {"name": "BIH (Republika Srpska i Federacija Bosne i Hercegovine)", "delivery_price": 1600},
    {"name": "Hrvatska", "delivery_price": 1600},
    {"name": "Crna Gora", "delivery_price": 1600},
    {"name": "Slovenija", "delivery_price": 1600},
    {"name": "Severna Makedonija", "delivery_price": 1600},
    {"name": "Nemačka", "delivery_price": 2300},


    {"name": "Jamajka", "delivery_price": 3000},
    {"name": "Japan", "delivery_price": 3000},
    {"name": "Jemen", "delivery_price": 3000},
    {"name": "Jermenija", "delivery_price": 3000},
    {"name": "Jordan", "delivery_price": 3000},
    {"name": "Južna Džordžija i Južna Sendvička Ostrva", "delivery_price": 3000},
    {"name": "Južna Koreja", "delivery_price": 3000},
    {"name": "Južni Sudan", "delivery_price": 3000},
    {"name": "Južnoafrička Republika", "delivery_price": 3000},
    {"name": "Džerzi", "delivery_price": 3000},
    {"name": "Džibuti", "delivery_price": 3000},
    {"name": "Avganistan", "delivery_price": 3000},
    {"name": "Azerbejdžan", "delivery_price": 3000},
    {"name": "Albanija", "delivery_price": 2300},
    {"name": "Alžir", "delivery_price": 3000},
    {"name": "Angvila", "delivery_price": 3000},
    {"name": "Angola", "delivery_price": 3000},
    {"name": "Andora", "delivery_price": 3000},
    {"name": "Antigva i Barbuda", "delivery_price": 3000},
    {"name": "Argentina", "delivery_price": 3000},
    {"name": "Aruba", "delivery_price": 3000},
    {"name": "Australija", "delivery_price": 3500},
    {"name": "Austrija", "delivery_price": 2300},
    {"name": "Bangladeš", "delivery_price": 3000},
    {"name": "Barbados", "delivery_price": 3000},
    {"name": "Bahami", "delivery_price": 3000},
    {"name": "Bahrein", "delivery_price": 3000},
    {"name": "Belgija", "delivery_price": 2300},
    {"name": "Belize", "delivery_price": 3000},
    {"name": "Belorusija", "delivery_price": 2300},
    {"name": "Benin", "delivery_price": 3000},
    {"name": "Bermuda", "delivery_price": 3000},
    {"name": "Božićno Ostrvo", "delivery_price": 3000},
    {"name": "Bolivija", "delivery_price": 3000},
    {"name": "Bocvana", "delivery_price": 3000},
    {"name": "Brazil", "delivery_price": 3000},
    {"name": "Britanska Devičanska Ostrva", "delivery_price": 3000},
    {"name": "Britanska teritorija Indijskog okeana", "delivery_price": 3000},
    {"name": "Brunej", "delivery_price": 3000},
    {"name": "Bugarska", "delivery_price": 2300},
    {"name": "Burkina Faso", "delivery_price": 3000},
    {"name": "Burundi", "delivery_price": 3000},
    {"name": "Butan", "delivery_price": 3000},
    {"name": "Valis i Futuna", "delivery_price": 3000},
    {"name": "Vanuatu", "delivery_price": 3000},
    {"name": "Vatikan", "delivery_price": 2300},
    {"name": "Venecuela", "delivery_price": 3000},
    {"name": "Vijetnam", "delivery_price": 3000},
    {"name": "Gabon", "delivery_price": 3000},
    {"name": "Gambija", "delivery_price": 3000},
    {"name": "Gana", "delivery_price": 3000},
    {"name": "Gvadelup", "delivery_price": 3000},
    {"name": "Gvatemala", "delivery_price": 3000},
    {"name": "Gvajana", "delivery_price": 3000},
    {"name": "Gvineja", "delivery_price": 3000},
    {"name": "Gvineja-Bisao", "delivery_price": 3000},
    {"name": "Gernzi", "delivery_price": 3000},
    {"name": "Gibraltar", "delivery_price": 3000},
    {"name": "Grenada", "delivery_price": 3000},
    {"name": "Grenland", "delivery_price": 3000},
    {"name": "Gruzija", "delivery_price": 3000},
    {"name": "Grčka", "delivery_price": 2300},
    {"name": "Danska", "delivery_price": 2300},
    {"name": "Dominika", "delivery_price": 3000},
    {"name": "Dominikanska Republika", "delivery_price": 3000},
    {"name": "Egipat", "delivery_price": 3000},
    {"name": "Ekvador", "delivery_price": 3000},
    {"name": "Ekvatorijalna Gvineja", "delivery_price": 3000},
    {"name": "Eritreja", "delivery_price": 3000},
    {"name": "Estonija", "delivery_price": 2300},
    {"name": "Etiopija", "delivery_price": 3000},
    {"name": "Zambija", "delivery_price": 3000},
    {"name": "Zapadna Sahara", "delivery_price": 3000},
    {"name": "Zelenortska Ostrva", "delivery_price": 3000},
    {"name": "Zimbabve", "delivery_price": 3000},
    {"name": "Izrael", "delivery_price": 3000},
    {"name": "Indija", "delivery_price": 3000},
    {"name": "Indonezija", "delivery_price": 3000},
    {"name": "Irak", "delivery_price": 3000},
    {"name": "Irska", "delivery_price": 2300},
    {"name": "Island", "delivery_price": 2300},
    {"name": "Italija", "delivery_price": 2300},
    {"name": "Kazahstan", "delivery_price": 3000},
    {"name": "Kambodža", "delivery_price": 3000},
    {"name": "Kamerun", "delivery_price": 3000},
    {"name": "Kanada", "delivery_price": 3000},
    {"name": "Karipska Holandija", "delivery_price": 3000},
    {"name": "Katar", "delivery_price": 3000},
    {"name": "Kajmanska Ostrva", "delivery_price": 3000},
    {"name": "Kenija", "delivery_price": 3000},
    {"name": "Kina", "delivery_price": 3000},
    {"name": "Kipar", "delivery_price": 2300},
    {"name": "Kirgistan", "delivery_price": 3000},
    {"name": "Kiribati", "delivery_price": 3000},
    {"name": "Kokosova (Kilingova) Ostrva", "delivery_price": 3000},
    {"name": "Kolumbija", "delivery_price": 3000},
    {"name": "Komorska Ostrva", "delivery_price": 3000},
    {"name": "Kongo - Brazavil", "delivery_price": 3000},
    {"name": "Kongo - Kinšasa", "delivery_price": 3000},
    {"name": "Kosovo", "delivery_price": 2300},
    {"name": "Kostarika", "delivery_price": 3000},
    {"name": "Kuvajt", "delivery_price": 3000},
    {"name": "Kukova Ostrva", "delivery_price": 3000},
    {"name": "Kurasao", "delivery_price": 3000},
    {"name": "Laos", "delivery_price": 3000},
    {"name": "Lesoto", "delivery_price": 3000},
    {"name": "Letonija", "delivery_price": 2300},
    {"name": "Liban", "delivery_price": 3000},
    {"name": "Liberija", "delivery_price": 3000},
    {"name": "Libija", "delivery_price": 3000},
    {"name": "Litvanija", "delivery_price": 2300},
    {"name": "Lihtenštajn", "delivery_price": 2300},
    {"name": "Luksemburg", "delivery_price": 2300},
    {"name": "Madagaskar", "delivery_price": 3000},
    {"name": "Malavi", "delivery_price": 3000},
    {"name": "Maldivi", "delivery_price": 3000},
    {"name": "Malezija", "delivery_price": 3000},
    {"name": "Mali", "delivery_price": 3000},
    {"name": "Malta", "delivery_price": 2300},
    {"name": "Maroko", "delivery_price": 3000},
    {"name": "Martinik", "delivery_price": 3000},
    {"name": "Mauritanija", "delivery_price": 3000},
    {"name": "Mauricijus", "delivery_price": 3000},
    {"name": "Mađarska", "delivery_price": 2300},
    {"name": "Majot", "delivery_price": 3000},
    {"name": "Meksiko", "delivery_price": 3000},
    {"name": "Mijanmar (Burma)", "delivery_price": 3000},
    {"name": "Mozambik", "delivery_price": 3000},
    {"name": "Moldavija", "delivery_price": 3000},
    {"name": "Monako", "delivery_price": 2300},
    {"name": "Mongolija", "delivery_price": 3000},
    {"name": "Monserat", "delivery_price": 3000},
    {"name": "Namibija", "delivery_price": 3000},
    {"name": "Nauru", "delivery_price": 3000},
    {"name": "Nepal", "delivery_price": 3000},
    {"name": "Niger", "delivery_price": 3000},
    {"name": "Nigerija", "delivery_price": 3000},
    {"name": "Nikaragva", "delivery_price": 3000},
    {"name": "Niue", "delivery_price": 3000},
    {"name": "Nova Kaledonija", "delivery_price": 3000},
    {"name": "Novi Zeland", "delivery_price": 3500},
    {"name": "Norveška", "delivery_price": 2300},
    {"name": "Obala Slonovače (Kot d’Ivoar)", "delivery_price": 3000},
    {"name": "Olandska Ostrva", "delivery_price": 3000},
    {"name": "Oman", "delivery_price": 3000},
    {"name": "Ostrva Turks i Kaikos", "delivery_price": 3000},
    {"name": "Ostrvo Asension", "delivery_price": 3000},
    {"name": "Ostrvo Buve", "delivery_price": 3000},
    {"name": "Ostrvo Man", "delivery_price": 3000},
    {"name": "Ostrvo Norfok", "delivery_price": 3000},
    {"name": "Ostrvo Herd i Mekdonaldova ostrva", "delivery_price": 3000},
    {"name": "Pakistan", "delivery_price": 3000},
    {"name": "Palestinske teritorije", "delivery_price": 3000},
    {"name": "Panama", "delivery_price": 3000},
    {"name": "Papua Nova Gvineja", "delivery_price": 3000},
    {"name": "Paragvaj", "delivery_price": 3000},
    {"name": "Peru", "delivery_price": 3000},
    {"name": "Pitkern", "delivery_price": 3000},
    {"name": "Portugalija", "delivery_price": 2300},
    {"name": "Poljska", "delivery_price": 2300},
    {"name": "Reinion", "delivery_price": 3000},
    {"name": "Ruanda", "delivery_price": 3000},
    {"name": "Rumunija", "delivery_price": 2300},
    {"name": "Rusija", "delivery_price": 3000},
    {"name": "SAR Makao (Kina)", "delivery_price": 3000},
    {"name": "SAR Hongkong (Kina)", "delivery_price": 3000},
    {"name": "Salvador", "delivery_price": 3000},
    {"name": "Samoa", "delivery_price": 3000},
    {"name": "San Marino", "delivery_price": 2300},
    {"name": "Sao Tome i Principe", "delivery_price": 3000},
    {"name": "Saudijska Arabija", "delivery_price": 3000},
    {"name": "Svazilend", "delivery_price": 3000},
    {"name": "Svalbard i Jan Majen", "delivery_price": 3000},
    {"name": "Sveta Jelena", "delivery_price": 3000},
    {"name": "Sveta Lucija", "delivery_price": 3000},
    {"name": "Sveti Bartolomej", "delivery_price": 3000},
    {"name": "Sveti Martin (Francuska)", "delivery_price": 2300},
    {"name": "Sveti Martin (Holandija)", "delivery_price": 2300},
    {"name": "Sen Pjer i Mikelon", "delivery_price": 3000},
    {"name": "Senegal", "delivery_price": 3000},
    {"name": "Sent Vinsent i Grenadini", "delivery_price": 3000},
    {"name": "Sent Kits i Nevis", "delivery_price": 3000},
    {"name": "Sejšeli", "delivery_price": 3000},
    {"name": "Singapur", "delivery_price": 3000},
    {"name": "Sijera Leone", "delivery_price": 3000},
    {"name": "Slovačka", "delivery_price": 2300},
    {"name": "Solomonska Ostrva", "delivery_price": 3000},
    {"name": "Somalija", "delivery_price": 3000},
    {"name": "Sudan", "delivery_price": 3000},
    {"name": "Surinam", "delivery_price": 3000},
    {"name": "Sjedinjene Američke Države", "delivery_price": 3500},
    {"name": "Tanzanija", "delivery_price": 3000},
    {"name": "Tajvan", "delivery_price": 3000},
    {"name": "Tajland", "delivery_price": 3000},
    {"name": "Tadžikistan", "delivery_price": 3000},
    {"name": "Timor-Leste (Istočni Timor)", "delivery_price": 3000},
    {"name": "Togo", "delivery_price": 3000},
    {"name": "Tokelau", "delivery_price": 3000},
    {"name": "Tonga", "delivery_price": 3000},
    {"name": "Trinidad i Tobago", "delivery_price": 3000},
    {"name": "Tristan da Kunja", "delivery_price": 3000},
    {"name": "Tuvalu", "delivery_price": 3000},
    {"name": "Tunis", "delivery_price": 3000},
    {"name": "Turkmenistan", "delivery_price": 3000},
    {"name": "Turska", "delivery_price": 2300},
    {"name": "Uganda", "delivery_price": 3000},
    {"name": "Udaljena ostrva SAD", "delivery_price": 3000},
    {"name": "Uzbekistan", "delivery_price": 3000},
    {"name": "Ukrajina", "delivery_price": 2300},
    {"name": "Urugvaj", "delivery_price": 3000},
    {"name": "Ujedinjeni Arapski Emirati", "delivery_price": 3000},
    {"name": "Ujedinjeno Kraljevstvo", "delivery_price": 2300},
    {"name": "Farska Ostrva", "delivery_price": 2300},
    {"name": "Filipini", "delivery_price": 3000},
    {"name": "Finska", "delivery_price": 2300},
    {"name": "Fidži", "delivery_price": 3000},
    {"name": "Foklandska Ostrva", "delivery_price": 3000},
    {"name": "Francuska", "delivery_price": 2300},
    {"name": "Francuska Gvajana", "delivery_price": 3000},
    {"name": "Francuska Polinezija", "delivery_price": 3000},
    {"name": "Francuske Južne Teritorije", "delivery_price": 3000},
    {"name": "Haiti", "delivery_price": 3000},
    {"name": "Holandija", "delivery_price": 2300},
    {"name": "Honduras", "delivery_price": 3000},
    {"name": "Centralnoafrička Republika", "delivery_price": 3000},
    {"name": "Čad", "delivery_price": 3000},
    {"name": "Češka", "delivery_price": 2300},
    {"name": "Čile", "delivery_price": 3000},
    {"name": "Švajcarska", "delivery_price": 2300},
    {"name": "Švedska", "delivery_price": 2300},
    {"name": "Španija", "delivery_price": 2300},
    {"name": "Šri Lanka", "delivery_price": 3000}
]