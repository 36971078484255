import React from "react";
import {elements} from "../../exports";
import {connect} from "react-redux";
import {closeDrawer} from "../../../store/actions/drawersActions";


class ElementsModal extends React.Component {

    selected = (item) => {
        this.props.drawer.callback(item.default);
        this.props.closeDrawer("elements");
    }

    render = () => {
     return  <div className="row">
         {Object.values(elements).map((item) => {
             return <div key={item.name} className="col-md-3 col-6 mb-3">
                 <button onClick={() => this.selected(item)}
                         className="btn btn-primary w-100">
                     {item.name}
                 </button>
             </div>
         })}
     </div>
    };
}
const mapStateToProps = state => ({
    drawer: state.drawers.elements
});
const mapDispatchToProps = {
    closeDrawer: closeDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(ElementsModal);
