import React from "react";
import {connect} from "react-redux";
import Slider from "../Slider";

class LinkSettings extends React.Component {

    onChange = (e) => {
        this.props.edit(e);
        setTimeout(() => {
            this.props.compileStyles();
        }, 10);
    }

    saveAndCompile = (e) => {
        this.props.edit(e);
        this.props.save(e);
        setTimeout(() => {
            this.props.compileStyles();
        }, 10);
    }

    render = () => {
        const node = this.props.node;
       return <>
               <Slider header="BOJE I SIRINA" className="mb-3">
                   <div className="mb-3">
                       <div className="font-weight-bold mb-1"> Primarna boja: </div>
                       <input type="color"
                              name="primary"
                              onBlur={this.props.save}
                              onChange={this.onChange}
                              value={node.primary || "#ffffff"} />
                   </div>
                   <div className="mb-3">
                       <div className="font-weight-bold mb-1"> Sekundarna boja: </div>
                       <input type="color"
                              name="secondary"
                              onBlur={this.props.save}
                              onChange={this.onChange}
                              value={node.secondary || "#ffffff"} />
                   </div>
                   <div className="mb-3">
                       <div className="font-weight-bold mb-1"> Boja teksta (TAMNA): </div>
                       <input type="color"
                              name="fontColor"
                              onBlur={this.props.save}
                              onChange={this.onChange}
                              value={node.fontColor || "#282828"} />
                    </div>
                   <div className="mb-3">
                       <div className="font-weight-bold mb-1"> Boja teksta (SVETLA): </div>
                       <input type="color"
                              name="fontColorLight"
                              onBlur={this.props.save}
                              onChange={this.onChange}
                              value={node.fontColorLight || "#ffffff"} />
                   </div>
                   <div className="mb-3">
                       <div className="font-weight-bold mb-1"> Boja pozadine: </div>
                       <input type="color"
                              name="background"
                              onBlur={this.props.save}
                              onChange={this.onChange}
                              value={node.background || "#ffffff"} />

                          <label title="Uklonite boju" className="btn btn-sm btn-danger">
                           <input name="background" value={false} onChange={this.saveAndCompile}
                                  style={{visibility: "hidden", width: 2}} type="checkbox" />
                           <i className="fa fa-close" />
                       </label>
                   </div>
                   <div className="mb-3">
                       Širina strane:
                       <input
                           name="container"
                           onChange={this.onChange}
                           onBlur={this.props.save}
                           type="text" className="form-control" value={node.container || ""}
                           placeholder="broj: 1200" />
                   </div>
               </Slider>
           <Slider header="FONT" className="mb-3">
               <div className="mb-3">
                   <div className="font-weight-bold"> Google FONTS link:</div>
                   <div className="font_08 gray font-italic mb-1">samo link fonta</div>

                   <textarea
                       rows="3"
                          className="form-control"
                          name="fontLink"
                       placeholder="primer: https://fonts.googleapis.com/css2?family=Duru"
                          onBlur={this.props.save}
                          onChange={this.onChange}
                          value={node.fontLink || ""} />
               </div>
               <div className="mb-3">
                   <div className="font-weight-bold"> Google FONTS ime: </div>
                   <input type="text"
                          name="fontFamily"
                          onBlur={this.props.save}
                          onChange={this.onChange}
                          placeholder="primer: 'Duru Sans', sans-serif;"
                          className="form-control"
                          value={node.fontFamily || ""} />
               </div>
           </Slider>
           <Slider header="EMAILS" className="mb-3">
               <div className="font-weight-bold mb-1"> Email za porudzbine: </div>
               <input type="email"
                      name="orderEmail"
                      onBlur={this.props.save}
                      onChange={this.onChange}
                      className="form-control"
                      value={node.orderEmail || ""} />
           </Slider>
           </>
    };
}
const mapStateToProps = state => ({
    globals: state.globals
});
export default connect(mapStateToProps)(LinkSettings);
