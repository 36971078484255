import React from "react";
import Looper from "../Looper";
import Menu from "../theme/Menu";
import {connect} from "react-redux";
import CategoriesDrawer from "../theme/drawers/CategoriesDrawer";
import {closeDrawer, openDrawer} from "../../store/actions/drawersActions";
import BasketDrawer from "../theme/drawers/BasketDrawer";
import Search from "../theme/Search";
import Grid from "../Grid";
import FloatingSidebar from "../theme/FloatingSidebar";
import Drawer from "../../views/framework/Drawer";
import routes from "../../app/routes";
import {Link} from "react-router-dom";
import InfoDrawer from "../theme/drawers/InfoDrawer";
import user from "../../assets/images/user.png";
import userWhite from "../../assets/images/user-white.png";
import bag from "../../assets/images/bag.png";
import bagWhite from "../../assets/images/bag-white.png";

class Template1 extends React.Component {

    openInfo = (e) => {
        e.preventDefault();
        this.props.openDrawer('info');
    }
    openBasket = (e) => {
        e.preventDefault();
        this.props.openDrawer('basket');
    }
    openCategories = (e) => {
        e.preventDefault();
        this.props.openDrawer('categories');
    }

    render = () => {
        const structure = this.props.globals.structure;
        let classname = "";
        if (structure.classes) {
            classname += Object.values(structure.classes).join(" ");
        }
        return <div className={classname}>
            <CategoriesDrawer/>
            <FloatingSidebar/>
            <InfoDrawer/>

            <Drawer
                reload={true}
                width={360}
                show={this.props.drawers.basket.status}
                close={() => this.props.closeDrawer('basket')}>

                <BasketDrawer/>
            </Drawer>

            <header className="pt-4 pt-md-0">
                {/*structure.gridsBefore && structure.gridsBefore.length > 0
                    ? structure.gridsBefore.map((item, i) => {
                        return <Grid key={i}
                                     path="globals.structure.gridsBefore"
                                     position={i} />
                    })
                    : <Grid path="globals.structure.gridsBefore" position={0} />*/}

                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-auto mb-md-0 mb-3 mt-md-0 mt-3 text-center text-md-left">
                            <Looper
                                path="globals.structure.header logo"
                                default={[{element: "image", width: 200}]}/>
                        </div>

                        <div className="col">
                            <Search history={this.props.history}/>
                        </div>

                        <div className="col-md-auto d-none d-md-block">
                            <div className="d-flex align-items-center">
                                <Link to={routes.userLogin.route} className="mr-3">
                                    <div className="text-md-center mb-1">
                                        <img src={user} width={24} alt="User icon"/>
                                    </div>
                                    {this.props.user ? this.props.user.name : "NALOG"}
                                </Link>

                                <a href={routes.basket.route} onClick={this.openBasket}>
                                    <div className="text-center">
                                        <div className="text-md-center mb-1 position-relative d-inline-block">
                                            <div className="builder_badge">
                                                {this.props.basket.length}
                                            </div>
                                            <img src={bag} width={24} alt="Bah icon"/>
                                        </div>
                                    </div>
                                    KORPA
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <Menu
                    path="globals.structure.menu"
                    default={{element: "menu", classes: {textAlign: "text-center"}}}/>

            </header>

            <div className="mt-2 mb-4" style={{minHeight: 200}}>
                {this.props.children}
            </div>

            {structure.gridsAfter && structure.gridsAfter.length > 0
                ? structure.gridsAfter.map((item, i) => {
                    return <Grid key={i}
                                 path="globals.structure.gridsAfter"
                                 position={i}/>
                })
                : <Grid path="globals.structure.gridsAfter" position={0}/>}

            <div className="bottom_nav d-md-none row p-0 m-0 text-center">
                <div className="col p-0">
                    <a href="/" onClick={this.openCategories}>
                        <div>
                            <i className="fa fa-bars" style={{fontSize: 24}}/>
                        </div>
                        PROIZVODI
                    </a>
                </div>
                <div className="col p-0">
                    <Link to={routes.userLogin.route} className="mr-3">
                        <div>
                           <img src={userWhite} width={24} alt="User icon" />
                        </div>
                        {this.props.user ? this.props.user.name : "NALOG"}
                    </Link>
                </div>
                <div className="col p-0">
                    <a href={routes.basket.route} onClick={this.openBasket}>
                        <div>
                                <span className="text-md-center mb-1 position-relative">
                                    <span className="builder_badge white">
                                        {this.props.basket.length}
                                    </span>
                                       <img src={bagWhite} width={24} alt="Bag icon" />
                                </span>
                        </div>
                        KORPA
                    </a>
                </div>
                <div className="col p-0">
                    <a href="/" onClick={this.openInfo}>
                        <div>
                            <i className="fa fa-info" style={{fontSize: 24}}/>
                        </div>
                        INFO
                    </a>
                </div>
                {this.props.drawers.bottomNav.status &&
                this.props.drawers.bottomNav.button}
            </div>
        </div>
    };
}

const mapStateToProps = state => ({
    globals: state.globals,
    drawers: state.drawers,
    basket: state.basket,
    user: state.user
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer,
}
export default connect(mapStateToProps, mapDispatchToProps)(Template1);
