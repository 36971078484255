import {SET_SELECTED} from "../actions/selectedActions";

export default function(state= false, {type, payload})      {
    switch(type)        {
        case SET_SELECTED:
            if(payload === state)   return "";
            return payload;
        default:
            return state;
    }
}