import React from "react";


class Pagination extends React.Component {


    render = () => {
        const page = parseInt(this.props.page);
        const lastpage = parseInt(this.props.lastPage);
        return <div className="text-center mt-3">
            {((page - 2) > 1) &&
            <>
                <button
                    name="page" value="1"
                    onClick={this.props.change}
                    className={(page === 1) ? "btn btn-primary btn-sm mr-2" : "btn btn-outline-primary btn-sm mr-2"}>
                    1
                </button>
                {((page - 2) !== 1) && <span> ... </span>}
            </>
            }
            {[page-2, page-1, page, page+1, page+2].map((item) => {
                if(item < 1) return "";
                if(item > lastpage) return "";
                return <button key={item}
                        name="page" value={item}
                        className={(page === item) ? "btn btn-primary mr-2" : "btn btn-outline-primary mr-2"}
                        onClick={this.props.change}>
                        {item}
                    </button>
            })}
            {((page + 2) < lastpage) &&
            <>
                {((page + 2) !== lastpage) &&  <span> ... </span>}
                <button
                    name="page" value={lastpage}
                    className={(page === lastpage) ? "btn btn-primary mr-2" : "btn btn-outline-primary mr-2"}
                    onClick={this.props.change}>
                    {lastpage}
                </button>
            </>
            }
        </div>
    };

}
export default Pagination;
