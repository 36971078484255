import TextOption from "./TextOption";
import CheckboxOption from "./CheckboxOption";
import SelectOption from "./SelectOption";
import RadioOption from "./RadioOption";
import TextareaOption from "./TextareaOption";
import text from  "../images/text.png"
import textarea from  "../images/textarea.png"
import checkbox from  "../images/checkbox.png"
import radio from  "../images/radio.png"
import select from  "../images/select.png"
import ImageOption from "./ImageOption";
import image from "../images/image.png";
import ProductsOption from "./ProductsOption";

export default {
    text: {
        component: TextOption,
        element: "text",
        name: "Input text sa naslovom",
        image: text
    },
    textarea: {
        component: TextareaOption,
        element: "textarea",
        name: "Textarea sa naslovom",
        image: textarea
    },
    checkbox: {
        component: CheckboxOption,
        element: "checkbox",
        name: "Checkbox",
        image: checkbox
    },
    radio: {
        component: RadioOption,
        element: "radio",
        name: "Radio buttons",
        image: radio
    },
    select: {
        component: SelectOption,
        element: "select",
        name: "Select dropdown",
        image: select
    },
    image: {
        component: ImageOption,
        element: "image",
        name: "Image upload",
        image: image
    },
    products: {
        component: ProductsOption,
        element: "products",
        name: "Proizvodi",
        image: checkbox
    },
}