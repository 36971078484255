import React from 'react';
import {connect} from "react-redux";
import {categoryBreadcrumbs, findCategoryBySlug, getChildrenIds} from "../../../providers/helpers";
import {getProducts} from "../../../providers/hooks";
import ProductRender from "../../theme/ProductRender";
import update from "react-addons-update";
import {closeDrawer, openDrawer} from "../../../store/actions/drawersActions";
import Drawer from "../../../views/framework/Drawer";
import SettingsButton from "../../fragments/SettingsButton";
import {Link} from "react-router-dom";
import routes from "../../../app/routes";
import CategoriesOnly from "./CategoriesOnly";
import Loader from "../../../views/elements/Loader";
import Pagination from "../../../views/elements/Pagination";


class Shop extends React.Component {

    default = {
        page: 1,
        filters: {},
        sort: "poslednje",
        pretraga: "",
        limit: 20,
        brand: ""
    }
    state = {
        object: {},
        category: null,
        loading: true,
        products: {},
        form: this.default,
        drawer: false,
        query: ""
    }

    scrollToTop = () => {
        window.scrollTo({ top: 200, behavior: 'smooth' });
    };

    editField = (name, value) => {
        let newState;
        if(name === "page") {
            newState = update(this.state, {form: {[name]: {$set: value}}});
        } else  {
            newState = update(this.state, {form: {[name]: {$set: value}, page: {$set: 1}}});
        }
        this.setState(newState);
        this.props.history.push(this.route(newState.form));
        this.load(newState.form);
        this.scrollToTop();
    }

    route = (form = false) => {
        if(!form) form = this.state.form;
        let params = [];
        for(let key in form) {
            let value = form[key];
            if(!value) continue;
            if(typeof value === "object")    {
                value = JSON.stringify(value);
            }
            if(Array.isArray(value))    {
                value = JSON.stringify(value);
            }
            params.push(`${key}=${value}`);
        }
        if(params.length === 0) {
            return "";
        }
        return "?" + params.join("&");
    }

    openDrawer = (e) => {
        e.preventDefault();
        this.setState({...this.state, drawer: true});
    }

    bottomNavButton = () => {
        return <div className="col">
            <a href="/" onClick={this.openDrawer}>
                <div>
                    <i className="fa fa-filter" style={{fontSize: 24}} />
                </div>
                FILTERI
            </a>
        </div>
    }

    render = () => {
        if(this.state.loading)      {
            return <Loader height={399} />
        }
        return  <div>
            <SettingsButton path={this.props.path} />

            <Drawer
                width="90%"
                reload={true}
                show={this.state.drawer}
                close={() => this.setState({...this.state, drawer: false})}>

                <div className="p-2">
                    <CategoriesOnly
                        object={this.state.object}
                        category={this.state.category} />

                    {/*<Categories
                        object={this.state.object}
                        category={this.state.category} />

                    <Filters
                        selected={this.state.form.filters}
                        editField={this.editField}
                        object={this.state.object}
                        category={this.state.category} />*/}
                </div>
            </Drawer>


            <div className="mt_20 mb_30">
                <span className="mr-2">
                    <Link to={routes.index.route}>
                        Naslovna <i className="fa fa-angle-right ml-2" />
                    </Link>
                </span>
                <span className="mr-2">
                    <Link to={routes.shop.route}>
                        Shop {this.state.category && <i className="fa fa-angle-right ml-2" />}
                    </Link>
                </span>
                {this.state.category &&
                categoryBreadcrumbs(this.state.category.id, this.props.categories).map((item) => {
                    return <span key={item.id} className="mr-2">
                        <Link to={routes.singleCategory.route(item)}>
                            {item.name}
                            {this.state.category.slug !== item.slug &&
                            <i className="fa fa-angle-right ml-2" />}
                        </Link>
                    </span>
                })}
            </div>


            <div className="row">
                <div className="col-md-3 d-none d-md-block">
                    <CategoriesOnly
                        object={this.state.object}
                        category={this.state.category} />

                    {/*<Categories
                        object={this.state.object}
                        category={this.state.category} />

                    <Filters
                        selected={this.state.form.filters}
                        editField={this.editField}
                        object={this.state.object}
                        category={this.state.category} />*/}
                </div>
                <div className="col-md-9">
                    {this.state.form.pretraga &&
                    <div className="mb-3">
                        <button
                            onClick={() => this.editField("pretraga", "")}
                            className="btn btn-sm btn-danger">Pretraga: {this.state.form.pretraga}
                        <i className="fa fa-close ml-3" /> </button>
                    </div>}

                    {this.state.products.data &&
                    <div>
                        {this.state.products.data.length === 0
                        ? <div className="p-5 font_4 text-center">Nema proizvoda</div>
                        : <>
                            <div className="d-md-flex align-items-center justify-content-between gray_block mb-3 font-weight-bold ">
                                <div className="mb-2 mb-md-0">Ukupno: {this.state.products.total}</div>

                                <div className="form-inline">
                                    Prikaži: &nbsp; <select
                                    value={this.state.form.limit}
                                    onChange={(e) => this.editField("limit", e.target.value)}
                                    className="mr-3 form-control mb-2 mb-md-0">
                                    <option value={20}> 20 </option>
                                    <option value={40}> 40 </option>
                                    <option value={60}> 60 </option>
                                    <option value={80}> 80 </option>
                                </select>

                                    Sortiraj: &nbsp; <select
                                    value={this.state.form.sort}
                                    onChange={(e) => this.editField("sort", e.target.value)}
                                    className="form-control">
                                    <option value="poslednje">Poslednje</option>
                                    <option value="rastuce">Ceni rastuće</option>
                                    <option value="opadajuce">Ceni opadajuće</option>
                                    <option value="imenu">Po imenu</option>
                                </select>
                                </div>
                            </div>

                            {this.state.form.brand &&
                                <Link to={routes.shop.route}>
                                    <button className="btn btn-danger btn-sm">
                                        {this.state.form.brand} <i className="fa fa-trash" />
                                    </button>
                                </Link>}
                            <div className="row m-0 mb-3">
                                {this.state.products.data.map((item) => {
                                    return <div key={item.id} className="col-md-3 col-6 pl-0 shop_product pr-0 mb-3">
                                        <ProductRender product={item} />
                                    </div>
                                })}
                            </div>

                            <Pagination
                                change={(e) => this.editField("page", e.target.value)}
                                page={this.state.form.page}
                                lastPage={this.state.products.last_page} />
                        </>}
                    </div>}
                </div>
            </div>
        </div>
    };

    load = (form = false) => {
        if(!form)   {
            const query = this.props.history.location.search; // could be '?foo=bar'
            const params =  new URLSearchParams(decodeURIComponent(query));
            let filters = params.get('filters') ? JSON.parse(params.get('filters')) : {};
            let pageUrl = params.get('page') ? params.get('page') : 1;
            let brand = params.get('brand') ? params.get('brand') : "";
            let search = params.get('pretraga') ? params.get('pretraga') : "";
            let limit = params.get('limit') ? params.get('limit') : 20;
            let sort = params.get('sort') ? params.get('sort') : "";
            form = {...this.default, filters: filters, page: pageUrl, brand: brand, pretraga: search, limit: limit, sort: sort};

            const pathObj = this.getObject();
            let category = pathObj.slug
                ? findCategoryBySlug(pathObj.slug, this.props.categories)
                : findCategoryBySlug(this.props.page.category, this.props.categories);

            if(!category && !search)   {
                //category = this.props.categories[0].children[0];
            }
            this.setState({...this.state, query: this.props.history.location.search, form: form, category: category, object: pathObj});
            if(category)                {
                form = {...form, categories: getChildrenIds(category)};
            }
        }
        else    {
            if(this.state.category)     {
                form = {...form, categories: getChildrenIds(this.state.category)};
            }
        }
        getProducts(form).then((response) => {
            this.setState({...this.state, loading: false, query: this.props.history.location.search, products: response.data});
        })
    }

    getObject = () => {
        const pathname = window.location.pathname; // /foo/bar
        const pathnameArr = pathname.split("/");
        pathnameArr.shift();
        const pathObj = {};
        pathObj.page = pathnameArr.shift();
        pathObj.slug = pathnameArr.pop();
        return pathObj;
    }

    componentDidMount()     {
        this.load();
        //this.props.openDrawer({name: "bottomNav", button: this.bottomNavButton()});
    }
    componentWillUnmount() {
        //this.props.closeDrawer("bottomNav");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.page.slug !== prevProps.page.slug)        {
            this.load();
        }
        else if(this.props.match.url !== prevProps.match.url)        {
            this.load();
        }
        else if(this.props.history.location.search !== this.state.query)        {
            this.load();
        }
    }
}
const mapStateToProps = state => ({
    page: state.page,
    categories: state.categories,
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(Shop);
