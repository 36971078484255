import React from 'react';
import {connect} from "react-redux";
import {deletePath} from "../../../providers/hooks";
import {setMenu} from "../../../store/actions/menuActions";
import AddPage from "./AddPage";
import Checkbox from "../../../views/elements/edit/Checkbox";


class PagesItem extends React.Component   {

    state = {
        settings: false
    }

    delete = () => {
        var r = window.confirm("Želite da obrišete stranu?");
        if (r !== true) {
            return false;
        }
        deletePath({path: "pages." + this.props.item.id}).then((response) => {
           this.props.reload();
        })
    }

    render = () => {
        let item = this.props.item;
        return <div>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    {this.props.collapseIcon}
                    {this.props.handler}
                    {item.name}
                </div>

                <div>
                    <button
                        onClick={() => this.setState({...this.state, settings: !this.state.settings})}
                        className="btn btn-primary btn-sm">
                        <i className="fa fa-gear" />
                    </button>
                </div>

            </div>
            {this.state.settings &&
            <div className="mt-3">
                <div className="mb-2">
                    <label>
                        <Checkbox
                            name="header"
                            path={`pages.${item.id}.header`}
                            onChange={this.props.reload}
                            value="1"
                            checked={item.header}
                            className="mr-2" /> Vidljivo u header-u
                    </label>
                </div>
                <div className="mb-3">
                    <AddPage item={item} reload={this.props.reload} />
                </div>
                <div className="mb-3">
                    <AddPage parent={item.id} reload={this.props.reload} />
                </div>
                <button onClick={this.delete} className="btn btn-danger">
                    Obrišite
                </button>
            </div>}
        </div>;
    };
}
const mapStateToProps = state => ({
    menu: state.menu
});
const mapDispatchToProps = {
    setMenu: setMenu
};
export default connect(mapStateToProps, mapDispatchToProps)(PagesItem);
