import React from "react";
import {Link} from "react-router-dom";

class LeftMenuItem extends React.Component
{

    state = {
        open: false
    }

    toggle = (e) => {
        e.preventDefault();
        this.setState({...this.state, open: !this.state.open});
    }

    render = () => {
        const item = this.props.item;
        const children = this.props.children;
        return children
            ? <li className={this.props.history.location.pathname === item.route ? "active" : ""}>
                <Link to={item.route}>
                    {item.name}

                    <div className="float-right" onClick={this.toggle}>
                        {this.state.open ? <i className="fa fa-chevron-up" /> : <i className="fa fa-chevron-down" />}
                    </div>
                </Link>

                <ul className="list-unstyled"
                    style={this.state.open ? {maxHeight: 50*children.length} : {maxHeight: 0}}>
                    {children.map((child, i) => {
                        const selected = this.props.history.location.pathname === child.route;
                        return <li key={i} className={selected ? "active pl-5" : "pl-5"}>
                            <Link to={child.route}>
                                {child.name}
                            </Link>
                        </li>
                    })}
                </ul>
            </li>
            : <li className={this.props.history.location.pathname === item.route ? "active" : ""}>
                <Link to={item.route}>
                    {item.name}
                    {this.props.count &&
                    <div className="float-right left_menu_count">
                        {this.props.count}
                    </div>}
                </Link>
            </li>}
}
export default LeftMenuItem;