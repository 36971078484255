import React from "react";
import MarginSettings from "../fragments/settings/MarginSettings";
import PaddingSettings from "../fragments/settings/PaddingSettings";
import DeleteNodeSettings from "../fragments/settings/DeleteNodeSettings";
import {storage} from "../../providers/helpers";
import {filesDrawer} from "../../store/actions/drawersActions";
import {connect} from "react-redux";

class PopupSet extends React.Component {

    render = () => {
        const node = this.props.node;
        return <>
            <div className="mb-3">
                {node.src && <img src={storage(node.src)} alt="big" width={50} className="mr-2" />}
                <button
                    onClick={() => this.props.filesDrawer({status: true, callback:
                            (files) => this.props.save({target: {name: "src", value: files[0].name}})
                    })}
                    className="btn btn-sm btn-success">
                    <i className="fa fa-image mr-1" />  Promenite sliku
                </button>
            </div>
            <div className="mb-3">
                {node.mobileSrc && <img src={storage(node.mobileSrc)} alt="big" width={50} className="mr-2" />}
                <button
                    onClick={() => this.props.filesDrawer({status: true, callback:
                            (files) => this.props.save({target: {name: "mobileSrc", value: files[0].name}})
                    })}
                    className="btn btn-sm btn-success">
                    <i className="fa fa-image mr-1" />  Slika na mobilnom
                </button>
            </div>

            <MarginSettings edit={this.props.edit} save={this.props.save} node={this.props.node}/>
            <PaddingSettings edit={this.props.edit} save={this.props.save} node={this.props.node}/>
            <DeleteNodeSettings save={this.props.save} node={this.props.node}/>
        </>
    };
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    filesDrawer: filesDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(PopupSet);
