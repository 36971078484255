import React from "react";
import {Link} from "react-router-dom";
import routes from "../../../app/routes";
import {deletePath} from "../../../providers/hooks";
import {storage} from "../../../providers/helpers";
import Checkbox from "../../elements/edit/Checkbox";
import Price from "../../../builder/theme/Price";
import moment from "moment";


class BlogsItem extends React.Component {


    delete = () => {
        var r = window.confirm("Želite da obrišete proizvod?");
        if (r !== true) {
            return false;
        }
        deletePath({path: "blogs." + this.props.item.id}).then((response) => {
            this.props.reload();
        })
    }

    render = () => {
        const item = this.props.item;
        return <div className="dark_block mb-3 d-flex">
            <div style={{flex: "120px 0 0"}}>
                <div className="bcg_image" style={{backgroundImage: `url(${storage(item.image_name)})`}} />
            </div>
            <div className="flex-grow-1 pl-3">
                <div className="mb-2">
                    <Link className="prewrap font-weight-bold" to={routes.cmsSingleBlog.route(item.id)}>{item.title}</Link>
                </div>
                <div className="gray prewrap mb-2">{item.description}</div>
                <div className="gray">
                    <i className="fas fa-calendar mr-2" aria-hidden="true" />
                    {moment.utc(item.created_at).local().format('DD.MM.YYYY HH:mm')}
                </div>
            </div>
            <div className="text-right" style={{flex: "150px 0 0"}}>
                <div className="mb-2">
                    <Link to={routes.cmsSingleBlog.route(item.id)} className="btn btn-primary btn-sm">
                        Detaljnije
                    </Link>
                </div>
                <div>
                    <button onClick={this.delete} className="btn btn-danger btn-sm">
                        <i className="fa fa-trash" /> Obrišite
                    </button>
                </div>
            </div>
        </div>
    };
}

export default BlogsItem;
