import React from "react";
import Input from "../../../elements/edit/Input";
import elements from "./elements";
import {getProducts, putOptionProducts} from "../../../../providers/hooks";
import Recommendations from "../../product/Recommendations";
import {pluck, storage} from "../../../../providers/helpers";
import routes from "../../../../app/routes";
import {Link} from "react-router-dom";
import Price from "../../../../builder/theme/Price";
import RenderOptionProduct from "../../../../builder/grids/basket/RenderOptionProduct";


class ProductsOption extends React.Component {

    state = {
        edit: false,
        products: []
    }

    changed = (e) => {
        const items = e.target.value;
        putOptionProducts({element: this.props.item.id, products: items}).then((response) => {
            this.props.reload();
        })
    }

    render = () => {
        const item = this.props.item;

        if(this.props.admin)     {
            return <div className="d-flex justify-content-between">
                <div className="flex-grow-1 mr-3">
                    <div className="mb-2">
                        Element: <strong> {elements[item.element].name} </strong>
                    </div>
                    {this.state.edit
                        ?  <>
                            <div className="mb-2">
                                Polje (naslov):
                                <Input
                                    path={`option_elements.${item.id}.field_1`}
                                    onBlur={() => this.props.reload()}
                                    value={item.field_1} name="field_1"
                                    maxLength={199} autoComplete="off"  />
                            </div>
                            <div className="mb-2">
                                <div className="mb-2 font-weight-bold">Dodajte proizvode:</div>

                                <Recommendations
                                    onChange={this.changed}
                                    value={this.state.products}
                                />
                            </div>
                            {this.props.edit}
                        </>
                        : <>
                            <div className="mb-2">  Polje (naslov): <strong> {item.field_1} </strong></div>
                            <div className="mb-2">  Proizvodi:
                                {this.state.products.map((product, index) => {
                                    return <div key={product.id}>
                                        <div className="d-flex align-items-center pb-2">
                                            <img src={storage(product.image_name)} width={40} className="mr-2" alt={product.name} />
                                            <strong className="mr-4">{index+1}. {product.name} </strong>
                                        </div>
                                    </div>
                                })}
                            </div>
                            {this.props.show}
                        </>}
                </div>
                <div>
                    <div className="mb-1">
                        <button
                            onClick={() => this.setState({...this.state, edit: !this.state.edit})}
                            className="btn btn-success btn-sm btn-block">{this.state.edit ? "Vrati" : "Prepravite"}</button>
                    </div>
                    {this.props.buttons}
                </div>
            </div>
        }

        if(!this.props.editField)   {
            const product = this.state.products.find(prod => prod.id == this.props.order[item.id]?.value);
            if(!product) return "";

            return <div>
                <div className="font-weight-bold mb-1"> {item.field_1} {item.required && "*"} </div>
                {item.price &&
                <div> Cena opcije: +<Price price={item.price} /></div>}

                <Link to={routes.singleProduct.route(product)} target="_blank">
                    <div className="d-flex align-items-center">
                        <div style={{width: 80}} className="mr-2">
                            <div className="bcg_image" style={{backgroundImage: `url(${storage(product.image_name)})`, backgroundSize: "cover"}} />
                        </div>

                        {product.name}
                    </div>
                </Link>
            </div>
        }

        return <div>
            <div className="font-weight-bold mb-1"> {item.field_1} {item.required && "*"} </div>
            <div className="font-italic font_08 gray">Kliknite na proizvod ispod da odaberete</div>
            <div className="row no-gutters">
                {this.state.products.length > 0 &&
                item.selects.map((select) => {
                    if(!select.price && item.price)     {
                        select.price = item.price
                    }
                    select.element = "products";
                    if(!select.name || select.name === "") return "";
                    const product = this.state.products.find(prod => parseInt(prod.id) === parseInt(select.name))
                    if(!product) return "";
                    return <div key={select.id} className="col-md-3 col-6">
                        <Link to={routes.singleProduct.route(product)} target="_blank">
                            <span className="font-italic font_08 font_primary">
                               <i className="fa fa-link font_06" /> Idite na proizvod
                            </span>
                        </Link>

                        <label className="p-1 select_checkbox position-relative font_08 d-block">
                            <div className="bcg_image" style={{backgroundImage: `url(${storage(product.image_name)})`, backgroundSize: "cover"}} />
                            <input
                                onChange={(e) => this.props.editField(item.field_1, select.name, item)}
                                type="radio" name={item.id} value={select.id}
                                className="position-absolute" required={item.required}
                                style={{opacity: 0, top: 0}} />

                                <span className="border_primary" />
                            {product.name} {select.price && <>&nbsp; &nbsp; &nbsp; <br /><Price price={select.price} /></>}
                        </label>
                    </div>
                })}
            </div>
        </div>
    };

    load = () => {
        if(this.props.item.selects.length > 0)  {
            getProducts({ids: pluck(this.props.item.selects, "name"), limit: 80}).then((response) => {
                this.setState({...this.state, products: response.data.data});
            });
        } else  {
            this.setState({...this.state, products: []});
        }
    }
    componentDidMount() {
        this.load();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.item.selects.length !== prevProps.item.selects.length)    {
            this.load();
        }
    }
}
export default ProductsOption;