import React from 'react';
import {putAdmin} from "../../../providers/hooks";
import update from "react-addons-update";


class AddAdmin extends React.Component   {

    default = {
        name: "",
        lastname:  "",
        email: "",
        password: ""
    }
    state = {
        open: false,
        form: this.default
    };

    submit = (e) => {
        e.preventDefault();
        putAdmin(this.state.form).then((response) => {
            this.props.reload();
            this.setState({...this.state, open: false, form: this.default});
        })
    }

    toggleOpen = () => {
        this.setState({...this.state, open: !this.state.open});
    }

    editField = (e) => {
        const field = e.target.name;
        let value = e.target.value;
        this.setState(update(this.state, {form: {[field]: {$set: value}} }));
    }

    render = () => {
        if(!this.state.open)     {
            return <button
                    onClick={this.toggleOpen}
                    className="btn btn-primary">
                <span> <i className="fa fa-plus" /> Dodajte administratora </span>
            </button>
        }
        const admin = this.state.form;
        return <div className="dark_block p-3 position-relative">
            <form action="#" onSubmit={this.submit}>
                <div className="row mb-1">
                    <div className="col-md-6">
                        <label className="d-block mb-3">
                            <div className="mb-2">
                                <strong> Ime: </strong>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                onChange={this.editField}
                                name="name" autoComplete="off"
                                value={admin.name}
                                maxLength={191} required />
                        </label>

                        <label className="d-block">
                            <div className="mb-2">
                                <strong> Prezime: </strong>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                onChange={this.editField}
                                name="lastname" autoComplete="off"
                                value={admin.lastname}
                                maxLength={191} required />
                        </label>
                    </div>
                    <div className="col-md-6">
                        <label className="d-block mb-3">
                            <div className="mb-2">
                                <strong> Email: </strong>
                            </div>
                            <input
                                type="email"
                                className="form-control"
                                onChange={this.editField}
                                name="email" autoComplete="off"
                                value={admin.email}
                                maxLength={255} required />
                        </label>

                        <label className="d-block mb-3">
                            <div className="mb-2">
                                <strong> Lozinka: </strong>
                            </div>
                            <input
                                type="password"
                                className="form-control"
                                onChange={this.editField}
                                name="password" autoComplete="off"
                                value={admin.password}
                                maxLength={255} required />
                        </label>
                    </div>
                </div>
                {!admin.id &&
                <button type="submit" className="btn btn-primary">
                    Sačuvajte
                </button>}
                <button
                    style={{top: 4, right: 4}}
                    onClick={this.toggleOpen}
                    className="btn btn-primary btn-sm position-absolute">
                    <i className="fa fa-close" />
                </button>
            </form>
        </div>;
    };
}
export default AddAdmin;
