import React from "react";
import Slider from "../Slider";
import {openDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";

class ProductsSettings extends React.Component {

    render = () => {
        const node = this.props.node;
       return <Slider open={this.props.open} header="PROIZVODI" className="mb-3">
           <div className="mb-3">
               <button
                   onClick={() => this.props.openDrawer({name: "products", status: true, selected: node.products,
                       callback: (products) => this.props.save({target: {name: "products", value: products}})
                   })}
                   className="btn btn-sm btn-success">
                   DODAJTE PROIZVODE
               </button>
           </div>

       </Slider>
    };
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    openDrawer: openDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductsSettings);
