import React from 'react';
import {connect} from "react-redux";
import Modal from "../../../views/framework/Modal";
import Nestable from 'react-nestable';
import PageItem from "./PagesItem";
import AddPage from "./AddPage";
import {setMenu} from "../../../store/actions/menuActions";
import {getMenu, postPagesOrder} from "../../../providers/hooks";


class PagesModal extends React.Component   {

    state = {
        pagesModal: false,
    }

    renderItem = (props) => {
        return <PageItem {...props} reload={this.load} />
    };

    collapseIcons = ({ isCollapsed }) => {
        if(isCollapsed) return <span className="nestable_icon"><i className="fa fa-plus" /></span>
        return <span className="nestable_icon"><i className="fa fa-minus" /></span>;
    };

    changedOrder = (items) => {
        postPagesOrder({items: items}).then((response) => {
            this.load();
        })
    }

    render = () => {
        return <>
            <Modal
                open={this.state.pagesModal}
                close={() => this.setState({...this.state, pagesModal: false})}>

                <div className="mb-3">
                    <AddPage reload={this.load} />
                </div>

                <Nestable
                    collapsed={true}
                    maxDepth={1}
                    onChange={this.changedOrder}
                    renderCollapseIcon={this.collapseIcons}
                    handler={<span className="nestable_icon"><i className="fa fa-arrows" /></span>}
                    items={this.props.menu}
                    renderItem={this.renderItem}
                />
            </Modal>

            <button
                onClick={() => this.setState({...this.state, pagesModal: true})}
                className="btn btn-primary mr-2 btn-sm">
                Podešavanje stranica
            </button>
        </>
    };

    load = () => {
        getMenu().then((response) => {
            this.props.setMenu(response.data);
        });
    }

}
const mapStateToProps = state => ({
    menu: state.menu
});
const mapDispatchToProps = {
    setMenu: setMenu
};
export default connect(mapStateToProps, mapDispatchToProps)(PagesModal);
