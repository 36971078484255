import React from "react";
import Looper from "../Looper";

class Grid1 extends React.Component {

    render = () => {
        const node = this.props.node;
        const blockCount = parseInt(node.blockCount);
        if(blockCount === 2)    {
            let col1 = 6;
            let col2 = 6;
            if(node.proportion) {
                const prop = parseInt(node.proportion);
                col1 = prop;
                col2 = 12 - prop;
            }
            return <div className="row">
                <div className={`col-md-${col1}`}>
                    <Looper path={`${this.props.path}.slides.0`} />
                </div>
                <div className={`col-md-${col2}`}>
                    <Looper path={`${this.props.path}.slides.1`} />
                </div>
            </div>
        }
        return <div className="row">
            {Array.from(Array(blockCount).keys()).map((item) => {
                return  <div key={item} className="col-md mb-4 mb-md-0">
                <Looper path={`${this.props.path}.slides.${item}`} />
                </div>
            })}
        </div>
    };
}
export default Grid1;
