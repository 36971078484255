import React from "react";

class Button extends React.Component {

    render = () => {
        let text = this.props.node.text || "Lorem Ipsum";

        let classname = "prewrap btn ";
        if(this.props.node.classes)     {
            classname += Object.values(this.props.node.classes).join(" ");
        }
        if(!this.props.node.classes?.buttonColor)       {
            classname += " btn-primary ";
        }
        if(this.props.node.iconText)     {
            text = <i className={this.props.node.iconText} />
        }
        const textASlign = this.props.node.classes?.textAlign || "";
        return <div className={textASlign}>
           <button className={classname} style={this.props.node.style || {}}>
               {text}
           </button>
        </div>
    };

}
export default Button;
