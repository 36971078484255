import React from "react";
import {connect} from "react-redux";
import {getProducts} from "../../providers/hooks";
import {Link} from "react-router-dom";
import {storage} from "../../providers/helpers";
import Overlay from "../../views/framework/Overlay";
import Price from "./Price";
import routes from "../../app/routes";


class Search extends React.Component {

    state = {
        show: false,
        value: "",
        products: [],
        categories: [],
        cursor: false,
    }

    open = () => {
        this.setState({...this.state, show: true});
    };

    close = () => {
        this.setState({...this.state, show: false});
    };

    changeInput = (e)  => {
        if(e.target.value)      {
            this.loadProducts(e.target.value);
            this.setState({...this.state, value: e.target.value, show: true});
        }
        else        {
            this.setState({...this.state, value: "", products: []});
        }
    }

    keyDown = (e) => {
        let code = e.keyCode;
        let cursor = this.state.cursor !== false ? this.state.cursor : -1;
        let max = this.state.categories.length + this.state.products.length;
        let set = false;
        let show = true;
        if(code === 40)     { // DOWN
            if(cursor === (max-1))     set = 0;
            else                       set = cursor + 1;
        }
        else if(code === 38)     { // up
            if(cursor === 0)           set = max - 1;
            else                       set = cursor - 1;
        }
        else if(code === 13)     { // ENTER
            if(cursor === false) return false;
            if(cursor < this.state.categories.length)       {
                let item = this.state.categories[cursor];
                if(!item) return false;
                this.props.history.push(routes.singleCategory.route(item));
                show = false;
            }
            else if(cursor < (this.state.categories.length + this.state.products.length))    {
                let item = this.state.products[cursor - this.state.categories.length];
                this.props.history.push(routes.singleProduct.route(item));
                show = false;
            }
        }
        else  return false;
        e.preventDefault();
        this.setState({...this.state, cursor: set, show: show});
    };

    submit = (e) => {
        e.preventDefault();
        this.props.history.push(routes.shop.route + "?pretraga=" + this.state.value);
        this.setState({...this.state, show: false});
    }


    render = () => {
        let autocomplete = (this.state.products.length > 0 || this.state.categories.length > 0) && this.state.show
        return  <>
            <div className={this.state.show ? "search_form search_form_show" : "search_form"}>
                <div className="position-relative">
                    <form onSubmit={this.submit} action={"."} method="GET">
                        <div className={autocomplete ? "prepend_form autocomplete" : "prepend_form"}>
                            <button className="btn close_btn btn-danger" type="button" onClick={this.close}>
                                <i className="fa fa-close" />
                            </button>

                            <div className="input-group">
                                <input
                                    autoComplete="off"
                                    name="pretraga"
                                    ref={(input) => { this.input = input; }}
                                    value={this.state.value}
                                    onChange={this.changeInput}
                                    onFocus={this.open}
                                    onKeyDown={this.keyDown}
                                    placeholder="Pretraga..."
                                    className="form-control" />

                                <div className="input-group-append">
                                    <button className="btn btn-primary pl-3 pr-3" type="submit"><i className="fas fa-search" /></button>
                                </div>
                            </div>

                        </div>
                    </form>
                    {autocomplete &&
                    <div className="search_autocomplete font_color">
                        {this.state.categories.map((item, index) => {
                            return <div key={item.id}
                                        className={this.state.cursor === index
                                            ? "search_autocomplete_active"
                                            : ""}>
                                <Link
                                    className="link_button"
                                    onClick={this.close}
                                    to={routes.singleCategory.route(item)}>
                                    <span className="gray">Kategorija: </span>
                                    <strong className="ml-2">{item.name}</strong>
                                </Link>
                            </div>
                        })}

                        {this.state.products.map((item, index) => {
                            return <div key={item.id}
                                        className={this.state.cursor === (index + this.state.categories.length)
                                            ? "search_autocomplete_active"
                                            : ""}>
                                <Link
                                    className="link_button"
                                    onClick={this.close}
                                    to={routes.singleProduct.route(item)}>
                                    <div className="d-flex align-items-center">
                                        <div className="mr-3">
                                            <img src={storage(item.image_name)} width={60} className="search_autocomplete_image" alt="slika" />
                                        </div>
                                        <div className="">
                                            <div>{item.name}</div>
                                            <div className="font_primary font_09 font-weight-bold">
                                                <Price item={item} />
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        })}
                    </div>}
                </div>
            </div>
            <Overlay show={this.state.show} close={this.close} />
        </>
    };

    loadProducts = (search) => {
        let categories = [];
        if(search && search.length > 1)     {
            categories = this.props.categories.filter((item) => {
                let name = item.name.toLowerCase()
                    .replace(/[čć]/gi, 'c')
                    .replace(/[š]/gi, 's')
                    .replace(/[ž]/gi, 'z')
                let srch = search.toLowerCase()
                    .replace(/[čć]/gi, 'c')
                    .replace(/[š]/gi, 's')
                    .replace(/[ž]/gi, 'z')
                return name.search(srch) > -1
            });
        }

        getProducts({pretraga: search}).then((response) => {
            this.setState({...this.state, products: response.data.data, categories: categories,  show: true});
        })
    };
}
const mapStateToProps = state => ({
    categories: state.categories
});
export default connect(mapStateToProps)(Search);
