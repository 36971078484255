import React, {useRef, useState} from "react";
import {EditorState, RichUtils, convertToRaw, convertFromRaw} from "draft-js";
import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import BlockStyleControls from "./BlockStyleControls";
import InlineStyleControls from "./InlineStyleControls";

import createImagePlugin from '@draft-js-plugins/image';
import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createFocusPlugin from '@draft-js-plugins/focus';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import createLinkPlugin from '@draft-js-plugins/anchor';
import createUndoPlugin from '@draft-js-plugins/undo';
import {ItalicButton, BoldButton, UnderlineButton} from '@draft-js-plugins/buttons';

import ErrorBoundary from "./ErrorBoundary";
import {storage} from "../../providers/helpers";

const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const blockDndPlugin = createBlockDndPlugin();
const alignmentPlugin = createAlignmentPlugin();
const { AlignmentTool } = alignmentPlugin;

const decorator = composeDecorators(
    resizeablePlugin.decorator,
    alignmentPlugin.decorator,
    focusPlugin.decorator,
    blockDndPlugin.decorator
);
const imagePlugin = createImagePlugin({ decorator });

const linkPlugin = createLinkPlugin({
    placeholder: 'kopirajte link i pritisnite enter',
    linkTarget: '_blank'
});
const inlineToolbarPlugin = createInlineToolbarPlugin();
const { InlineToolbar } = inlineToolbarPlugin;

const undoPlugin = createUndoPlugin();
const { UndoButton, RedoButton } = undoPlugin;


function RichTextEditor({onChange, onSave, content, readOnly, filesDrawer}) {
    const refEditor = useRef();

    const insertImageCallback = (images) => {
        if (images.length === 0) return false;
        const newState = imagePlugin.addImage(editorState, storage(images[0].name));
        changed(newState);
    }

    let defaultContent = null;
    if(content) {
        try {
            defaultContent = JSON.parse(content);
            defaultContent = EditorState.createWithContent(convertFromRaw(defaultContent));
        } catch (e) {
            defaultContent = EditorState.createEmpty();
        }
    } else  {
        defaultContent = EditorState.createEmpty();
    }

    const [different, setDifferent] = useState(false);
    const [editorState, setEditorState] = React.useState(
        content
            ? () => defaultContent
            : () => EditorState.createEmpty(),
    );

    const changed = (newEditorState) => {
        if(JSON.stringify(convertToRaw(newEditorState.getCurrentContent())) !== JSON.stringify(convertToRaw(editorState.getCurrentContent())))  {
            setDifferent(true);
        }

        setEditorState(newEditorState);
        if(onChange)    {
            onChange(convertToRaw(newEditorState.getCurrentContent()))
        }
    }
    const saved = () => {
        if(!different) return false;
        setDifferent(false);
        if(onSave)    {
            onSave(convertToRaw(editorState.getCurrentContent()))
        }
    }

    const toggleBlockType = (blockType) => {
        /*if (blockType === "image") {
            return false;
        }*/
        changed(
            RichUtils.toggleBlockType(
                editorState,
                blockType
            )
        );
    }
    const toggleInlineStyle = (inlineStyle) => {
        changed(
            RichUtils.toggleInlineStyle(
                editorState,
                inlineStyle
            )
        );
    }
    function getBlockStyle(block) {
        switch (block.getType()) {
            case 'blockquote':
                return 'RichEditor-blockquote';
            default:
                return null;
        }
    }

    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
        if (contentState.getBlockMap().first().getType() !== 'unstyled') {
            className += ' RichEditor-hidePlaceholder';
        }
    }

    const editor = () => {
        return <Editor
            readOnly={readOnly}
            blockStyleFn={getBlockStyle}
            customStyleMap={{
                COLOR: {
                    color: "#f01980"
                },
                CENTER: {
                    display: "block",
                    textAlign: 'center',
                },
                RIGHT: {
                    display: "block",
                    textAlign: "right",
                }
            }}
            editorState={editorState}
            //handleKeyCommand={this.handleKeyCommand}
            onChange={changed}
            onBlur={saved}
            ref={refEditor}
            spellCheck={false}
            plugins={[
                blockDndPlugin,
                focusPlugin,
                alignmentPlugin,
                resizeablePlugin,
                imagePlugin,
                inlineToolbarPlugin,
                linkPlugin
                //undoPlugin
            ]}>
        </Editor>
    }

    if(readOnly)    {
        return editor();
    }

    return <>
        <div className="RichEditor-root">
            <div className="d-flex items-center mb-1">
                <BlockStyleControls
                    editorState={editorState}
                    onToggle={toggleBlockType}
                />
                <span onClick={() => filesDrawer({status: true, callback: insertImageCallback})}
                    className="pointer mr-2 gray" style={{padding: "2px 0"}}>Slika</span>

                {/*widget("storage.StorageDrawer", {
                    button: <span className="cursor-pointer mr-2 text-gray-400" style={{padding: "2px 0"}}>Slika</span>,
                    callback: insertImageCallback}
                )*/}
            </div>


            <InlineStyleControls
                editorState={editorState}
                onToggle={toggleInlineStyle}
            />

            <div className={className} onClick={() => refEditor.current.focus()}>
                <ErrorBoundary>
                    {editor()}
                </ErrorBoundary>
                <AlignmentTool />
                <InlineToolbar>
                    {
                        (externalProps) => (
                            <div>
                                <BoldButton {...externalProps} />
                                <ItalicButton {...externalProps} />
                                <UnderlineButton {...externalProps} />
                                <linkPlugin.LinkButton {...externalProps} />
                            </div>
                        )
                    }
                </InlineToolbar>
                <div className="mt-3 d-none">
                    <UndoButton /> <RedoButton />
                </div>
            </div>
            {onSave && different &&
            <div className="mt-3">
                <button className="btn btn-primary btn-lg font-weight-bold text-uppercase" onClick={saved}> sacuvajte </button>
            </div>}
        </div>
    </>
}

export default RichTextEditor;