import React from "react";
import Loader from "../../elements/Loader";
import {getFilters, postFiltersOrder} from "../../../providers/hooks";
import Nestable from "react-nestable";
import FiltersItem from "./FiltersItem";
import AddFilter from "./AddFilter";


class Filters extends React.Component {

    state = {
        loading: true,
        filters: []
    }

    changedOrder = (items) => {
        postFiltersOrder({items: items}).then((response) => {
            this.load();
        })
    }

    collapseIcons = ({ isCollapsed }) => {
        if(isCollapsed) return <span className="nestable_icon"><i className="fa fa-plus" /></span>
        return <span className="nestable_icon"><i className="fa fa-minus" /></span>;
    };

    renderItem = (props) => {
        return <FiltersItem {...props} reload={this.load} />
    };

    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }

        return <div className="container mt-5 mb-5">
            <div className="mb-3">
                <AddFilter reload={this.load} />
            </div>

            <Nestable
                collapsed={true}
                maxDepth={2}
                onChange={this.changedOrder}
                renderCollapseIcon={this.collapseIcons}
                handler={<span className="nestable_icon"><i className="fa fa-arrows" /></span>}
                items={this.state.filters}
                renderItem={this.renderItem} />
        </div>
    };

    load = () => {
        getFilters({}).then((response) => {
            this.setState({...this.state, loading: false, filters: response.data});
        });
    }

    componentDidMount() {
        this.load();
    }
}

export default Filters;
