import React from "react";
import Slider from "../Slider";

class InfoSettings extends React.Component {

    render = () => {
        const node = this.props.node;
       return <Slider open={this.props.open} header="INFO" className="mb-3">

           <div className="mb-3">
               Telefon:
               <input
                   name="phone"
                   onChange={this.props.edit}
                   onBlur={this.props.save}
                   type="text" className="form-control" value={node.phone || ""}
                   placeholder="032 333 333" />
           </div>

           <div className="mb-3">
               Email:
               <input
                   name="email"
                   onChange={this.props.edit}
                   onBlur={this.props.save}
                   type="email" className="form-control" value={node.email || ""}
                   placeholder="" />
           </div>

           <div className="mb-3">
               Facebook:
               <input
                   name="facebook"
                   onChange={this.props.edit}
                   onBlur={this.props.save}
                   type="text" className="form-control" value={node.facebook || ""}
                   placeholder="" />
           </div>

           <div className="mb-3">
               Youtube:
               <input
                   name="youtube"
                   onChange={this.props.edit}
                   onBlur={this.props.save}
                   type="text" className="form-control" value={node.youtube || ""}
                   placeholder="" />
           </div>

           <div className="mb-3">
               Twitter:
               <input
                   name="twitter"
                   onChange={this.props.edit}
                   onBlur={this.props.save}
                   type="text" className="form-control" value={node.twitter || ""}
                   placeholder="" />
           </div>

           <div className="mb-3">
               Instagram:
               <input
                   name="instagram"
                   onChange={this.props.edit}
                   onBlur={this.props.save}
                   type="text" className="form-control" value={node.instagram || ""}
                   placeholder="" />
           </div>
       </Slider>
    };
}

export default InfoSettings;
