import React from "react";
import moment from "moment";
import routes from "../../../app/routes";
import {
    delivery_price_over,
    delivery_service,
    order_status,
    payment_method
} from "../../../providers/orderOptions";
import {connect} from "react-redux";
import Price from "../../theme/Price";
import {storage} from "../../../providers/helpers";
import LiLink from "../../../views/elements/LiLink";
import RenderOptionProduct from "./RenderOptionProduct";
import elements from "../../../views/cms/options/elements/elements";
const sqlFrmat = 'Do MMMM YYYY, HH:mm';


class Printable extends React.Component {

    state = {

    }

    print = (elem) =>    {
        let root = document.getElementById("root").innerHTML;
        document.getElementById("root").innerHTML = document.getElementById(elem).innerHTML;

        window.print();
        window.close();
        document.getElementById("root").innerHTML = root;
        return true;
    }

    render = () => {
        let order = this.props.order;
        const status = order_status(order.status);
        let fullPrice = parseFloat(order.full_price);
        let deliveryPrice = parseFloat(order.delivery_price);

        if(order.promo_code_discount) {
            fullPrice = fullPrice - ((fullPrice/100) * order.promo_code_discount);
        }
        fullPrice = fullPrice + deliveryPrice;
        console.log(fullPrice)
        return <div>
            <div className="text-right font_2">
                <button onClick={() => this.print("printable")}>
                   <i className="fa fa-print" /> Odštampaj
                </button>
            </div>
            <div id="printable">
                <div className="mb-4 font_4 font-weight-bold">
                    Porudžbina #{order.code}
                </div>

                {order.payment_method == 3 &&
                <div className="mb-3 bg-white bordered p-3">
                    <div className="font_4 mb-3 font-weight-bold">
                        <i className="fas fa-credit-card" /> Plaćanje karticom
                    </div>
                    <div className="font_4 mb-3">
                        <span className=" font-weight-bold"> STATUS TRANSAKCIJE: </span>
                        {!order.card_status &&
                        <div className="">
                            <div className="text-danger font-weight-bold"> Nije završeno </div>
                            <div className="font-italic">
                                Korisnik je otišao na terminal za plaćanje, ali potvrda o transakciji nije stigla. <br />
                                Ovo je samo logovano za praćenje, nikakva akcija dalje nije potrebna.
                            </div>
                        </div>}

                        {order.card_status == 1 &&
                        <span className="text-success font-weight-bold ml-2">USPEŠNO</span>}
                        {order.card_status == 2 &&
                        <span className="text font-weight-bold ml-2">NEUSPEŠNO</span>}
                    </div>

                    {order.card_data &&
                    <>
                        <div className="mb-2">Transaction ID: <strong>{order.card_data?.XID}</strong></div>
                        <div className="mb-2">Transaction Code: <strong>
                            {order.card_data?.TranCode} &nbsp;
                            {order.card_data?.TranCode === "000" &&
                            "(Authorized transaction)"}
                        </strong></div>
                        <div className="mb-2">Card: <strong>{order.card_data?.ProxyPan}</strong></div>
                        <div className="mb-2">TotalAmount: <strong>{(order.card_data?.TotalAmount/100)} din.</strong></div>
                        <div className="mb-2">OrderID: <strong>{order.card_data?.OrderID}</strong></div>
                        <div className="mb-2">ApprovalCode: <strong>{order.card_data?.ApprovalCode}</strong></div>
                        <div className="mb-2">Email: <strong>{order.card_data?.Email}</strong></div>
                        <div className="mb-2">PurchaseTime: <strong>{order.card_data?.PurchaseTime}</strong></div>
                    </>}
                </div>}

                <div className="bg-white bordered mb-4 p-3">
                    <div className="mb-4 font_4" style={{color: status.color}}>
                        <strong> Status: {status.title} </strong>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-3">
                            <div className="font-weight-bold mb-3 border-bottom"> Osnovno </div>
                            <div className="mb-1">Šifra</div>
                            <div className="mb-3">
                                <strong>#{order.code}</strong>
                            </div>
                            <div className="mb-1">Cena</div>
                            <div className="mb-3">

                            {(order.promo_code_discount)
                                ? <div>
                                    <div className="">
                                        - PROMO KOD "{order.promo_code}": {order.promo_code_discount}%
                                    </div>
                                    <div>
                                        (<Price price={order.full_price} /> - {order.promo_code_discount}%) + <Price price={deliveryPrice} />
                                    </div>

                                    <div className="font_2 font-weight-bold">
                                        <Price price={fullPrice} />
                                    </div>
                                </div>
                                : <div className="font_2 font-weight-bold">
                                        <Price price={fullPrice} />
                                    </div>}

                            </div>
                            <div className="mb-1">Datum</div>
                            <div className="mb-3">
                                <strong>{moment.utc(order.created_at).local().format(sqlFrmat)}</strong>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="font-weight-bold mb-3 border-bottom"> Kontakt </div>
                            <div className="mb-1">Ime i prezime</div>
                            <div className="mb-3">
                                <strong>{order.name} {order.lastname}</strong>
                            </div>

                            <div className="mb-1">Telefon</div>
                            <div className="mb-3">
                                <strong>{order.phone}</strong>
                            </div>
                            <div className="mb-1">Email</div>
                            <div className="mb-3">
                                <strong>{order.email}</strong>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="font-weight-bold mb-3 border-bottom"> Adresa </div>
                            <div className="mb-1">Adresa</div>
                            <div className="mb-3">
                                <strong>{order.address}</strong>
                            </div>
                            <div className="mb-1">Grad</div>
                            <div className="mb-3">
                                <strong>{order.zip} {order.city}, {order.state}</strong>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="font-weight-bold mb-3 border-bottom"> Način plaćanja </div>
                            <div>
                            {payment_method(order.payment_method)}
                            </div>


                            {order.delivery_service &&
                            <div className="mt-5">
                                <div className="font-weight-bold mb-3 border-bottom"> Kurirska služba</div>
                                <div className="mb-3">
                                    <img src={delivery_service(order.delivery_service)?.logo} alt="Logo" width={60} className="mr-2" />
                                    <strong>{delivery_service(order.delivery_service)?.name}</strong>
                                </div>
                            </div>}
                        </div>
                    </div>

                    {order.message &&
                    <div className="p-3 mb-3 bcg-2">
                        <div className="font-weight-bold mb-3 border-bottom"> Dodatna napomena </div>
                        <div className="prewrap">{order.message}</div>
                    </div>}

                    {order.delivery_address &&
                    <div className="user_bcg p-3">
                        <div className="font-weight-bold mb-3 border-bottom"> Isporuka na drugu adresu </div>

                        <div className="row mb-3">
                            <div className="col-md-3">
                                <div className="mb-1">Ime i prezime</div>
                                <div className="mb-3">
                                    <strong>{order.delivery_name} {order.delivery_lastname}</strong>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="mb-1">Telefon</div>
                                <div className="mb-3">
                                    <strong>{order.delivery_phone}</strong>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">
                                <div className="mb-1">Adresa</div>
                                <div className="mb-3">
                                    <strong>{order.delivery_address}</strong>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="mb-1">Grad</div>
                                <div className="mb-3">
                                    <strong>{order.delivery_zip} {order.delivery_city}, {order.delivery_state}</strong>
                                </div>
                            </div>
                        </div>
                    </div>}

                </div>

                <table className="table table-bordered">
                    <tbody>
                    {order.products.map((product) => {
                        const filters = product.pivot.filters ?  JSON.parse(product.pivot.filters) : {};
                        let extraPrice = 0;
                        const priceFilters = Object.values(filters);
                        for(let i=0; i<priceFilters.length; i++)    {
                            if(!priceFilters[i].price) continue;
                            extraPrice = extraPrice + parseFloat(priceFilters[i].price);
                        }

                        return <tr key={product.id}>
                            <td width={140} style={{verticalAlign: "middle"}}>
                                <img src={storage(product.image_name)} alt={product.name} className="img-fluid" />
                            </td>
                            <td style={{verticalAlign: "middle"}}>
                                <LiLink className="prewrap" to={routes.singleProduct.route(product)}><strong>{product.name}</strong></LiLink>


                                {order.id < 1564 && product.pivot.filters &&
                                <div className="mt-2">
                                    {Object.keys(filters).map((filterName) => {
                                        return <div key={filterName} className="accent_bcg mb-1 p-2">
                                            <div> {filterName} </div>

                                            {filters[filterName].element === "image"
                                                ? <a href={storage(filters[filterName].selectedValue, true)} target="_blank" rel="noopener noreferrer">
                                                    <img src={storage(filters[filterName].selectedValue)} width={60} className="" alt="option" />
                                                </a>
                                                : filters[filterName].element === "products"
                                                    ? <RenderOptionProduct item={filters[filterName]} />
                                                    : <div> {filters[filterName].selectedValue} </div>}

                                            {filters[filterName].price &&
                                            <div>Cena: +<Price price={filters[filterName].price} /> </div>}
                                        </div>
                                    })}
                                </div>}

                                {product.pivot.filters && product.categories && product.categories.length > 0 &&
                                product.categories.map(cat => {
                                    return cat.options.map((option) => {
                                        const filters = JSON.parse(product.pivot.filters);
                                        return <div key={option.id}>
                                            {option.elements.map((element) => {
                                                if(!filters[element.id]) return null;
                                                const El = elements[element.element].component;
                                                return <div key={element.id} className="accent_bcg p-2 mb-2">
                                                    <El
                                                        item={element}
                                                        order={filters} />
                                                </div>
                                            })}
                                        </div>
                                    })
                                })}

                            </td>
                            <td style={{verticalAlign: "middle"}}>
                                <div>
                                    trenutna: <Price item={product} extra={extraPrice} />
                                </div>
                                <strong>
                                    poručena: <Price price={(parseFloat(product.pivot.price) + extraPrice)} />
                                </strong>
                            </td>
                            <td style={{verticalAlign: "middle"}}>
                                <div className="mb-1">Količina:</div>
                                <div className="mb-3 font_2">
                                    <strong>{product.pivot.qty}</strong>
                                </div>
                            </td>
                            <td style={{verticalAlign: "middle"}}>
                                <div className="mb-1">Ukupno:</div>
                                <div className="mb-3">
                                    <strong><Price price={(parseFloat(product.pivot.price) + extraPrice) * product.pivot.qty} /></strong>
                                </div>
                            </td>
                        </tr>
                    })}
                    <tr>
                        <td colSpan={4}> </td>
                        <td width={180}>
                            <div className="mb-1">
                                Cena:  <Price price={order.full_price} />
                            </div>
                            <div className="mb-2 font_09">
                                Dostava:  {order.delivery_price === 0
                                    ? " besplatna"
                                    : <Price price={order.delivery_price} />}
                            </div>
                            <div className="font_primary">
                                <div className="mb-1 font-weight-bold">Ukupno:</div>
                                <div className="">
                                {(order.promo_code_discount && order.promo_code)
                                ? <div>
                                    <div>
                                        <Price price={order.full_price} />
                                        <div>- {order.promo_code_discount}%</div>
                                        + <Price price={deliveryPrice} />
                                    </div>

                                    <div className="font_2 font-weight-bold">
                                        <Price price={fullPrice} />
                                    </div>
                                </div>
                                : <div className="font_2 font-weight-bold">
                                        <Price price={fullPrice} />
                                    </div>}
                            </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    }

    componentDidMount() {

    }
}
const mapStateToProps = state => ({
    categories: state.categories,
});
export default connect(mapStateToProps)(Printable);