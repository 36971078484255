import React from "react";

class Title extends React.Component {

    render = () => {
        const text = this.props.node.text || "Lorem Ipsum";

        let classname = "prewrap builder_title ";
        if(this.props.node.classes)     {
            classname += Object.values(this.props.node.classes).join(" ");
        }
        let iconLeft = "";
        let iconRight = "";
        if(this.props.node.icon)     {
            if(this.props.node.iconRight)       {
                iconRight = <i className={this.props.node.icon + " ml-1"} />
            }
            else    {
                iconLeft = <i className={this.props.node.icon + " mr-1"} />
            }
        }

        return <div className={classname} style={this.props.node.style || {}}>
            <span>{iconLeft}{text}{iconRight}</span>
        </div>
    };

}
export default Title;
