import React from "react";
import Loader from "../../../views/elements/Loader";
import Page404 from "../../../views/elements/Page404";
import {getOrder} from "../../../providers/hooks";
import Printable from "./Printable";


class OrderStatus extends React.Component {

    state = {
        loading: true,
        order: null
    }

    render = () => {
        if(this.state.loading) {
            return <Loader height={300}/>
        }
        if(!this.state.order) {
            return <Page404 />
        }
        return <div>
            <div className="container mt-4 mb-5 font_2">
                <Printable order={this.state.order} />
            </div>
        </div>
    };

    componentDidMount() {
        getOrder({code: this.props.match.params.code}).then((response) => {
            this.setState({...this.state, loading: false, order: response.data});
        }).catch((error) => {
            this.setState({...this.state, loading: false});
        });
    }
}
export default OrderStatus;
