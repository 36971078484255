import React from "react";
import LinkSettings from "../fragments/settings/LinkSettings";
import MarginSettings from "../fragments/settings/MarginSettings";
import PaddingSettings from "../fragments/settings/PaddingSettings";
import DeleteNodeSettings from "../fragments/settings/DeleteNodeSettings";
import TextSettings from "../fragments/settings/TextSettings";
import ButtonSettings from "../fragments/settings/ButtonSettings";
import IconSettings from "../fragments/settings/IconSettings";

class ButtonSet extends React.Component {

    render = () => {
        return <>
            <ButtonSettings edit={this.props.edit} save={this.props.save} node={this.props.node} open={true} />
            <TextSettings edit={this.props.edit} save={this.props.save} node={this.props.node} />
            <IconSettings edit={this.props.edit} save={this.props.save} node={this.props.node} />
            <LinkSettings edit={this.props.edit} save={this.props.save} node={this.props.node}/>
            <MarginSettings edit={this.props.edit} save={this.props.save} node={this.props.node}/>
            <PaddingSettings edit={this.props.edit} save={this.props.save} node={this.props.node}/>
            <DeleteNodeSettings save={this.props.save} node={this.props.node}/>
        </>
    };

}

export default ButtonSet;
