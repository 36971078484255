import React from "react";
import Loader from "../../elements/Loader";
import {getBlogs} from "../../../providers/hooks";
import Pagination from "../../elements/Pagination";
import update from "react-addons-update";
import {connect} from "react-redux";
import BlogsItem from "./BlogsItem";
import routes from "../../../app/routes";
import {Link} from "react-router-dom";


class Blogs extends React.Component {

    state = {
        loading: true,
        products: {},
        form: {
            page: 1,
            search: "",
        }
    }

    changeField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let newState;
        if(name === "page") {
            newState = update(this.state, {form: {[name]: {$set: value}}});
        } else  {
            newState = update(this.state, {form: {[name]: {$set: value}, page: {$set: 1}}});
        }
        this.setState(newState);
        if(this.props.history)  {
            this.props.history.push(this.route(newState.form));
        }
        this.load(newState.form);
    }

    route = (form = false) => {
        if(!form) form = this.state.form;
        let params = [];
        for(let key in form) {
            let value = form[key];
            if(!value) continue;
            if(typeof value === "object")    {
                value = JSON.stringify(value);
            }
            if(Array.isArray(value))    {
                value = JSON.stringify(value);
            }
            params.push(`${key}=${value}`);
        }
        if(params.length === 0) {
            return "";
        }
        return "?" + params.join("&");
    }

    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }
        return <div className="container mb-5">

            <div className="mb-3 d-flex form-inline">
                <input
                    value={this.state.form.search}
                    name="search"
                    className="form-control mr-3"
                    onChange={this.changeField}
                    type="text" placeholder="Pretražite po imenu..." />
            </div>
            <div className="mb-3">
                <Link to={routes.cmsSingleBlog.route()} className="btn btn-primary mr-3">
                    <i className="fa fa-plus" /> Dodajte vest
                </Link>
            </div>


            {this.state.blogs.data.length > 0
                ? <div>
                    <div className="font-weight-bold mb-2">
                        Ukupno: {this.state.blogs.total}
                    </div>

                    {this.state.blogs.data.map(item => {
                        return <BlogsItem key={item.id} item={item} reload={this.load} />
                    })}

                    <Pagination
                        change={this.changeField}
                        page={this.state.form.page}
                        lastPage={this.state.blogs.last_page} />
                </div>
                : <strong> Nema vesti </strong>}
        </div>
    };

    load = (form = false) => {
        if(!form) form = this.state.form;
        if(this.props.history)      {
            const query = this.props.history.location.search; // could be '?foo=bar'
            const params =  new URLSearchParams(decodeURIComponent(query));
            let pageUrl = params.get('page') ? params.get('page') : 1;
            form = {...form, page: pageUrl};
        }

        getBlogs(form).then((response) => {
            this.setState({...this.state, form: form, loading: false, blogs: response.data});
        });
    }

    componentDidMount() {
        this.load(this.state.form);
    }
}
const mapStateToProps = state => ({
    drawer: state.drawers.products,
});
export default connect(mapStateToProps)(Blogs);
