import React from "react";
import {connect} from "react-redux";
import Loader from "../../../views/elements/Loader";
import Page404 from "../../../views/elements/Page404";
import {getOrder} from "../../../providers/hooks";
import Printable from "./Printable";
import {emptyBasket} from "../../../store/actions/basketActions";


class ThankYou extends React.Component {

    state = {
        loading: true,
        order: null
    }

    render = () => {
        if(this.state.loading) {
            return <Loader height={300}/>
        }
        if(!this.state.order) {
            return <Page404 />
        }
        return <div>

            <div className="container mt-4 mb-4">
                <div className="alert text-center alert-success font_6">
                    Uspešno poručeno! <br />
                    Hvala na poverenju! <br />
                    <div className="mt-3">
                        Print 4 Party
                    </div>
                </div>
            </div>

            <div className="container mt-4 mb-5 font_2">
                <Printable order={this.state.order} />
            </div>
        </div>
    };

    componentDidMount() {
        this.props.emptyBasket();
        getOrder({code: this.props.match.params.code}).then((response) => {
            this.setState({...this.state, loading: false, order: response.data});
        }).catch((error) => {
            this.setState({...this.state, loading: false});
        });
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    emptyBasket: emptyBasket
};
export default connect(mapStateToProps, mapDispatchToProps)(ThankYou);
