import React from 'react';
import {classes} from "../exports";

class Classes extends React.Component   {


    state = {

    }

    render = () => {
        const name = this.props.name;
        return <div className={this.props.className || " "}>
          <div className="font-weight-bold mb-1"> {this.props.title} </div>
          <select
              className="form-control form-control-sm"
              group="classes"
              name={name}
              onChange={this.props.onChange}
              value={(this.props.classes && this.props.classes[name]) ? this.props.classes[name] : ""}>
              <option value={false}> </option>
              {classes[name].map((item) => {
                  return <option key={item[1]} value={item[1]}>
                      {item[0]}
                  </option>
              })}
          </select>
        </div>
    };
}

export default Classes;
