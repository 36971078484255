import React from "react";
import Looper from "../../Looper";
import Lightbox from "react-image-lightbox";
import {storage} from "../../../providers/helpers";
import {connect} from "react-redux";
import SettingsButton from "../../fragments/SettingsButton";

class Gallery extends React.Component {

    state = {
        photoIndex: 0,
        isOpen: false,
    };

    index = (i) => {
        if(this.props.admin && !this.props.preview)   {
            return false;
        }
        this.setState({...this.state, isOpen: !this.true, photoIndex: i});
    }

    toggleLightbox = () => {
        this.setState({...this.state, isOpen: !this.state.isOpen})
    };

    render = () => {
        const columns = this.props.node.columns || 4;
        let className;
        switch (columns)    {
            case "1":
                className = "col-12 mb-4";
                break;
            case "2":
                className = "col-6 mb-4";
                break;
            case "3":
                className = "col-6 col-md-4 mb-4";
                break;
            case "4":
                className = "col-md-3 col-6 mb-4";
                break;
            case "6":
                className = "col-6 col-md-2 mb-4";
                break;
            default:
                className = "col-md-3 col-6 mb-4";
        }
        const images = this.props.node.gallery;
        return <div className="">
            <SettingsButton path={this.props.path} />
            {this.state.isOpen && (
            <Lightbox
                    mainSrc={storage(images[this.state.photoIndex].src,true)}
                    nextSrc={storage(images[(this.state.photoIndex + 1) % images.length].src, true)}
                    prevSrc={storage(images[(this.state.photoIndex + images.length - 1) % images.length].src, true)}
                    onCloseRequest={this.toggleLightbox}
                    onMovePrevRequest={() =>
                        this.setState({...this.state, photoIndex: (this.state.photoIndex + images.length - 1) % images.length})
                    }
                    onMoveNextRequest={() =>
                        this.setState({...this.state, photoIndex: (this.state.photoIndex + 1) % images.length})
                    }
                /> )}


            <Looper
                inline={true}
                parent={{className: "row"}}
                element={{name: "popup", className: className, props: {onClick: this.index,
                        paddingTop: this.props.node.paddingTop,
                        backgroundSize: this.props.node.backgroundSize, backgroundPosition: this.props.node.backgroundPosition}}}
                path={`${this.props.path}.gallery`} />
        </div>
    };
}
const mapStateToProps = state => ({
    admin: state.admin,
    preview: state.preview

});
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
