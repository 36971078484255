export const SET_BASKET = "SET BASKET";
export const ADD_TO_BASKET = "ADD TOO BASKET";
export const DELETE_FROM_BASKET = "DELETE FROM BASKET";
export const EMPTY_BASKET = "EMPTY_BASKET";

export function setBasket(value) {
    return {
        type: SET_BASKET,
        payload: value
    }
}

export function addToBasket(value) {
    return {
        type: ADD_TO_BASKET,
        payload: value
    }
}

export function deleteFromBasket(value) {
    return {
        type: DELETE_FROM_BASKET,
        payload: value
    }
}

export function emptyBasket(value) {
    return {
        type: EMPTY_BASKET,
        payload: value
    }
}