import React from "react";
import {patchPath} from "../../../providers/hooks";
import {openDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";

class Select extends React.Component {

    state = {
        value: this.props.value || "",
    }

    changed = (e) => {
        if(this.props.path)     {
            var val = e.target.value;
            patchPath({path: this.props.path, value: val}).then((response) => {
                this.setState({...this.state, value: val});
                if(this.props.onChange)   this.props.onChange();
                this.props.openDrawer("alert");
            })
        } else {
            if(this.props.onChange)  {
                this.props.onChange(e);
            }
        }
    }

    render = () => {
        return <>
            <select
                disabled={this.props.disabled}
                value={this.state.value}
                onChange={this.changed}
                className={this.props.size ? "form-control form-control-" + this.props.size : "form-control"}
                name={this.props.name}
                required={this.props.required || false}>
                <option value=""> </option>
                {this.props.options.map((item) => {
                    return this.props.render(item);
                })}
            </select>
            </>
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.value !== prevProps.value)    {
            this.setState({...this.state, value: this.props.value});
        }
    }
}
const mapStateToProps = state => ({
    drawers: state.drawers
});
const mapDispatchToProps = {
    openDrawer: openDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Select);
