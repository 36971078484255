import React from "react";
import {connect} from "react-redux";
import Slider from "../Slider";
import Classes from "../Classes";
import {storage} from "../../../providers/helpers";
import {filesDrawer} from "../../../store/actions/drawersActions";


class BackgroundSettings extends React.Component {

    onChange = (e) => {
        this.props.edit(e);
        this.props.compileStyles();
    }

    render = () => {
        const node = this.props.node;
        console.log(node)
       return <Slider header="POZADINA" className="mb-3">
               <div className="mb-3">
                   <div className="font-weight-bold mb-1"> Boja: </div>

                   <div className="d-flex align-items-center">
                   <input type="color"
                          name="backgroundColor"
                          onBlur={this.props.save}
                          onChange={this.props.edit}
                          value={node.backgroundColor || "#ffffff"} />
                       <label className="btn btn-sm btn-danger">
                           <input name="backgroundColor" value={false} onChange={this.props.save}
                                  style={{visibility: "hidden", width: 2}} type="checkbox" />
                           <i className="fa fa-close" />
                       </label>
                       <div> &nbsp; ili &nbsp; </div>
                       <Classes
                           name="bcgColor"
                           classes={node.classes}
                           onChange={this.props.save} />
                   </div>
               </div>

               <div className="mb-2">
                   <div className="font-weight-bold mb-1"> Pozadinska slika: </div>
                   {node.backgroundImage && <img src={storage(node.backgroundImage)} alt="big" width={50} className="mr-2" />}
                   <button
                       onClick={() => this.props.filesDrawer({status: true, callback:
                               (files) => this.props.save({target: {name: "backgroundImage", value: files[0].name}})
                       })}
                       className="btn btn-sm btn-success">
                       <i className="fa fa-image mr-1" />  Dodajte sliku
                   </button>
               </div>

           <div>
               <div className="mb-2">
                   Ponavljanje patterna:
                   <select
                       className="form-control"
                       name="backgroundRepeat"
                       onChange={this.props.save}
                       value={node.backgroundRepeat || ""}>
                       <option value=""> </option>
                       <option value="no-repeat"> No repeat </option>
                       <option value="repeat-x"> Repeat X </option>
                       <option value="repeat-y"> Repeat Y </option>
                   </select>
               </div>
               <div className="mb-2">
                   Pozicija patterna:
                   <select
                       className="form-control"
                       name="backgroundPosition"
                       onChange={this.props.save}
                       value={node.backgroundPosition || ""}>
                       <option value=""> </option>
                       <option> left top </option>
                       <option> left center </option>
                       <option> left bottom </option>
                       <option> right top </option>
                       <option> right center </option>
                       <option>  right bottom </option>
                       <option>  center top </option>
                       <option>  center center </option>
                       <option>  center bottom </option>
                   </select>
               </div>
               <div className="mb-2">
                   Veličina patterna:
                   <select
                       className="form-control"
                       name="backgroundSize"
                       onChange={this.props.save}
                       value={node.backgroundSize || ""}>
                       <option value=""> </option>
                       <option value="cover"> Cover </option>
                       <option value="contain"> Contain </option>
                   </select>
               </div>
           </div>
           </Slider>
    };
}
const mapStateToProps = state => ({
    globals: state.globals
});
const mapDispatchToProps = {
    filesDrawer: filesDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(BackgroundSettings);
