import {OPEN_DRAWER, CLOSE_DRAWER, FILES_DRAWER} from "../actions/drawersActions";
import update from "react-addons-update";

const drawers = {
    categories: {status: false},
    basket: {status: false},
    files: {status: false, callback: false, bucket: false},
    products: {status: false, callback: false, selected: false},
    grids: {status: false, callback: false},
    elements: {status: false, callback: false},
    alert: {status: false},
    error: {status: false, content: false},
    bottomNav: {status: false, button: ""},
    info: {status: false},
    ordersCount: {status: false, count: 0},
}

export default function(state= drawers, {type, payload})      {
    switch(type)        {
        case OPEN_DRAWER:
            if(payload.name)        {
                return update(state, {[payload.name]: {$set:
                            {...drawers[payload.name], ...payload, ...{status: true}}}});
            }
            return  update(state, {[payload]: {status: {$set: true}}});
        case CLOSE_DRAWER:
            return  update(state, {[payload]: {status: {$set: false}}});
        case FILES_DRAWER:
            return  update(state, {files: {
                    status: {$set: payload.status},
                    callback: {$set: payload.callback},
                    bucket: {$set: payload.bucket},
            }});
        default:
            return state;
    }
}