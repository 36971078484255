import React from "react";
import {grids} from "../../exports";
import {connect} from "react-redux";
import {closeDrawer} from "../../../store/actions/drawersActions";


class GridsModal extends React.Component {

    state = {
        grids: []
    }

    selected = (item) => {
        this.props.drawer.callback(item.default);
        this.props.closeDrawer("grids");
    }

    render = () => {
        return <div>
            {this.state.grids.map((item) => {
                if(this.props.admin.id !== 1 && item.name === "Skriveni")   return "";
                 return <div key={item.name} className="mmb-3">
                     <div className="font-weight-bold mb-2">{item.name}</div>
                     <div className="row">
                         {item.children.map((child, i) => {
                             return <div key={i} className="col-md-4 col-6 mb-3">
                                 <button onClick={() => this.selected(child)}
                                         className="btn btn-primary w-100">
                                     {child.name}
                                     <div className="">
                                         {child.description}
                                     </div>
                                 </button>
                             </div>
                         })}
                     </div>
                 </div>
             })}
         </div>
    };

    componentDidMount() {
        let holder = {};
        const grd = Object.values(grids);
        for(let i=0; i<grd.length; i++)       {
            const item = grd[i];
            if(!holder[item.folder]) {
                holder[item.folder] = {name: item.folder, children: []};
            }
            holder[item.folder].children.push(item);
        }
        this.setState({...this.state, grids: Object.values(holder)});
    }
}
const mapStateToProps = state => ({
    admin: state.admin,
    drawer: state.drawers.grids
});
const mapDispatchToProps = {
    closeDrawer: closeDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(GridsModal);
