import React from "react";
import Slider from "../Slider";

class IconSettings extends React.Component {

    render = () => {
        const node = this.props.node;
       return <Slider header="IKONICA" className="mb-3">
           <div>
               Odaberite ikonicu:
               <div>
                   <label className="form-inline mr-3 mb-2 d-inline-block">
                       <input
                           checked={!node.iconText}
                           name="iconText"
                           onChange={this.props.save}
                           value="" type="radio" className="form-control mr-1" />
                       Nema
                   </label>
                   {["fa fa-plus", "fa fa-phone", "fa fa-mobile", "fa fa-envelope",  "fa fa-download",
                   "fa fa-facebook", "fa fa-instagram", "fa fa-youtube-play"].map((item) => {
                       return <label key={item} className="form-inline mr-3 mb-0 d-inline-block">
                           <input
                               checked={node.iconText === item}
                               onChange={this.props.save}
                               name="iconText" value={item} type="radio" className="form-control mr-1" />
                           <i className={item} />
                       </label>
                   })}
               </div>
           </div>
           <div>
               <label className="m-0">
                   <input
                       onChange={this.props.save}
                       name="iconRight"
                       type="checkbox" className="mr-2"
                       checked={node.iconRight || false} />
                   Desno od teksta
               </label>
           </div>
       </Slider>
    };
}

export default IconSettings;
