import React from "react";
import Classes from "../Classes";
import Slider from "../Slider";

class PaddingSettings extends React.Component {

    render = () => {
        const node = this.props.node;
       return <Slider header="PADDING" className="mb-3">
           <Classes
               className="mb-3"
               title="Padding top"
               name="paddingTop"
               classes={node.classes}
               onChange={this.props.save} />
           <Classes
               className="mb-3"
               title="Padding bottom"
               name="paddingBottom"
               classes={node.classes}
               onChange={this.props.save} />
           <Classes
               className="mb-3"
               title="Padding right"
               name="paddingRight"
               classes={node.classes}
               onChange={this.props.save} />
           <Classes
               className="mb-3"
               title="Padding left"
               name="paddingLeft"
               classes={node.classes}
               onChange={this.props.save} />
       </Slider>
    };
}

export default PaddingSettings;
