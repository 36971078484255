import {server} from "./hooks";

export function randString(length=5) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function storage(item, big = false) {
    if(!item)   return '/images/000-no-image.png';
    if(item.endsWith('.pdf'))   return '/images/000-pdf.png';
    if(big) return server('galerija/lg/' + item);
    return server('galerija/sm/' + encodeURIComponent(item));
}

export function processPrice(item)      {
    if(item.discount_price)     return item.discount_price;
    return item.price;
}
export function calculateDiscount(item) {
    return Math.round((item.price - item.discount_price)/item.price * 100);
}

export function merge(target, source)  {
    if(source.structure) {
        return source;
    }
    return target;
    /*
    // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
    for (const key of Object.keys(source)) {
        if (source[key] instanceof Object) Object.assign(source[key], this.merge(target[key], source[key]))
    }
    // Join `target` and modified `source`
    Object.assign(target || {}, source)
    return target
     */
}

export function findCategoryBySlug(slug, categories)  {
    return checkSlug(slug, categories);
}
function checkSlug(slug, categories)        {
    for(let i=0; i<categories.length; i++)      {
        const item = categories[i];
        if(item.slug === slug)   {
           return item;
        }
        if(item.children.length > 0)        {
            const selected = checkSlug(slug, item.children);
            if(selected) return selected;
        }
    }
}

export function findCategoryById(id, categories)  {
    return checkId(id, categories);
}
function checkId(id, categories)        {
    for(let i=0; i<categories.length; i++)      {
        const item = categories[i];
        if(item.id === id)   {
            return item;
        }
        if(item.children.length > 0)        {
            const selected = checkId(id, item.children);
            if(selected) return selected;
        }
    }
}

export function categoryBreadcrumbs(id, categories) {
    return checkAll(id, categories, []);
}
function checkAll(id, categories, acc)  {
    if(!id) return acc;
    const cat = checkId(id, categories);
    if(cat)     {
        acc.unshift(cat);
        acc = checkAll(cat.parent, categories, acc);
    }
    return acc;
}

export function flatten(array) {
    return flattenAll(array, [], 1);
}
function flattenAll(array, acc, lvl)  {
    for(let i=0; i<array.length; i++) {
        const item = array[i];
        item.lvl = lvl;
        acc.push(item);
        if(item.children && item.children.length > 0)   {
            acc = flattenAll(item.children, acc, lvl+1);
        }
    }
    return acc;
}

export function getChildrenIds(category) {
    let holder = [category.id];
    return loopChildren(category.children, holder);
}
function loopChildren(items, holder) {
    if(items)   {
        for(let i=0; i<items.length; i++)       {
            holder.push(items[i].id);
            holder = loopChildren(items[i].children, holder);
        }
    }
    return holder;
}

export function pluck(arr, name, value=false)      {
    let holder = [];
    if(value) {
        holder = {};
    }
    for(let i=0; i<arr.length; i++)     {
        if(arr[i][name])        {
            if(value)       {
                holder[arr[i][name]] = arr[i][value];
            }
            else        {
                holder.push(arr[i][name]);
            }
        }
    }
    return holder;
}