import React from "react";
import {getCategories, putProductCategories} from "../../../providers/hooks";
import update from "react-addons-update";


class Categories extends React.Component {

    state =  {
        categories: [],
        selected: this.props.selected || []
    }

    changed = (e, item) => {
        const checked = e.target.checked;
        let categories;
        if(checked)     {
            const newState = update(this.state, {selected: {$push: [item]}});
            this.setState(newState);
            categories = newState.selected;
        } else  {
            const newSelected = this.state.selected.filter(select => select.id !== item.id);
            this.setState({...this.state, selected: newSelected});
            categories = newSelected;
        }
        this.props.onChange({target: {name: 'categories', value: categories}});
        if(this.props.product)     {
            putProductCategories({categories: categories, product: this.props.product}).then((response) => {
                this.setState({...this.state, selected: response.data});
            });
        }
    }

    loop = (items) => {
        const selected = this.state.selected.map(item => item.id);
        return <div className="mb-3 ml-3">
            {items.map((item) => {
                return <span key={item.id}>

                    {item.children.length > 0
                        ? <div>
                            <div className="mb-2"> {item.name} </div>
                            {this.loop(item.children)}
                        </div>
                        : <label className="mr-3">
                            <input
                                checked={selected.includes(item.id)}
                                onChange={(e) => this.changed(e, item)}
                                type="checkbox"
                                className="mr-2" />
                                {item.name}
                        </label>}
                </span>
            })}
        </div>
    }

    render = () => {
        return <div>
            {this.loop(this.state.categories)}
            </div>
    };

    componentDidMount() {
        getCategories().then((response) => {
            this.setState({...this.state, categories: response.data});
        });
    }
}

export default Categories;
