import React from 'react';
import LiLink from "../../../views/elements/LiLink";


class CategorySlide extends React.Component {

    state = {
        open: this.props.open
    }

    clicked = (e) => {
        e.preventDefault();
        this.setState({...this.state, open: !this.state.open});
    }

    render = () => {
        const item = this.props.item;
        return  <div>
            {item.children.length > 0
                ? <a href={`/${this.props.object.page}/${item.slug}`}
                       onClick={this.clicked}
                       className="font-weight-bold d-flex justify-content-between">
                        {item.name}
                        <i className={this.state.open ? "fa fa-chevron-up" : "fa fa-chevron-down"} />
                    </a>
                : <LiLink to={`/${this.props.object.page}/${item.slug}`} className="font-weight-bold">
                    {item.name} ({item.count}
                </LiLink>}


            <div className={this.state.open ? "" : "d-none"}>
                {item.children.map((child) => {
                    return <div key={child.id} className="pl-3">
                        <LiLink to={`/${this.props.object.page}/${child.slug}`}>
                            {this.props.category && this.props.category.slug === child.slug &&
                            <i className="fa fa-check mr-1" />}
                            {child.name} <span className="gray">({child.count})</span>
                        </LiLink>
                    </div>
                })}
            </div>
        </div>
    };
}
export default CategorySlide;
