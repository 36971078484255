export const SET_PAGE = "SET PAGE";
export const INSERT_NODE = "INSERT PAGE NODE";

export function setPage(value) {
    return {
        type: SET_PAGE,
        payload: value
    }
}
export function insertPageNode(value) {
    return {
        type: INSERT_NODE,
        payload: value
    }
}