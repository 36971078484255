import React from "react";
import Input from "../../../elements/edit/Input";

import elements from "./elements";
import Price from "../../../../builder/theme/Price";


class CheckboxOption extends React.Component {

    state = {
        edit: false,
    }


    render = () => {
        const item = this.props.item;

        if(this.props.admin)     {
            return <div className="d-flex justify-content-between">
                <div className="flex-grow-1 mr-3">
                    <div className="mb-2">
                        Element: <strong> {elements[item.element].name} </strong>
                    </div>
                    {this.state.edit
                        ?  <>
                            <div className="mb-2">
                                Polje (naslov):
                                <Input
                                    path={`option_elements.${item.id}.field_1`}
                                    onBlur={() => this.props.reload()}
                                    value={item.field_1} name="field_1"
                                    maxLength={199} autoComplete="off"  />
                            </div>
                            {this.props.edit}
                        </>
                        : <>
                            <div className="mb-2">  Polje (naslov): <strong> {item.field_1} </strong></div>
                            {this.props.show}
                        </>}
                </div>
                <div>
                    <div className="mb-1">
                        <button
                            onClick={() => this.setState({...this.state, edit: !this.state.edit})}
                            className="btn btn-success btn-sm btn-block">{this.state.edit ? "Vrati" : "Prepravite"}</button>
                    </div>
                    {this.props.buttons}
                </div>
            </div>
        }


        if(!this.props.editField)   {
            return <div>
                <div className="font-weight-bold">{item.field_1} {item.required && "*"} </div>
                <div>
                    {this.props.order[item.id].value ? "DA" : "NE"}
                    {item.price &&
                    <span className="ml-2"> (Cena opcije: +<Price price={item.price} />)</span>}
                </div>


                {this.props.order[item.id].value && item.children &&
                <div>
                    {item.children.map((child) => {
                        const El = elements[child.element].component;
                        return  <div key={child.id} className="mt-1 ml-4">
                            <El
                                item={child}
                                order={this.props.order} />
                        </div>
                    })}
                </div>}
            </div>
        }

        return <div>
            <label className="font-weight-bold mb-1 chbx">
                <input
                    onChange={(e) => this.props.editField(item.field_1, e.target.checked ? 1 : null, item)}
                    type="checkbox"
                    name={item.id}
                    required={item.required}
                    checked={this.props.order[item.id] ? this.props.order[item.id].value : false}
                    value="1"  /> <span />
                &nbsp; {item.field_1} {item.required && "*"}
            </label>
            {item.price &&
            <div> Cena opcije: +<Price price={item.price} /></div>}

            {this.props.order[item.id] && this.props.order[item.id].value && item.children &&
            <div className="mt-3">
                {item.children.map((child) => {
                    const El = elements[child.element].component;
                    return  <div key={child.id} className="mb-3 ml-4">
                        <El
                            item={child}
                            editField={this.props.editField}
                            order={this.props.order} />
                    </div>
                })}
            </div>}
        </div>
    };
}
export default CheckboxOption;