import React from "react";
import LinkSettings from "../fragments/settings/LinkSettings";
import MarginSettings from "../fragments/settings/MarginSettings";
import PaddingSettings from "../fragments/settings/PaddingSettings";
import DeleteNodeSettings from "../fragments/settings/DeleteNodeSettings";
import ImageSettings from "../fragments/settings/ImageSettings";

class ImageSet extends React.Component {

    render = () => {
        return <>
            <ImageSettings edit={this.props.edit} save={this.props.save} node={this.props.node} />
            <LinkSettings edit={this.props.edit} save={this.props.save} node={this.props.node}/>
            <MarginSettings edit={this.props.edit} save={this.props.save} node={this.props.node}/>
            <PaddingSettings edit={this.props.edit} save={this.props.save} node={this.props.node}/>
            <DeleteNodeSettings save={this.props.save} node={this.props.node}/>
        </>
    };

}

export default ImageSet;
