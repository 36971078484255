import React from "react";
import {putOptionElement} from "../../../providers/hooks";
import Modal from "../../framework/Modal";
import elements from "./elements/elements";



class AddElement extends React.Component {

    state = {
        modal: false
    }

    add = (element) => {
        putOptionElement(
            {element: element,
                option: this.props.item ||  null,
                parent: this.props.parent || null,
                select: this.props.select || null}
                ).then((response) => {
            this.props.reload();
            this.setState({...this.state, modal: false});
        })
    }

    render = () => {
        return <div>
            <Modal
                width={960}
                open={this.state.modal}
                close={() => this.setState({...this.state, modal: false})}>

                <div className="row">
                    {Object.values(elements).map((item) => {
                        return <div className="col-md-4 p-2 mb-2" key={item.element}>
                            <button
                                onClick={() => this.add(item.element)}
                                className="btn btn-primary btn-block">
                                {item.name}

                                <div>
                                    <img src={item.image} className="img-fluid" alt="slika" />
                                </div>
                            </button>
                        </div>
                    })}
                </div>
            </Modal>

            <button
                onClick={() => this.setState({...this.state, modal: true})}
                className="btn btn-primary btn-sm">
                {this.state.block ? "Odustani" : this.props.title || "Dodajte element u blok"}
            </button>
        </div>
    };

}
export default AddElement;
