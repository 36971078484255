import React from 'react';
import Overlay from "./Overlay";


class Modal extends React.Component   {

    render = () => {
        if(!this.props.open) return "";
        let style = {};
        if(this.props.width) style.maxWidth = this.props.width;
      return <>
          <div className="builder_modal_content" style={style}>
              <div className="builder_modal_inner">
                  {this.props.children}
              </div>
          </div>
          <Overlay show={this.props.open} close={this.props.close} />
      </>
    };
}

export default Modal;
