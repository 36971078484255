import React from "react";
import {connect} from "react-redux";
import SettingsButton from "../../../fragments/SettingsButton";
import Looper from "../../../Looper";
import {getUser, patchUser} from "../../../../providers/hooks";
import LeftMenu from "../LeftMenu";
import Switch from "../../../../views/elements/Switch";


class Newsletter extends React.Component {

    state = {
        newsletter: false
    };

    change = (val) => {
        patchUser({field: "newsletter", value: val ? 1 : null}).then((response) => {
            this.setState({...this.state, newsletter: val});
        });
    };

    render = () => {
        return <div>
                <SettingsButton path={this.props.path} />

            <div className="row">
                <div className="col-md-3">
                    <LeftMenu />
                </div>
                <div className="col-md-9">
                    <Looper path={`${this.props.path}.title`} />

                    <div className="mb-5 p-3 user_bcg">
                        <div className="font_2 mb-3">
                            {this.state.newsletter
                                ? "Prijavljeni ste za newsletter."
                                : "Niste prijavljeni za newsletter. Kliknite na dugme ispod."}

                        </div>

                        <Switch onChange={this.change}
                                default={this.state.newsletter}
                                label={<div className="d-md-inline-block">
                                    Prijavite se za newsletter.
                                </div>} />

                    </div>
                </div>
            </div>
        </div>
    }

    componentDidMount() {
        getUser().then((response) => {
            this.setState({...this.state, newsletter: !!response.data.newsletter});
        });
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Newsletter);