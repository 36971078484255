import React from "react";
import {connect} from "react-redux";
import SettingsButton from "../../fragments/SettingsButton";
import Looper from "../../Looper";
import {setPage} from "../../../store/actions/pageActions";


class Breadcrumbs extends React.Component {


    render = () => {
        return <div>
            <SettingsButton path={this.props.path} />

            <div className="d-flex align-items-center breadcrumbs">
                <Looper
                    inline={true}
                    path={`${this.props.path}.links`} />
            </div>
        </div>
    };
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    setPage: setPage,
};
export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs);
