import {SET_GLOBALS, INSERT_NODE} from "../actions/globalsActions";
import dotProp from "dot-prop-immutable";

export default function(state= false, {type, payload})      {
    switch(type)        {
        case SET_GLOBALS:
            if(payload) {
                if (!payload.structure) {
                    payload.structure = {};
                }
            }
            return payload;
        case INSERT_NODE: // INSERTING DEFAULT NODE ON COMPONENT DID MOUNT (Looper)
            return dotProp.set(state, payload.path, payload.node);
        default:
            return state;
    }
}