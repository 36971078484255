import React from 'react';
import {deletePath} from "../../../providers/hooks";
import AddFilter from "./AddFilter";


class FiltersItem extends React.Component   {

    state = {
        settings: false
    }

    delete = () => {
        var r = window.confirm("Želite da obrišete filter?");
        if (r !== true) {
            return false;
        }
        deletePath({path: "filters." + this.props.item.id}).then((response) => {
            this.setState({...this.state, settings: false});
            this.props.reload();
        })
    }

    render = () => {
        let item = this.props.item;
        return <div>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    {this.props.collapseIcon}
                    {this.props.handler}
                    {item.name}
                </div>
                <button
                    onClick={() => this.setState({...this.state, settings: !this.state.settings})}
                    className="btn btn-primary btn-sm">
                    <i className="fa fa-gear" />
                </button>
            </div>
            {this.state.settings &&
            <div className="mt-3 p-3">
                <div className="mb-3">
                    <AddFilter item={item} reload={this.props.reload} />
                </div>
                <div className="mb-3">
                    <AddFilter parent={item.id} reload={this.props.reload} />
                </div>
                <button
                    onClick={this.delete}
                    className="btn btn-danger">
                    <i className="fa fa-trash" /> Obrišite
                </button>
            </div>}
        </div>;
    };
}
export default FiltersItem;
