import {SET_ADMIN} from "../actions/adminActions";
import {checkAdmin} from "../../providers/adminProvider";

export default function(state= checkAdmin(), {type, payload})      {
    switch(type)        {
        case SET_ADMIN:
            return payload;
        default:
            return state;
    }
}