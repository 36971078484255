import React from "react";
import {connect} from "react-redux";
import SettingsButton from "../../../fragments/SettingsButton";
import Looper from "../../../Looper";
import {getBlogs, getProducts} from "../../../../providers/hooks";
import {storage} from "../../../../providers/helpers";
import {Link} from "react-router-dom";
import Loader from "../../../../views/elements/Loader";
import Pagination from "../../../../views/elements/Pagination";
import update from "react-addons-update";
import moment from "moment";
import routes from "../../../../app/routes";


class Blogs extends React.Component {

    default = {
        page: 1,
        pretraga: "",
    }
    state = {
        loading: true,
        products: {},
        form: this.default,
    }

    scrollToTop = () => {
        window.scrollTo({ top: 200, behavior: 'smooth' });
    };

    route = (form = false) => {
        if(!form) form = this.state.form;
        let params = [];
        for(let key in form) {
            let value = form[key];
            if(!value) continue;
            if(typeof value === "object")    {
                value = JSON.stringify(value);
            }
            if(Array.isArray(value))    {
                value = JSON.stringify(value);
            }
            params.push(`${key}=${value}`);
        }
        if(params.length === 0) {
            return "";
        }
        return "?" + params.join("&");
    }

    editField = (name, value) => {
        let newState;
        if(name === "page") {
            newState = update(this.state, {form: {[name]: {$set: value}}});
        } else  {
            newState = update(this.state, {form: {[name]: {$set: value}, page: {$set: 1}}});
        }
        this.setState(newState);
        this.props.history.push(this.route(newState.form));
        this.load(newState.form);
        this.scrollToTop();
    }

    render = () => {
        if(this.state.loading)      {
            return <Loader height={399} />
        }
        return <div>
            <SettingsButton path={this.props.path} />

            {this.state.blogs?.data?.length === 0
                ? <div className="p-5 font_14 text-center">Nema Vesti</div>
                : <>
                    {this.state.blogs.data.map(item => {
                        return <div key={item.id} className="row border-bottom">
                            <div className="col-md-3">
                                <img src={storage(item.image_name,true)} className="img-fluid mb-3" alt={item.title} />
                            </div>

                            <div className="col-md-9 pt-3 pb-3">
                                <Link to={routes.singleBlog.route(item)} className="link">
                                    <h3 className="font-weight-bold font_secondary">{item.title}</h3>
                                </Link>
                                <div className="gray prewrap mb-3">{item.description}</div>
                                <div className="gray mb-3">
                                    <i className="fas fa-calendar mr-2" aria-hidden="true" />
                                    {moment.utc(item.created_at).local().format('DD.MM.YYYY HH:mm')}
                                </div>
                                <div>
                                    <Link to={routes.singleBlog.route(item)} className="btn btn-primary">
                                        Detaljnije
                                    </Link>
                                </div>
                            </div>
                        </div>
                    })}

                    <Pagination
                        change={(e) => this.editField("page", e.target.value)}
                        page={this.state.form.page}
                        lastPage={this.state.blogs.last_page} />
                </>}
        </div>
    }

    load = (form = false) => {
        getBlogs(form).then((response) => {
            console.log(response.data)
            this.setState({...this.state, loading: false, blogs: response.data});
        })
    }

    componentDidMount()     {
        this.load();
    }
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(Blogs);