import React from 'react';
import {putFilter} from "../../../providers/hooks";
import update from "react-addons-update";
import Input from "../../elements/edit/Input";


class AddFilter extends React.Component   {

    default = {
        id: this.props.item ? this.props.item.id : false,
        name: this.props.item ? this.props.item.name : "",
        slug: this.props.item ? this.props.item.slug : "",
        parent: this.props.parent || null
    }
    state = {
        open: false,
        filter: this.default
    };

    submit = (e) => {
        e.preventDefault();
        putFilter(this.state.filter).then((response) => {
            this.props.reload();
            this.setState({...this.state, open: false, filter: this.default});
        })
    }

    toggleOpen = () => {
        this.setState({...this.state, open: !this.state.open});
    }

    editField = (e) => {
        const field = e.target.name;
        let value = e.target.value;
        this.setState(update(this.state, {filter: {[field]: {$set: value}} }));
    }

    render = () => {
        if(!this.state.open)     {
            return <button
                    onClick={this.toggleOpen}
                    className="btn btn-primary">
                {this.props.item
                    ? <span> <i className="fa fa-edit" /> Prepravite filter </span>
                    : <span> <i className="fa fa-plus" /> Dodajte {this.props.parent && "pod"}filter </span>}
            </button>
        }
        const filter = this.state.filter;
        return <div className="light_block p-3 position-relative">
            <form action="#" onSubmit={this.submit}>
                <div className="row mb-1">
                    <div className="col-md-6">
                        <label className="d-block">
                            <div className="mb-2">
                                <strong> Naziv filtera: </strong>
                            </div>
                            <Input
                                path={filter.id && `filters.${filter.id}.name`}
                                onChange={this.editField}
                                onBlur={this.props.reload}
                                value={filter.name} name="name"
                                autoComplete="off"
                                maxLength={191} required />
                        </label>
                    </div>
                </div>
                {!filter.id &&
                <button type="submit" className="btn btn-primary">
                    Sačuvajte
                </button>}
                <button
                    style={{top: 4, right: 4}}
                    onClick={this.toggleOpen}
                    className="btn btn-primary btn-sm position-absolute">
                    <i className="fa fa-close" />
                </button>
            </form>
        </div>;
    };
}
export default AddFilter;
