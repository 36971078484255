import React from "react";
import {Route, Redirect} from "react-router-dom";
import { connect } from "react-redux";
import routes from "../app/routes";


const UserMiddleware = ({ component: Component, ...rest }) => {
    return <Route {...rest} render={(props) => (
        rest.user === false
            ? <Redirect to={routes.userLogin.route} />
            : <Component {...props} />
    )}/>
};

const mapStateToProps = state =>    ({
    user: state.user
});

export default connect(mapStateToProps)(UserMiddleware);
