export const OPEN_DRAWER = "OPEN DRAWER";
export const CLOSE_DRAWER = "CLOSE DRAWER";
export const FILES_DRAWER = "FILES DRAWER";

export function closeDrawer(value) {
    return {
        type: CLOSE_DRAWER,
        payload: value
    }
}

export function openDrawer(value) {
    return {
        type: OPEN_DRAWER,
        payload: value
    }
}

export function filesDrawer(value) {
    return {
        type: FILES_DRAWER,
        payload: value
    }
}