import React from "react";
import MarginSettings from "../fragments/settings/MarginSettings";
import DeleteNodeSettings from "../fragments/settings/DeleteNodeSettings";

class SocialsSet extends React.Component {

    render = () => {
        return <>
            <MarginSettings edit={this.props.edit} save={this.props.save} node={this.props.node}/>
            <DeleteNodeSettings save={this.props.save} node={this.props.node}/>
        </>
    };

}

export default SocialsSet;
