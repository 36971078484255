import React from 'react';
import { putPromoCode} from "../../../providers/hooks";
import update from "react-addons-update";
import moment from "moment";

function randString(length= 6) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

class AddPromo extends React.Component   {

    default = {
        code: "",
        count: 1,
        discount: 0,
        expires_at: ""
    }
    state = {
        open: false,
        promocode: this.default
    };

    generateCode = () => {
        return randString(6).toUpperCase();
    }

    submit = (e) => {
        e.preventDefault();
        let form = {...this.state.promocode};
        console.log(form)
        if(form.expires_at)   {
            const expires = moment(form.expires_at).utc().format('YYYY-MM-DD HH:mm:ss');
            form = {...form, expires_at: expires};
        }
        putPromoCode(form).then((response) => {
            this.props.reload();
            this.setState({...this.state, open: false, promocode: this.default});
        })
    }

    toggleOpen = () => {
        this.setState({...this.state, open: !this.state.open, promocode: {...this.state.promocode, code: this.generateCode()}});
    }

    editField = (e) => {
        const field = e.target.name;
        let value = e.target.value;
        this.setState(update(this.state, {promocode: {[field]: {$set: value}} }));
    }

    render = () => {
        if(!this.state.open)     {
            return <button 
                    onClick={this.toggleOpen} 
                    className="btn btn-primary">
                    <span> <i className="fa fa-plus" /> Dodajte promo kod </span>
            </button>
        }
  
        return <div className="dark_block p-3 position-relative mb-5">
            <form action="#" onSubmit={this.submit}>
                <div className="mb-3 font-weight-bold">
                PROMO KOD:
                </div>
                <div className="d-flex flex-items-center mb-3">
                    <input
                        onChange={this.editField}
                        value={this.state.promocode.code} name="code"
                        autoComplete="off"
                        className="form-control w-auto"
                        maxLength={191} required />

                    <button type="button" onClick={(e) => {
                            this.setState({...this.state, promocode: {...this.state.promocode, code: this.generateCode()}})
                        }} className="btn btn-info">
                        REGENERATE
                    </button>
                </div>

            
                <div className="mb-1 font-weight-bold">
                    BROJ KODOVA
                </div>
                <div className="d-flex align-items-center mb-3">
                    <input
                        onChange={this.editField}
                        value={this.state.promocode.count} name="count"
                        type="number"
                        min={1}
                        className="form-control w-auto"
                        required />

                        <div className="text-gray-500 ml-2">
                            koliko puta kod može da se iskoristi (default je 1)
                        </div>
                </div>

                <div className="mb-1 font-weight-bold">
                    POPUST
                </div>
                <div className="d-flex align-items-center mb-3">
                    <input
                        onChange={this.editField}
                        value={this.state.promocode.discount} name="discount"
                        type="number"
                        min={1}
                        className="form-control w-auto"
                        required />

                        <div className="text-gray-500 ml-2">
                            %
                        </div>
                </div>
              
                <div className="mb-1 font-weight-bold">
                    DATUM ISTEKA KODA
                </div>
                <div className="d-flex align-items-center mb-4">
                    <input 
                        type="datetime-local"
                        className="form-control w-auto"
                        min={1} 
                        value={this.state.promocode.expires_at}
                        name="expires_at" 
                        onChange={this.editField} />
                   

                        <div className="text-gray-500 ml-2">
                            Ako je prazno, traje neograničeno.
                        </div>
                </div>

                <button type="submit" className="btn btn-success">
                    KREIRAJTE PROMO KOD
                </button>
            </form>

            <button
                style={{top: 4, right: 4}}
                onClick={this.toggleOpen}
                className="btn btn-primary btn-sm position-absolute">
                <i className="fa fa-close" />
            </button>
        </div>;
    };
}
export default AddPromo;
