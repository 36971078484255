import React from "react";
import MarginSettings from "../../fragments/settings/MarginSettings";
import PaddingSettings from "../../fragments/settings/PaddingSettings";
import DeleteNodeSettings from "../../fragments/settings/DeleteNodeSettings";
import BackgroundSettings from "../../fragments/settings/BackgroundSettings";

class NewsletterSet extends React.Component {

    render = () => {
        return <>
            <BackgroundSettings edit={this.props.edit} save={this.props.save} node={this.props.node} />
            <MarginSettings edit={this.props.edit} save={this.props.save} node={this.props.node}/>
            <PaddingSettings edit={this.props.edit} save={this.props.save} node={this.props.node}/>
            <DeleteNodeSettings save={this.props.save} node={this.props.node}/>
        </>
    };

}

export default NewsletterSet;
