import {SET_PAGE, INSERT_NODE} from "../actions/pageActions";
import dotProp from "dot-prop-immutable";

export default function(state= false, {type, payload})      {
    switch(type)        {
        case SET_PAGE:
            if(payload) {
                if(!payload.structure)       {
                    payload.structure = [];
                }
            }
            return payload;
        case INSERT_NODE:
            return dotProp.set(state, payload.path, payload.node);
        default:
            return state;
    }
}