import React from 'react';
import {putPage} from "../../../providers/hooks";
import update from "react-addons-update";
import Input from "../../../views/elements/edit/Input";
import {connect} from "react-redux";
import Select from "../../../views/elements/edit/Select";
import {flatten} from "../../../providers/helpers";
import Slider from "../Slider";


class AddPage extends React.Component   {

    default = {
        id: this.props.item ? this.props.item.id : false,
        name: this.props.item ? this.props.item.name : "",
        slug: this.props.item ? this.props.item.slug : "",
        link: this.props.item ? this.props.item.link : "",
        category: this.props.item ? this.props.item.category : "",
        seo_title: this.props.item ? this.props.item.seo_title : "",
        seo_description: this.props.item ? this.props.item.seo_description : "",
        seo_keywords: this.props.item ? this.props.item.seo_keywords : "",
        structure: null,
        parent: this.props.parent || null
    }
    state = {
        open: false,
        page: this.default
    };

    submit = (e) => {
        e.preventDefault();
        putPage(this.state.page).then((response) => {
            this.props.reload();
            this.setState({...this.state, open: false, page: this.default});
        })
    }

    toggleOpen = () => {
        this.setState({...this.state, open: !this.state.open});
    }

    editField = (e) => {
        const field = e.target.name;
        let value = e.target.value;
        if(field === "category")      {
            if(e.target.value)      {
                this.setState(update(this.state, {page: {[field]: {$set: value}, structure: {$set: JSON.stringify([{"grid": "shop"}])}} }));
                return false;
            }
            this.setState(update(this.state, {page: {[field]: {$set: value}, structure: {$set: null}} }));
            return false;
        }
        this.setState(update(this.state, {page: {[field]: {$set: value}} }));
    }

    renderItem = (item) => {
        return <option key={item.id} value={item.slug}>
            {" - ".repeat(item.lvl)} {item.name}
        </option>
    }

    render = () => {
        if(!this.state.open)     {
            return <button
                    onClick={this.toggleOpen}
                    className="btn btn-primary">
                {this.props.item
                    ? <span><i className="fa fa-edit" /> Prepravite stranu </span>
                    : <span><i className="fa fa-plus" /> Dodajte {this.props.parent && "pod"}stranu </span>}
            </button>
        }
        const page = this.state.page;

        return <div className="light_block p-3 position-relative">
            <form action="#" onSubmit={this.submit}>
                <div className="row">
                    <div className="col-md-6">
                        <label className="d-block mb-3">
                            <div className="mb-2">
                                <strong> Naziv strane: </strong>
                            </div>
                            <Input
                                path={page.id && `pages.${page.id}.name`}
                                onChange={this.editField}
                                onBlur={this.props.reload}
                                value={page.name} name="name"
                                autoComplete="off"
                                maxLength={191} required />
                        </label>
                    </div>
                    <div className="col-md-6">
                        <label className="d-block mb-3">
                            <div className="mb-2">
                                <strong> Slug strane: </strong>
                            </div>
                            <Input
                                path={page.id && `pages.${page.id}.slug`}
                                onChange={this.editField}
                                onBlur={this.props.reload}
                                update={!page.id && page.name}
                                slugify={true}
                                autoComplete="off"
                                required={true}
                                value={page.slug} name="slug"
                                maxLength={191} />
                        </label>
                    </div>
                </div>

                <label className="d-block mb-3">
                    <div className="mb-2">
                        <strong> Postavite link: (redirekcija na url) </strong>
                    </div>
                    <Input
                        placeholder="https://google.com"
                        path={page.id && `pages.${page.id}.link`}
                        onChange={this.editField}
                        onBlur={this.props.reload}
                        value={page.link} name="link"
                        maxLength={191} />
                </label>

                {!page.id &&
                <label className="d-block mb-3">
                    <div className="mb-2">
                        <strong> Ili kategorija: </strong>
                    </div>
                    <Select
                        name="category"
                        value={page.category}
                        onChange={this.editField}
                        options={[{name: "sve", id: "sve", slug: "sve"}, ...flatten(this.props.categories)]}
                        render={this.renderItem}/>
                </label>}

                <Slider header="SEO" className="mb-3">
                    <label className="d-block mb-3">
                        <div className="mb-2">
                            <strong> SEO title: </strong>
                        </div>
                        <Input
                            path={page.id && `pages.${page.id}.seo_title`}
                            onChange={this.editField}
                            onBlur={this.props.reload}
                            value={page.seo_title} name="seo_title"
                            autoComplete="off" />
                    </label>
                    <label className="d-block mb-3">
                        <div className="mb-2">
                            <strong> SEO description: </strong>
                        </div>
                        <Input
                            path={page.id && `pages.${page.id}.seo_description`}
                            onChange={this.editField}
                            onBlur={this.props.reload}
                            value={page.seo_description} name="seo_description"
                            autoComplete="off" />
                    </label>
                    <label className="d-block mb-3">
                        <div className="mb-2">
                            <strong> SEO keywords: </strong>
                        </div>
                        <Input
                            path={page.id && `pages.${page.id}.seo_keywords`}
                            onChange={this.editField}
                            onBlur={this.props.reload}
                            value={page.seo_keywords} name="seo_keywords"
                            autoComplete="off" />
                    </label>
                </Slider>


                {!page.id &&
                <button type="submit" className="btn btn-success">
                    Sačuvajte
                </button>}
                <button
                    style={{top: 4, right: 4}}
                    onClick={this.toggleOpen}
                    className="btn btn-primary btn-sm position-absolute">
                    <i className="fa fa-close" />
                </button>
            </form>
        </div>;
    };
}
const mapStateToProps = state => ({
    categories: state.categories
});
export default connect(mapStateToProps)(AddPage);
