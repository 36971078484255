import React from "react";



class Template2 extends React.Component {

    state = {

    }

    render = () => {
        return <div>
            Template 2
            <div>
                {this.props.children}
            </div>
        </div>

    };
}

export default Template2;
