import React from "react";
import Loader from "../../elements/Loader";
import {connect} from "react-redux";
import {deletePath, getNewsletters} from "../../../providers/hooks";


class Newsletters extends React.Component {

    state = {
        loading: true,
        all: []
    }

    delete = (item) => {
        var r = window.confirm("Želite da obrišete email?");
        if (r !== true) {
            return false;
        }
        deletePath({path: `newsletters.${item.id}`}).then((response) => {
            this.load();
        });
    }

    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }

        return <div className="container mt-5 mb-5">
            <div className="font-weight-bold mb-2 font_2">Prijavljeni za newsletter: </div>
            <div className="mb-3 dark_block">
                {this.state.all.newsletters.length === 0
                    ? <div className="font-weight-bold"> Nema prijavljenih za newsletter.</div>
                    : <div>
                        {this.state.all.newsletters.map((item) => {
                            return <div key={item.id} className="mb-2">
                                {item.email}
                                <button
                                    onClick={() => this.delete(item)}
                                    className="btn btn-sm btn-danger ml-3">
                                    <i className="fa fa-trash" />
                                </button>
                            </div>
                        })}
                    </div>}
            </div>


            <div className="font-weight-bold mb-2 mt-3 font_2">Prijavljeni ulogovani korisnici: </div>
            <div className="mb-3 dark_block">
                {this.state.all.users.length === 0
                    ? <div className="font-weight-bold"> Nema prijavljenih za newsletter.</div>
                    : <div>
                        {this.state.all.users.map((item) => {
                            return <div key={item.id} className="mb-2">
                                {item.email}
                            </div>
                        })}
                    </div>}
            </div>
        </div>
    };

    load = () => {
        getNewsletters().then((response) => {
            this.setState({...this.state, all: response.data, loading: false});
        })

    }

    componentDidMount() {
        this.load();
    }
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(Newsletters);
