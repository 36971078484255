import React from "react";
import Classes from "../Classes";
import Slider from "../Slider";

class ButtonSettings extends React.Component {

    render = () => {
        const node = this.props.node;
       return <Slider open={this.props.open} header="DUGME" className="mb-3">
           <Classes
               className="mb-3"
               title="Pozicija:"
               name="textAlign"
               classes={node.classes}
               onChange={this.props.save} />

           <div className="mb-3">
               Širina dugmeta:
               <input
                   name="width" group="style"
                   onChange={this.props.edit}
                   onBlur={this.props.save}
                   type="text" className="form-control" value={node.style?.width ? node.style.width : ""}
                   placeholder="px, rem, %" />
           </div>

           <div className="mb-3">
               <label className="m-0">
                   <input
                       name="borderRadius" group="style"
                       onChange={this.props.save}
                       type="checkbox"
                       value="50%" checked={node.style?.borderRadius || false}
                       className="mr-2" />
                   Okruglo dugme
               </label>
           </div>

           <div className="d-flex align-items-center">
               <input
                   type="color"
                   name="backgroundColor" group="style"
                   onChange={this.props.edit}
                   onBlur={this.props.save}
                   value={node.style?.backgroundColor ? node.style.backgroundColor : ""} />
               <label className="btn btn-sm btn-danger">
                   <input name="backgroundColor" group="style" value={false} onChange={this.props.save}
                          style={{visibility: "hidden", width: 2}} type="checkbox" />
                   <i className="fa fa-close" />
               </label>
               <div> &nbsp; ili &nbsp; </div>
               <Classes
                   name="buttonColor"
                   classes={node.classes}
                   onChange={this.props.save} />
           </div>
       </Slider>
    };
}

export default ButtonSettings;
