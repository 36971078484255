import React from "react";
import {connect} from "react-redux";
import {closeDrawer} from "../../../store/actions/drawersActions";
import Looper from "../../Looper";
import {pluck, processPrice, storage} from "../../../providers/helpers";
import {deleteFromBasket} from "../../../store/actions/basketActions";
import {getProducts} from "../../../providers/hooks";
import Loader from "../../../views/elements/Loader";
import routes from "../../../app/routes";
import Price from "../Price";
import {Link} from "react-router-dom";


class BasketDrawer extends React.Component {

    state = {
        products: false
    }

    delete = (product) => {
        let products = this.state.products.filter(item => item.unique !== product.unique);
        this.props.deleteFromBasket(product);
        this.setState({...this.state, products: products});
    }

    render = () => {
        if(this.state.products === false)       {
            return <Loader height={300} />
        }
        if(this.state.products.length === 0)        {
           return <Looper
                path="globals.structure.basket drawer empty"
                default={[{element: "text", classes: {textAlign: "text-center"}}]} />
        }
        let total = 0;
        return <div className="p-2 pt-3">
            {this.state.products.map((item) => {
                let extraPrice = 0;
                const filters = Object.values(item.filters);
                for(let i=0; i<filters.length; i++)    {
                    if(!filters[i].price) continue;
                    extraPrice = extraPrice + parseFloat(filters[i].price);
                }

                let fullPrice = (parseFloat(processPrice(item.product)) + extraPrice) * item.qty;
                total += fullPrice;

                return <div key={item.unique} className="text-center mb-2 pb-2">
                    <button onClick={() => this.delete(item)} className="btn btn-link float-right">
                        <i className="fa fa-trash" />
                    </button>
                    <div className="d-inline-block mb-2" style={{width: 100}}>
                        <img src={storage(item.product.image_name)} alt={item.product.name} className="img-fluid" />
                    </div>
                    <div>
                        <div className="mb-2">
                            <Link className="prewrap" to={routes.singleProduct.route(item.product)}><strong>{item.product.name}</strong></Link>
                        </div>
                        <div>
                            <div>
                                <Price item={item.product} />
                                {Object.values(item.filters).map(filter => {
                                    if(!filter.price) return null;
                                    return <div className="">+ {filter.price}</div>;
                                })}
                            </div>
                            <Price item={item.product} extra={extraPrice} /> x {item.qty} = <Price price={fullPrice} />
                        </div>
                    </div>
                </div>
            })}

            <div className="font_4 font-weight-bold text-center mb-3 mt-3">
                <span>Ukupno: </span>
                <span className="font_primary">
                    <Price price={total} />
                </span>
            </div>
            <div>
                <Link
                    className="w-100 btn btn-success text-white"
                    to={routes.basket.route}
                    onClick={() => this.props.closeDrawer("basket")}>
                    KORPA ({this.props.basket.length})
                </Link>
            </div>

            <div className="basket_close_btn d-none">
                <div className="font_secondary font-weight-bold mb-1 font_08 text-center"> ZATVORI </div>
                <button
                    onClick={() => this.props.closeDrawer('basket')}
                    className="btn btn-primary categories_btn_round"><i className="fa fa-close" /> </button>
            </div>
        </div>
    };

    componentDidMount() {
        if(this.props.basket.length === 0)      {
            this.setState({...this.state, products: []});
            return false;
        }
        let basket = pluck(this.props.basket, "unique","id");
        getProducts({ids: Object.values(basket)}).then((response) => {
            let holder = [];
            for(let j=0; j<this.props.basket.length; j++)      {
                let item = this.props.basket[j];
                const prod = response.data.data.find(prod => parseInt(prod.id) === parseInt(item.id));
                if(prod)        {
                    holder.push({...item, product: prod});
                }
                else        {
                    this.props.deleteFromBasket(item)
                }
            }
            this.setState({...this.state, products: holder});
        });
    }
}
const mapStateToProps = state => ({
    drawer: state.drawers.basket,
    basket: state.basket,
    categories: state.categories
});
const mapDispatchToProps = {
    closeDrawer: closeDrawer,
    deleteFromBasket: deleteFromBasket
}
export default connect(mapStateToProps, mapDispatchToProps)(BasketDrawer);
