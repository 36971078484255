import React from "react";

class Switch extends React.Component   {

    state = {
        checked: false
    };

    change = (e) => {
        this.setState({...this.state, checked: e.target.checked});
        this.props.onChange(e.target.checked);
    };

    render = () => {
        return <label className="pointer mb-0">
                <span className="switch mr-1">
                     <input type="checkbox" checked={this.state.checked} onChange={this.change} />
                    <span className="slider round" />
                </span>
            {this.props.label}
        </label>
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.default !== prevProps.default)    {
            if(this.props.default !== this.state.checked)       {
                this.setState({...this.state, checked: this.props.default});
            }
        }
    }
}

export default Switch;
