import update from "react-addons-update";
import {SET_USER, UPDATE_USER} from "../actions/userActions";
import {checkUser} from "../../providers/userProvider";

export default function(state = checkUser(), {type, payload})      {
    switch(type)        {
        case UPDATE_USER:
            return update(state, {[payload.field]: {$set: payload.value}});
        case SET_USER:
            return payload;
        default:
            return state;
    }
}