import React from "react";
import routes from "../../../app/routes";
import LiLink from "../../../views/elements/LiLink";


class Content extends React.Component {

    render = () => {
        if(this.props.brands.length === 0)      {
            return <div className="text-center weight_500 font_2 mt-5 mb-5">
                Prazna pretraga
            </div>
        }
        return <div className="row">
            {this.props.brands.map((item) => {
                let col1 = this.props.brands.length === 1 ? "col-12" : "col-md-3";
                let col2 = this.props.brands.length === 1 ? "col-md-3" : "col-12";
                return <div key={item.firstLetter} className={col1}>
                    <div className="round bordered p-1 mb-4 bcg-1-2">
                        <h4 className="mb-2 title_underline">
                            <span className="title">
                                {item.firstLetter.toUpperCase()}
                            </span>
                        </h4>
                        <div className="row list_buttons">
                            {item.items.map((brand) => {
                                return <div key={brand.brand} className={col2}>
                                    <LiLink to={routes.shop.route + "?brand=" + brand.brand} className="weight_500">
                                        {brand.brand}
                                        <span className="gray ml-1"> ({brand.total}) </span>
                                    </LiLink>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            })}
        </div>

    };
}

export default Content;
