import React from "react";
import {elements, grids, templates} from "./exports";
import dotProp from "dot-prop-immutable"
import Grid from "./Grid";
import {setPage} from "../store/actions/pageActions";
import {connect} from "react-redux";
import {setSelected} from "../store/actions/selectedActions";
import {Path} from "./Path";
import {setGlobals} from "../store/actions/globalsActions";
import PagesModal from "./fragments/pages/PagesModal";
import TextSettings from "./fragments/settings/TextSettings";
import GlobalsSettings from "./fragments/settings/GlobalsSettings";
import MarginSettings from "./fragments/settings/MarginSettings";
import {getGlobals, getPage, patchPath} from "../providers/hooks";
import PaddingSettings from "./fragments/settings/PaddingSettings";
import {merge} from "../providers/helpers";
import {setPreview} from "../store/actions/previewActions";
import Loader from "../views/elements/Loader";
import InfoSettings from "./fragments/settings/InfoSettings";
import SeoSettings from "./fragments/settings/SeoSettings";
import {seoReset, seoTitle} from "../providers/seoProvider";
import routes from "../app/routes";
import {Link} from "react-router-dom";
import ReactGA from "react-ga";


class DefaultSettings extends React.Component {
    render = () => {
        return <div />
    }
}
class Builder extends React.Component {

    state = {
        loading: false,
        size: "lg",
        slug: ""
    }

    edit = (e, overrideState = false) => {
        const name = e.target.name;
        let value = e.target.value;
        const pathObj = Path(this.props.selected);
        let group = false;
        if(e.target.getAttribute)       {
            group = e.target.getAttribute("group");
        }
        let path = pathObj.path;
        let newState = pathObj.globals ? this.props.globals : this.props.page;
        newState = merge(newState, overrideState);
        if (group) {
            path = path ? `${path}.${group}` : group;
            let groupNode = dotProp.get(newState, path);
            if (!groupNode || Array.isArray(groupNode)) {
                newState = dotProp.set(newState, path, {});
            }
        }
        if(name === "delete") {
            this.props.setSelected(false);
        } else {
            path = path ? `${path}.${name}` : name;
        }
        if (value === false || value === "false") {
            newState = dotProp.delete(newState, path);
        } else {
            newState = dotProp.set(newState, path, value);
        }
        if (pathObj.globals) {
            this.props.setGlobals(newState);
        } else {
            this.props.setPage(newState);
        }
        return newState;
    }

    save = (e) => {
        if(e.persist)       {
            e.persist();
        }
        let value = e.target.value;
        let name = e.target.name;

        if(name === "delete")       {
            var r = window.confirm("Želite da obrišete element?");
            if (r !== true) {
                return false;
            }
        }
        if(e.target.type === "checkbox")    {
            if(!e.target.checked)   {
                value = false;
            }
        }
        const pathObj = Path(this.props.selected);
        let newState = pathObj.globals ? this.props.globals : this.props.page;
        if (pathObj.globals) {
            getGlobals().then((response) => {
                e.target.value = value;
                newState = this.edit(e, response.data);
                patchPath({path: `globals.${this.props.globals.id}.structure`, value: newState.structure, description: name});
            });
        }
        else        {
            getPage({slug: this.props.page.slug}).then((response) => {
                e.target.value = value;
                newState = this.edit(e, response.data);
                patchPath({path: `pages.${this.props.page.id}.structure`, value: newState.structure, description: name});
            });
        }
    }

    compileStyles = () => {
        let style = "";
        const globals = this.props.globals.structure;
        if(globals.container)      {
            style += `.builder .container { max-width: ${globals.container}px !important }`
        }
        const fontColor = globals.fontColor || "#000000";
        const fontColorLight = globals.fontColorLight || "#ffffff";
        const primaryColor = globals.primary || "#ff0000";
        const secondaryColor = globals.secondary || "#0029ac";
        const backgroundColor = globals.background || "transparent";

        style += `.builder { color: ${fontColor}; }`
        style += `.builder .font_color a:link, .builder .font_color a:visited { color: ${fontColor}; }`
        style += `.builder .btn-link { color: ${fontColor}; }`
        style += `.builder_body { background: ${backgroundColor} !important; }`
        style += `.builder a:link, .builder a:visited { color: ${fontColor} }`;
        style += `.builder a:hover { color: ${secondaryColor} }`

        style += `.builder .loader {   border: 6px solid ${primaryColor}; border-top: 6px solid ${secondaryColor}; }`
        style += `.builder .arrow_left, .arrow_right { background: ${primaryColor}; }`
        style += `.builder .arrow_left:hover, .arrow_right:hover { background: ${secondaryColor}; }`
        style += `.builder .bottom_nav { background: ${primaryColor}; }`

        style += `.builder .border_primary { border-color: ${primaryColor} !important; }`

        style += `.link_button a:active, a.link_button:active, .link_button button:active, .link_button label:active, label.link_button:active { background: ${primaryColor} }`;
        style += `.chbx input:checked + span { background: ${primaryColor}; border: 1px solid ${primaryColor}; }`;

        style += `.bcg_secondary, .bcg_primary { color: ${fontColorLight} }`;
        style += `.bcg_secondary a:link, .bcg_secondary a:visited, .bcg_primary a:link, .bcg_primary a:visited { color: ${fontColorLight} }`;
        style += `.bcg_secondary a:hover { color: ${primaryColor} }`;
        style += `.bcg_secondary a:hover svg.white { fill: ${primaryColor}; stroke: ${primaryColor} }`;

        style += `.builder .font_light { color: ${fontColorLight} }`;
        style += `.builder .font_light a:link, .builder .font_light a:visited { color: ${fontColorLight} }`
        style += `.builder .font_light a:hover, .builder a.font_light:hover { color: ${primaryColor} }`
        style += `.builder a.font_light:hover .font_light { color: inherit }`

        style += `.font_primary { color: ${primaryColor} }`;
        style += `.bcg_primary { background-color: ${primaryColor} }`;
        style += `.builder .font_primary a:link, .builder .font_primary a:visited { color: ${primaryColor} }`
        style += `.builder .font_primary a:hover { color: ${fontColor} }`

        style += `.builder .btn-primary { background-color: ${primaryColor} !important; border-color: ${primaryColor} }`
        style += `.builder .btn-primary:active { background-color: ${secondaryColor} !important; }`
        style += `.builder .btn-secondary { background-color: ${secondaryColor}; border-color: ${secondaryColor} }`
        style += `.builder .btn-outline-primary { color: ${primaryColor}; border-color: ${primaryColor} }`
        style += `.builder .btn-outline-secondary { color: ${secondaryColor}; border-color: ${secondaryColor} }`
        style += `.builder .btn-outline-primary:hover { background-color: ${primaryColor}; color: white; }`
        style += `.builder .btn-outline-secondary:hover { background-color: ${secondaryColor}; color: white; }`
        style += `.builder .btn-white, .builder .btn-white:visited { background: white; }`
        style += `.builder .btn-white:hover { color: ${primaryColor}; }`

        style += `.builder input:focus, .builder select:focus, .builder textarea:focus { border: 2px solid ${primaryColor} !important; }`;
        style += `.builder_title > span:after { border-bottom: 7px solid ${secondaryColor} }`;

        style += `.builder_badge    { background: ${primaryColor} }`;
        style += `.builder_badge.white    { color: ${primaryColor} }`;

        style += `.font_secondary { color: ${secondaryColor} }`;
        style += `.bcg_secondary { background-color: ${secondaryColor} }`;
        style += `.builder .font_secondary a:link, .builder .font_secondary a:visited { color: ${secondaryColor} }`

        style += `.builder .floating_item .icon, .floating_item .slide, .floating_help .slided, .floating_help .help_toggle { background: ${primaryColor} }`
        style += `.builder .product_tags div { background: ${primaryColor} } .builder .product_tags div:nth-child(even) { background: ${secondaryColor} }`
        style += `.switch input:checked + .slider { background-color: ${primaryColor} }`

        if(globals.fontFamily)      {
            style += `body { font-family : ${globals.fontFamily}}`
        }

        const idName = "builder_style";
        var styleElement = document.getElementById(idName);
        if(!styleElement)       {
            styleElement = window.document.createElement('style');
            styleElement.setAttribute('type', 'text/css');
            styleElement.id = idName;
        }
        styleElement.innerText = style;
        document.getElementsByTagName('head')[0].appendChild(styleElement);
    }


    render = () => {
        const node = this.props.selected ? dotProp.get(this.props, this.props.selected, {}) : {};
        const SelectedTemplate = templates[this.props.globals.template] || Object.values(templates)[0];
        let Settings = DefaultSettings;
        if(node.grid && grids[node.grid] && grids[node.grid].settings)                      Settings = grids[node.grid].settings;
        if(node.element && elements[node.element] && elements[node.element].settings)       Settings = elements[node.element].settings;

        return <div className="d-md-flex">
            <div className="flex-grow-1 position-relative">

                {this.props.admin &&
                <>
                    <div className="p-2 builder_top_settings d-none d-md-block cms">
                        <Link to={routes.products.route}
                            className="btn btn-primary mr-2 btn-sm">
                            <i className="fa fa-arrow-left" /> CMS
                        </Link>

                        <button
                            onClick={() => this.props.setSelected("globals.structure")}
                            className="btn btn-primary mr-2 btn-sm">
                            Globalna podešavanja
                        </button>

                        <PagesModal />

                        <button
                            title="Ukljuciti ON - OFF za edit mode"
                            onClick={() => this.props.setPreview(!this.props.preview)}
                            className={!this.props.preview ? "btn btn-success mr-2 btn-sm" : "btn btn-danger mr-2 btn-sm"}>
                            {this.props.preview
                                ? <><i className="fa fa-lock mr-1" /> UKLJUČI EDIT MODE</>
                                : <>PREVIEW MODE</>}
                        </button>

                        {/*<span className="ml-2"> Veličina ekrana: </span>
                        {[
                            {title: "Prikaži za desktop", size: "lg", icon: "fa fa-desktop"},
                            {title: "Prikaži za tablet", size: "md", icon: "fa fa-tablet"},
                            {title: "Prikaži za mobilni", size: "sm", icon: "fa fa-mobile"},
                        ].map((item) => {
                            const className = item.size === this.state.size ? "btn-primary" : "btn-outline-primary";
                            return  <button key={item.title}
                                            onClick={() => this.setState({...this.state, size: item.size})}
                                            title={item.title}
                                            className={`btn ${className} mr-2 btn-sm`}>
                                <i className={item.icon} />
                            </button>
                        })}*/}
                    </div>
                    <div className="d-none d-md-block" style={{height: 47}} />
                </>}

                <div className={"builder " + this.state.size}>
                    <SelectedTemplate history={this.props.history}>

                        {this.props.page
                            ? this.props.page.structure.length === 0
                                ? (this.props.admin && !this.props.preview)
                                    ? <Grid path="page.structure" position={0} match={this.props.match} history={this.props.history} />
                                    : <div className="text-center font-weight-bold font_4 mt-5 mb-5">
                                        Stranica je prazna.
                                    </div>
                                : this.props.page.structure.map((item, i) => {
                                    return <Grid key={i} path="page.structure" position={i} match={this.props.match} history={this.props.history} />
                                })
                            : this.state.loading
                                ? <Loader height={300} />
                                : <div className="font_4 mt-5 mb-5 text-center font-weight-bold">
                                Ne postoji stranica.
                            </div>}

                    </SelectedTemplate>
                </div>
            </div>

            {this.props.selected && this.props.admin && !this.props.preview &&
            <>
                <div className="builder_drawer cms p-2 ml-4">

                    <div className="builder_drawer_header d-flex justify-content-between align-items-center">
                        <span className="font-weight-bold">{node.element || node.grid}</span>
                        <button onClick={() => this.props.setSelected(false)} className="btn btn-primary btn-sm">
                            <i className="fa fa-close" />
                        </button>
                    </div>

                    <div>
                        <Settings edit={this.edit} save={this.save} node={node} />

                        {node.element === "menu" &&
                        <>
                            <TextSettings edit={this.edit} save={this.save} node={node} />
                            <MarginSettings edit={this.edit} save={this.save} node={node} />
                            <PaddingSettings edit={this.edit} save={this.save} node={node} />
                        </>}

                        {this.props.selected === "globals.structure" &&
                        <>
                            <GlobalsSettings compileStyles={this.compileStyles} edit={this.edit} save={this.save} node={node} />
                            <SeoSettings edit={this.edit} save={this.save} node={node} />
                            <InfoSettings edit={this.edit} save={this.save} node={node} />
                            <MarginSettings edit={this.edit} save={this.save} node={node} />
                            <PaddingSettings edit={this.edit} save={this.save} node={node} />
                        </>}
                    </div>

                </div>
                <div className="builder_drawer_buffer" />
            </>}
        </div>
    };

    loadPage = () => {
        seoReset();
        this.props.setSelected(false);
        const urlArr = this.props.match.url.split("/");
        let slug = urlArr[1];
        if(slug === "") {
            if(this.props.menu.length === 0) {
                this.props.setPage(false);
                return false;
            }
            slug = this.props.menu[0].slug;
        }
        this.setState({...this.state, loading: true});
        getPage({slug: slug}).then((response) => {
            this.setState({...this.state, slug: slug, loading: false});
            seoReset(response.data);
            if(!response.data.seo_title)    {
                seoTitle(response.data.name);
            }
            this.props.setPage(response.data);
        });

        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    componentDidMount() {
        this.compileStyles();
        setTimeout(() => {
            document.body.classList.add("builder_body");
        }, 1)

        this.loadPage();
    }
    componentWillUnmount() {
        document.body.classList.remove("builder_body");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.match.url !== prevProps.match.url)      {
            this.loadPage();
        }
    }
}
const mapStateToProps = state => ({
    admin: state.admin,
    page: state.page,
    globals: state.globals,
    selected: state.selected,
    menu: state.menu,
    preview: state.preview
});
const mapDispatchToProps = {
    setPage: setPage,
    setGlobals: setGlobals,
    setSelected: setSelected,
    setPreview: setPreview
};
export default connect(mapStateToProps, mapDispatchToProps)(Builder);
