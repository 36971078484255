import React from 'react';


class Slider extends React.Component   {

    state = {
        open: this.props.open
    }

    render = () => {
        let cls = "builder_slider";
        if(this.props.className) cls += " " + this.props.className;
      return <div className={cls}>

          <button type="button" onClick={() => this.setState({...this.state, open: !this.state.open})}
              className="btn btn-primary btn-block font-weight-bold d-flex justify-content-between align-items-center">
              {this.props.header}
              {this.state.open
                ? <i className="fa fa-chevron-up" />
                : <i className="fa fa-chevron-down" />}
          </button>

          {this.state.open &&
          <div className="mt-3 p-2">
              {this.props.children}
          </div>}
      </div>
    };
}

export default Slider;
