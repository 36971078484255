import React from 'react';
import {connect} from "react-redux";
import CategorySlide from "./CategorySlide";


class CategoriesOnly extends React.Component {

    render = () => {
        return  <div className="link_button">
            <div className="builder_title mb-3 font_4 font-weight-bold">
                <span>
                    KATEGORIJE
                </span>
            </div>

            {this.props.categories.map((item, index) => {
                let open = false;
                if(this.props.category)  {
                    open = item.children.find(item => item.slug === this.props.category.slug);
                } else  {
                    if(index === 0) open = true;
                }
                return <CategorySlide item={item} open={!!open}
                                      key={item.id} object={this.props.object} category={this.props.category} />
            })}
        </div>
    };
}
const mapStateToProps = state => ({
    categories: state.categories
});
export default connect(mapStateToProps)(CategoriesOnly);
