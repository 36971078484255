import React from "react";
import Loader from "../../elements/Loader";
import {connect} from "react-redux";
import {deletePath, getAdmins} from "../../../providers/hooks";
import AddAdmin from "./AddAdmin";


class Administrators extends React.Component {

    state = {
        loading: true,
        admins: []
    }

    delete = (item) => {
        var r = window.confirm("Želite da obrišete administratora?");
        if (r !== true) {
            return false;
        }
        deletePath({path: `admins.${item.id}`}).then((response) => {
            this.load();
        });
    }

    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }

        return <div className="container mt-5 mb-5">
            <div className="mb-3">
                <AddAdmin reload={this.load} />
            </div>

            <table className="table dark_block">
                <thead>
                    <tr className="font-weight-bold">
                        <td>Ime</td>
                        <td>Email</td>
                        <td>Broj pogresnih logovanja</td>
                        <td width={100}> </td>
                    </tr>
                </thead>
                <tbody>
                    {this.state.admins.map((item) => {
                        return <tr key={item.id}>
                            <td>{item.name} {item.lastname}</td>
                            <td>{item.email}</td>
                            <td>{item.count_login_attempts}</td>
                            <td>
                                <button
                                    onClick={() => this.delete(item)}
                                    className="btn btn-danger btn-sm">
                                    Obrišite
                                </button>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>

        </div>
    };

    load = () => {
        getAdmins().then((response) => {
            this.setState({...this.state, admins: response.data, loading: false});
        })

    }

    componentDidMount() {
        this.load();
    }
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(Administrators);
