import React from 'react';
import {putCategory} from "../../../providers/hooks";
import update from "react-addons-update";
import Input from "../../elements/edit/Input";


class AddCategory extends React.Component   {

    default = {
        id: this.props.item ? this.props.item.id : false,
        name: this.props.item ? this.props.item.name : "",
        slug: this.props.item ? this.props.item.slug : "",
        parent: this.props.parent || null
    }
    state = {
        open: false,
        category: this.default
    };

    submit = (e) => {
        e.preventDefault();
        putCategory(this.state.category).then((response) => {
            this.props.reload();
            this.setState({...this.state, open: false, category: this.default});
        })
    }

    toggleOpen = () => {
        this.setState({...this.state, open: !this.state.open});
    }

    editField = (e) => {
        const field = e.target.name;
        let value = e.target.value;
        this.setState(update(this.state, {category: {[field]: {$set: value}} }));
    }

    render = () => {
        if(!this.state.open)     {
            return <button
                    onClick={this.toggleOpen}
                    className="btn btn-primary">
                {this.props.item
                    ? <span> <i className="fa fa-edit" /> Prepravite kategoriju </span>
                    : <span> <i className="fa fa-plus" /> Dodajte {this.props.parent && "pod"}kategoriju </span>}
            </button>
        }
        const category = this.state.category;
        return <div className="light_block p-3 position-relative">
            <form action="#" onSubmit={this.submit}>
                <div className="mb-2">
                    Naziv kategorije:
                </div>
                <div className="row mb-1">
                    <div className="col-md-6">
                        <label className="d-block">
                            <Input
                                path={category.id && `categories.${category.id}.name`}
                                onChange={this.editField}
                                onBlur={this.props.reload}
                                value={category.name} name="name"
                                autoComplete="off"
                                maxLength={191} required />
                        </label>
                    </div>
                    <div className="col-md-6">
                        <label className="d-block">
                            <div className="mb-2">
                                <strong> Slug kategorije: </strong>
                            </div>
                            <Input
                                path={category.id && `categories.${category.id}.slug`}
                                onChange={this.editField}
                                onBlur={this.props.reload}
                                update={!category.id && category.name}
                                slugify={true}
                                value={category.slug} name="slug"
                                maxLength={191} required />
                        </label>
                    </div>
                </div>
                {!category.id &&
                <button type="submit" className="btn btn-success">
                    Sačuvajte
                </button>}
            </form>
            <button
                style={{top: 4, right: 4}}
                onClick={this.toggleOpen}
                className="btn btn-primary btn-sm position-absolute">
                <i className="fa fa-close" />
            </button>
        </div>;
    };
}
export default AddCategory;
