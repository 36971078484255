import React from "react";
import MarginSettings from "../../fragments/settings/MarginSettings";
import PaddingSettings from "../../fragments/settings/PaddingSettings";
import DeleteNodeSettings from "../../fragments/settings/DeleteNodeSettings";


class GallerySet extends React.Component {

    render = () => {
        return <>
            <div className="mb-3">
                Broj kolona:
                <select
                    className="form-control"
                    name="columns"
                    onChange={this.props.save}
                    value={this.props.node.columns || 4}>
                    {[1,2,3,4,6].map((item) => {
                        return <option key={item} value={item}>
                            {item}
                        </option>
                    })}
                </select>
            </div>

            <div className="mb-3">
                Visina slike (0 - 100) razmera:
                <input
                    onChange={this.props.save}
                    name="paddingTop"
                    type="text" className="form-control"
                    value={this.props.node.paddingTop || 100} />
            </div>

            <div className="mb-3">
                Veličina (prekrivanje):
                <select
                    className="form-control"
                    name="backgroundSize"
                    onChange={this.props.save}
                    value={this.props.node.backgroundSize || "cover"}>
                    {["cover", "contain"].map((item) => {
                        return <option key={item} value={item}>
                            {item}
                        </option>
                    })}
                </select>
            </div>

            <div className="mb-3">
                Pozicija slike:
                <select
                    className="form-control"
                    name="backgroundPosition"
                    onChange={this.props.save}
                    value={this.props.node.backgroundPosition || "center"}>
                    {["center", "top", "bottom"].map((item) => {
                        return <option key={item} value={item}>
                            {item}
                        </option>
                    })}
                </select>
            </div>

            <MarginSettings edit={this.props.edit} save={this.props.save} node={this.props.node}/>
            <PaddingSettings edit={this.props.edit} save={this.props.save} node={this.props.node}/>
            <DeleteNodeSettings save={this.props.save} node={this.props.node}/>
        </>
    };

}

export default GallerySet;
