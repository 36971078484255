import React from "react";
import {connect} from "react-redux";
import Lightbox from 'react-image-lightbox';
import {storage} from "../../../providers/helpers";

class ProductGallery extends React.Component {

    state = {
        photoIndex: 0,
        isOpen: false,
        images: []
    };

    toggleLightbox = () => {
        this.setState({...this.state, isOpen: !this.state.isOpen})
    };



    render = () => {
        let images = this.state.images;
        return <div>
            {this.state.isOpen && (
                <Lightbox
                    mainSrc={storage(images[this.state.photoIndex],true)}
                    nextSrc={storage(images[(this.state.photoIndex + 1) % images.length], true)}
                    prevSrc={storage(images[(this.state.photoIndex + images.length - 1) % images.length], true)}
                    onCloseRequest={this.toggleLightbox}
                    onMovePrevRequest={() =>
                        this.setState({...this.state, photoIndex: (this.state.photoIndex + images.length - 1) % images.length})
                    }
                    onMoveNextRequest={() =>
                        this.setState({...this.state, photoIndex: (this.state.photoIndex + 1) % images.length})
                    }
                />
            )}

            <div className="row">
                {/*<div className="ml-3 product_gallery_tmb d-md-block d-none">
                    {images.map((item, i) => {
                        return  <div key={i} className="border mb-3 pointer">
                            <div onClick={() => this.setState({...this.state, photoIndex: i})}>
                                <img src={storage(item)} className="img-fluid" alt={item} />
                            </div>
                        </div>
                    })}
                </div>*/}
                <div className="col-md">
                    <div onClick={this.toggleLightbox} className="pointer d-none d-md-block">
                        <img src={storage(images[this.state.photoIndex],true)} className="img-fluid mb-3" alt={this.props.product.name} />
                    </div>
                    <div className="d-md-none">
                        <img src={storage(images[this.state.photoIndex],true)} className="img-fluid mb-3" alt={this.props.product.name} />
                    </div>
                </div>
            </div>

            <div className="row no-gutters mb-3">
                {images.map((item, i) => {
                    return  <div key={i} className="col-3">
                        <div className="m-1 border" onClick={() => this.setState({...this.state, photoIndex: i})}>
                            <div className="bcg_image" style={{backgroundImage: `url(${storage(item)})`}} />
                        </div>
                    </div>
                })}
            </div>
        </div>
    };

    componentDidMount() {
        let images = this.props.product.images.map((item) => {
            return item.name;
        });
        if(this.props.product.image_name)  images.unshift(this.props.product.image_name);
        this.setState({...this.state, images: images});
    }
}

const mapStateToProps = state => ({
    categories: state.categories
});
export default connect(mapStateToProps)(ProductGallery);
