

export function payment_method(method) {
    const methods = {
        1: "Plaćanje pouzećem",
        2: "Direktna bankovna transakcija",
        3: "Plaćanje platnom karticom"
    }
    if(methods[method]) return methods[method];
}

export function order_statuses() {
    return  {
        1: {title: "U obradi", color: "#ff9e00", value: 1},
        2: {title: "Primljeno", color: "#006fca", value: 2},
        3: {title: "Poslato", color: "#009a56", value: 3},
        4: {title: "Email", color: "#009a56", value: 4},
        5: {title: "Isporučeno", color: "#009a56", value: 5},
        6: {title: "Otkazano", color: "#d00000", value: 6},
    }
}
export function order_status(method) {
    const methods = order_statuses();
    if(methods[method]) return methods[method];
}

export function delivery_price() {
    return 380;
}
export function delivery_price_over() {
    return 5000;
}
export function delivery_services() {
    return {
        1: {id: 1, name: "Post Express", logo: "/images/logo-Post-Express.jpg", active: true},
        2: {id: 2, name: "Daily Express", logo: "/images/logo-Dex.png", active: false},
        3: {id: 3, name: "Bex Express", logo: "/images/logo-Bex.png", active: true},
    }
}
export function delivery_service(service) {
    return delivery_services()[service];
}
