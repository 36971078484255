import slugify from "slugify";

export default {
    // CMS
    index: {route: "/", name: "Naslovna"},
    adminLogin: {route: "/cms/login", name: "Ulogujte se"},

    products: {route: "/cms/proizvodi", name: "Proizvodi"},
    promo: {route: "/cms/promo", name: "Promo kod"},
    product: {route: function (id) {
            if(!id) return "/cms/proizvod";
            return `/cms/proizvod/${id}`
        }, name: "Proizvod"},

    categories: {route: "/cms/kategorije", name: "Kategorije"},
    filters: {route: "/cms/filteri", name: "Filteri"},
    orders: {route: "/cms/orders", name: "Porudžbine"},
    order: {route:  (id) => `/order/${id}`, name: "Porudžbina"},
    storage: {route: "/cms/storage", name: "Galerija"},
    administrators: {route: "/cms/administratori", name: "Administratori"},
    newsletters: {route: "/cms/newsletters", name: "Newsletter"},
    users: {route: "/cms/korisnici", name: "Korisnici"},
    options: {route: "/cms/dodatne/opcije", name: "Dodatne opcije"},

    shop: {route: "/shop", name: "Shop"},
    blogs: {route: "/vesti", name: "Vesti"},
    cmsBlogs: {route: "/cms/vesti", name: "Blog"},
    basket: {route: "/korpa"},
    thankYou: {route: (code) => `/thank-you/${code}`},
    orderStatus: {route: (code) => `/status-porudzbine/${code}`},
    singleProduct: {route: function (product) {
            const slug = product.slug ? `/${product.slug}` : "";
            return `/proizvod/${product.id}${slug}`
        }},
    singleCategory: {route: function (category) {
            return `/shop/${category.slug}`
        }},
    singleBlog: {route: function (blog) {
            return `/vest/${blog.id}/${slugify(blog.title, {lower: true})}`
        }},
    cmsSingleBlog: {route: function (id) {
            if(!id) return "/cms/vest";
            return `/cms/vest/${id}`
        }},


    userLogin: {route: "/user-login"},
    userRegister: {route: "/user-register"},
    userPasswordRecovery: {route: "/user-password-recovery"},
    userDashboard: {route: "/user-dashboard", name: "Informacije o nalogu"},
    userOrders: {route: "/user-orders"},
    userNewsletter: {route: "/user-newsletter"},
}