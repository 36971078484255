import React from "react";

class DeleteNodeSettings extends React.Component {

    render = () => {
        const node = this.props.node;
       return <div>
           <div className="mb-3">
               <label className="m-0">
                   <input
                       name="mdHidden" group="classes"
                       value="d-none d-md-block"
                       onChange={this.props.save}
                       type="checkbox" className="mr-2"
                       checked={node.classes?.mdHidden || false} />
                   Ne prikazuj na mobilnom
               </label>
           </div>
           <button
               name="delete"
               value={false}
               onClick={this.props.save} className="btn btn-danger">
               Obrišite element
           </button>
       </div>
    };
}

export default DeleteNodeSettings;
