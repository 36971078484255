import React from "react";
import {connect} from "react-redux";
import Drawer from "../../../views/framework/Drawer";
import {closeDrawer} from "../../../store/actions/drawersActions";
import Looper from "../../Looper";
import routes from "../../../app/routes";
import LiLink from "../../../views/elements/LiLink";


class CategoriesDrawer extends React.Component {

    state = {
        category: this.props.categories[0],
        slide: false
    }

    slide = (cat) => {
        this.setState({...this.state, category: cat});
        setTimeout(() => {
            this.setState({...this.state, slide: true});
        }, 100);
    }

    changeCategory = (cat) => {
        this.setState({...this.state, category: cat});
    };

    item = (item) => {
        return <li className="d-flex mt-1" key={item.id}
                   onMouseEnter={() =>this.changeCategory(item)}>
            <LiLink
                onClick={() => this.props.closeDrawer("categories")}
                to={routes.singleCategory.route(item)}>
                <span className="font-weight-bold"> {item.name} </span>
            </LiLink>
            {item.children.length > 0 &&
            <button onClick={() =>this.slide(item)} className="d-md-none w-auto btn btn-sm pl-3 pr-3 btn-primary">
                <i className="fa fa-chevron-right" />
            </button>}
        </li>
    }

    render = () => {
        const drawer = this.props.drawer;
       return <Drawer
            noOverlay={true}
            anchor="top"
            width="100%"
            maxWidth="100%"
            show={drawer.status}
            close={() => this.props.closeDrawer('categories')}>

           <div className="container mt-5 mb-5">
               <div className="d-flex align-items-center justify-content-between mb-3 mr-2">
                   <Looper
                       path="globals.structure.categories drawer logo"
                       default={[{element: "image"}]} />

                       <div className="close_btn">
                           <div className="font_secondary font-weight-bold mb-1 font_08 text-center"> ZATVORI </div>
                           <button
                               onClick={() => this.props.closeDrawer('categories')}
                               className="btn btn-primary categories_btn_round"><i className="fa fa-close" /> </button>
                       </div>


               </div>

               <div className="row">
                   {this.props.categories.map((item) => {
                       return <div key={item.id} className="col-md-4 mb-4">
                           <div className="mb-3">
                               <LiLink
                                   onClick={() => this.props.closeDrawer("categories")}
                                   to={routes.singleCategory.route(item)}>
                                   <div className="d-flex align-items-center">
                                       <div className="builder_title font-weight-bold font_10">
                                           <span> {item.name} </span>
                                       </div>
                                       {item.hot &&
                                       <div className="bcg_red pill ml-1 mt-2">
                                           HOT
                                       </div>}
                                       {item.action &&
                                       <div className="bcg_secondary pill ml-1 mt-2">
                                           AKCIJA
                                       </div>}
                                       {item.new &&
                                       <div className="bcg_primary pill ml-1 mt-2">
                                           NEW
                                       </div>}
                                   </div>
                               </LiLink>
                           </div>

                           <ul className="list-unstyled link_button ml-3">
                               {item.children.map((child) => {
                                  return <li key={child.id}>
                                      <LiLink
                                          onClick={() => this.props.closeDrawer("categories")}
                                          to={routes.singleCategory.route(child)}>

                                          <div className="d-flex align-items-center">
                                              <div className="font-weight-bold mr-1">
                                                  {child.name}
                                                  <span className="gray"> ({child.count}) </span>
                                              </div>

                                              {child.hot &&
                                              <div className="bcg_red pill ml-1">
                                                  HOT
                                              </div>}
                                              {child.action &&
                                              <div className="bcg_secondary pill ml-1">
                                                  AKCIJA
                                              </div>}
                                              {child.new &&
                                              <div className="bcg_primary pill ml-1">
                                                  NEW
                                              </div>}
                                          </div>
                                      </LiLink>

                                  </li>
                               })}
                           </ul>
                       </div>
                   })}
               </div>
           </div>
       </Drawer>
    };
}
const mapStateToProps = state => ({
    categories: state.categories,
    drawer: state.drawers.categories
});
const mapDispatchToProps = {
    closeDrawer: closeDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(CategoriesDrawer);
