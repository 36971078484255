import React from "react";
import Input from "../../../elements/edit/Input";
import elements from "./elements";
import {deletePath, putOptionSelect} from "../../../../providers/hooks";
import Price from "../../../../builder/theme/Price";
import AddElement from "../AddElement";
import Checkbox from "../../../elements/edit/Checkbox";


class SelectOption extends React.Component {

    state = {
        edit: false,
    }

    add = () => {
        putOptionSelect({element: this.props.item.id}).then((response) => {
            this.props.reload();
        })
    }

    delete = (id) => {
        var r = window.confirm("Želite da obrišete opciju?");
        if (r !== true) {
            return false;
        }
        deletePath({path: "option_selects." + id}).then((response) => {
            this.props.reload();
        })
    }

    changed = (e) => {
        const name = e.target.name;
        const val = e.target.value;
        const select1 = this.props.item.selects.find(select => select.id == val);
        const duplicate = {...this.props.item};
        if(select1?.price)   {
            duplicate.price = select1.price;
        }
        this.props.editField(name, val, duplicate);
    }

    render = () => {
        const item = this.props.item;
        if(this.props.admin)     {
            return <div className="d-flex justify-content-between">
                <div className="flex-grow-1 mr-3">
                    <div className="mb-2">
                        Element: <strong> {elements[item.element].name} </strong>
                    </div>
                    {this.state.edit
                        ?  <>
                            <div className="mb-2">
                                Polje (naslov):
                                <Input
                                    path={`option_elements.${item.id}.field_1`}
                                    onBlur={() => this.props.reload()}
                                    value={item.field_1} name="field_1"
                                    maxLength={199} autoComplete="off"  />
                            </div>
                            <div className="mb-2">
                                <div className="mb-2 font-weight-bold">Opcije:</div>

                                {item.selects.map((select, index) => {
                                    return <div key={select.id} className="mb-2 d-flex align-items-center">
                                        <strong className="mr-3">{index+1}.</strong>
                                        <Input
                                            path={`option_selects.${select.id}.name`}
                                            onBlur={() => this.props.reload()}
                                            value={select.name} name="name"
                                            maxLength={191} autoComplete="off"  />

                                            <div  className="ml-2" style={{width: 100}}>
                                            <Input
                                                nullable={true}
                                                price={true}
                                                placeholder="cena"
                                                path={`option_selects.${select.id}.price`}
                                                onBlur={() => this.props.reload()}
                                                value={select.price} name="price"
                                                maxLength={191} autoComplete="off"  />
                                            </div>

                                            <button
                                                onClick={() => this.delete(select.id)}
                                                className="btn btn-danger btn-sm ml-2">
                                                Obrišite
                                            </button>
                                    </div>
                                })}
                                <div className="mt-3">
                                    <button
                                        onClick={this.add}
                                        className="btn btn-info btn-sm">
                                        Dodajte opciju
                                    </button>
                                </div>
                            </div>
                            {this.props.edit}
                        </>
                        : <>
                            <div className="mb-2">  Polje (naslov): <strong> {item.field_1} </strong></div>
                            <div className="mb-2">  Opcije:
                                {item.selects.map((select, index) => {
                                    return <div key={select.id}>
                                        <div className="d-flex align-items-center pb-2">
                                            <strong className="mr-4">{index+1}. {select.name} </strong>
                                            {(!item.parent && !this.props.child) && <AddElement
                                                title="Dodajte podelement"
                                                reload={this.props.reload}
                                                select={select.id} />}

                                        </div>
                                        {select.children.length > 0 &&
                                        <div className="mb-3">
                                            {select.children.map((child) => {
                                                const Child = elements[child.element].component;
                                                return <div key={child.id}
                                                            className="ml-5 pt-2 pb-2" style={{borderTop: "1px solid grey"}}>
                                                    <Child item={child} admin={true} reload={this.props.reload} child={true}
                                                           edit={<>
                                                               <Checkbox
                                                                   path={`option_elements.${child.id}.required`}
                                                                   checked={child.required} name="required"
                                                                   appendTrue={<span>Polje je obavezno</span>}
                                                                   appendFalse={<span>Polje je obavezno</span>} />

                                                               <div className="mb-2">
                                                                   Cena opcije:
                                                                   <Input
                                                                       nullable={true}
                                                                       price={true}
                                                                       path={`option_elements.${child.id}.price`}
                                                                       onBlur={() => this.load()}
                                                                       value={child.price} name="price"
                                                                       maxLength={191} autoComplete="off"  />
                                                               </div>
                                                           </>}
                                                           show={<>
                                                               <div className="mb-2">
                                                                   <strong> Polje {!child.required && "ni"}je obavezno </strong>
                                                               </div>
                                                               <div> Cena: <strong><Price price={child.price} /> </strong> </div>

                                                           </>}
                                                           buttons={<>
                                                               <div className="mb-1">
                                                                   <button onClick={() => this.props.deleteElement(child.id)} className="btn btn-danger btn-sm btn-block">Obrišite</button>
                                                               </div>
                                                           </>}
                                                    />
                                                </div>
                                            })}
                                        </div>}
                                    </div>
                                })}
                            </div>
                            {this.props.show}
                        </>}
                </div>
                <div>
                    <div className="mb-1">
                        <button
                            onClick={() => this.setState({...this.state, edit: !this.state.edit})}
                            className="btn btn-success btn-sm btn-block">{this.state.edit ? "Vrati" : "Prepravite"}</button>
                    </div>
                    {this.props.buttons}
                </div>
            </div>
        }

        if(!this.props.editField)   {
            const selected = item.selects.find(select => select.id == this.props.order[item.id].value);
            return <div>
                <div className="font-weight-bold">{item.field_1} {item.required && "*"} </div>
                <div>
                    {selected?.name}
                    {item.price &&
                    <span className="ml-2"> (Cena opcije: +<Price price={item.price} />)</span>}
                    {selected?.price &&
                    <span className="ml-2"> (Cena opcije: +<Price price={selected.price} />)</span>}
                </div>

                {item.selects.map((select) => {
                    return <div key={select.id}>
                        {select.children.map((child) => {
                            if(!this.props.order[item.id]) return "";
                            if(!this.props.order[item.id].value) return "";
                            if(this.props.order[item.id].value != select.id) return "";
                            const El = elements[child.element].component;
                            return  <div key={child.id} className="mt-2 ml-4">
                                <El
                                    item={child}
                                    order={this.props.order} />
                            </div>
                        })}
                    </div>
                })}

                {this.props.order[item.id] && this.props.order[item.id].value && item.children &&
                <div>
                    {item.children.map((child) => {
                        const El = elements[child.element].component;
                        return  <div key={child.id} className="mt-2 ml-4">
                            <El
                                item={child}
                                order={this.props.order} />
                        </div>
                    })}
                </div>}
            </div>
        }

        return <div>
            <div className="font-weight-bold mb-1"> {item.field_1} {item.required && "*"} </div>
            <div>
                <select
                    name={item.field_1}
                    onChange={this.changed}
                    required={item.required}
                    className="form-control">
                        <option value=""> </option>
                        {item.selects.map((select) => {
                            if(!select.name || select.name === "") return "";
                            if(!select.price && item.price)     {
                                select.price = item.price
                            }
                            return <option key={select.id} value={select.id}>
                                {select.name} &nbsp; &nbsp; &nbsp; {select.price && `(${select.price} din.)`}
                            </option>
                        })}
                </select>
            </div>

            {item.selects.map((select) => {
                return <div key={select.id} className="mt-3">
                    {select.children.map((child) => {
                        if(!this.props.order[item.id]) return "";
                        if(!this.props.order[item.id].value) return "";
                        if(this.props.order[item.id].value != select.id) return "";
                        const El = elements[child.element].component;
                        return  <div key={child.id} className="mb-3 ml-4">
                            <El
                                item={{...child, parent: item.id}}
                                editField={this.props.editField}
                                order={this.props.order} />
                        </div>
                    })}
                </div>
            })}

            {this.props.order[item.id] && this.props.order[item.id].value && item.children &&
            <div className="mt-3">
                {item.children.map((child) => {
                    const El = elements[child.element].component;
                    return  <div key={child.id} className="mb-3 ml-4">
                        <El
                            item={child}
                            editField={this.props.editField}
                            order={this.props.order} />
                    </div>
                })}
            </div>}

        </div>
    };
}
export default SelectOption;