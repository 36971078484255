import React from "react";
import Loader from "../../elements/Loader";
import {getBlog, patchPath, putBlog, putProduct} from "../../../providers/hooks";
import update from "react-addons-update";
import Image from "../../elements/edit/Image";
import Textarea from "../../elements/edit/Textarea";
import Input from "../../elements/edit/Input";
import RichTextEditor from "../../../app/Editor/RichTextEditor";
import {filesDrawer, openDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";
import routes from "../../../app/routes";



class Blog extends React.Component {

    default = {
        id: false,
        image_id: null,
        title: "",
        slug: "",
        content: null,
        description: null,
    }
    state = {
        loading: true,
        blog: {},
        errors: false
    }


    editField = (e) => {
        const field = e.target.name;
        let value = e.target.value;
        if(e.target.type === "checkbox")    {
            if(!e.target.checked)   {
                value = null;
            }
        }
        this.setState(update(this.state, {blog: {[field]: {$set: value}} }));
    }

    saveContent = (content) => {
        let t = this;
        patchPath({path: `blogs.${this.state.blog.id}.content`, value: JSON.stringify(content)}).then((response) => {
            t.props.openDrawer("alert");
            t.setState(update(t.state, {blog: {content: {$set: JSON.stringify(content)}} }));
        })
    }
    changeContent = (content) => {
        this.setState(update(this.state, {blog: {content: {$set: JSON.stringify(content)}} }));
    }

    submit = (e) => {
        e.preventDefault();
        putBlog(this.state.blog).then((response) => {
            this.props.history.push(routes.cmsBlogs.route);
        }).catch((errors) => {
            this.setState({...this.state, errors: errors});
        });
    }

    form = () => {
        const blog = this.state.blog;
        return <>
            <div className="dark_block mb-3">
                <div className="row mb-3">
                    <div className="col-md-3">
                        <div className="border">
                            <Image
                                api={this.props.api}
                                path={blog.id && `blogs.${blog.id}.image_id`}
                                onChange={this.editField}
                                value={blog.image_name}
                                name="image_id" />
                        </div>
                        <div className="text-center font-italic font_09"> Kliknite na sliku da promenite </div>
                    </div>
                    <div className="col-md-9">
                        <div className="mb-3">
                            <label className="d-block">
                                <div className="mb-2"> Naslov: </div>
                                <Input
                                    path={blog.id && `blogs.${blog.id}.title`}
                                    onChange={this.editField}
                                    value={blog.title} name="title" required />
                            </label>
                        </div>

                        <div>
                            <label className="d-block">
                                <div className="mb-2"> Kratak opis: </div>
                                <Textarea
                                    path={blog.id && `blogs.${blog.id}.description`}
                                    onChange={this.editField}
                                    value={blog.description} name="description"
                                />
                            </label>
                        </div>

                    </div>
                </div>
            </div>

            <div className="bg-white mb-3">
                <RichTextEditor
                    onChange={blog.id ? undefined : this.changeContent}
                    onSave={blog.id ? this.saveContent : undefined}
                    content={blog.content}
                    filesDrawer={this.props.filesDrawer} />
            </div>
        </>
    }

    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }

        return <div className="container mb-5">
            {this.state.blog.id
                ? this.form()
                : <form action="#" onSubmit={this.submit}>
                    {this.form()}
                    <button type="submit" className="btn btn-lg font-weight-bold btn-primary">Sačuvajte vest</button>
                </form>}



        </div>
    };

    componentDidMount() {
        const id = this.props.match.params.id;
        if(!id) {
            this.setState({...this.state, loading: false, blog: this.default});
            return false;
        }
        getBlog({id: id}).then((response) => {
            this.setState({...this.state, loading: false, blog: response.data});
        });
    }
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    filesDrawer: filesDrawer,
    openDrawer: openDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(Blog);
