import React from "react";
import Classes from "../Classes";
import Slider from "../Slider";
import {filesDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";
import {storage} from "../../../providers/helpers";

class ImageSettings extends React.Component {


    render = () => {
        const node = this.props.node;
       return  <Slider open={true} header="SLIKA" className="mb-3">
           <div className="mb-3">
               {node.src && <img src={storage(node.src)} alt="big" width={50} className="mr-2" />}
                <button
                    onClick={() => this.props.filesDrawer({status: true, callback:
                            (files) => this.props.save({target: {name: "src", value: files[0].name}})
                    })}
                    className="btn btn-sm btn-success">
                    <i className="fa fa-image mr-1" />  Promenite sliku
                </button>
           </div>
           <div className="mb-3">
               {node.mobileSrc && <img src={storage(node.mobileSrc)} alt="big" width={50} className="mr-2" />}
               <button
                   onClick={() => this.props.filesDrawer({status: true, callback:
                       (files) => this.props.save({target: {name: "mobileSrc", value: files[0].name}})
                   })}
                   className="btn btn-sm btn-success">
                   <i className="fa fa-image mr-1" />  Slika na mobilnom
               </button>
           </div>

           <div className="mb-3">
               Opis slike:
               <input
                   name="alt"
                   onChange={this.props.edit}
                   onBlur={this.props.save}
                   type="text" className="form-control" value={node.alt || ""}
                   placeholder="Kratak opis" />
           </div>

           <div className="mb-3">
               Širina slike:
               <input
                   name="width"
                   onChange={this.props.edit}
                   onBlur={this.props.save}
                   type="text" className="form-control" value={node.width || ""}
                   placeholder="broj: 100, procenti: 100%" />
           </div>

           <div className="mb-3">
               <label className="m-0">
               <input
                   name="big"
                   onChange={this.props.save}
                   type="checkbox"
                   value={1} checked={node.big || false}
                   className="mr-2" />
               Velika slika
               </label>
           </div>

           <Classes
               title="Pozicija slike:"
               name="textAlign"
               classes={node.classes}
               onChange={this.props.save} />

       </Slider>
    };
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    filesDrawer: filesDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(ImageSettings);
