import React from "react";
import Input from "../../../elements/edit/Input";
import elements from "./elements";
import Price from "../../../../builder/theme/Price";
import axios from "axios";
import {server} from "../../../../providers/hooks";
import {storage} from "../../../../providers/helpers";


class ImageOption extends React.Component {

    state = {
        maxSize: 8, // MB
        edit: false,
        loader: 0,
        preload: false
    }

    upload = (e) => {
        const file = e.target.files[0];
        if(!file) return false;

        let size = file.size/1000000;  // BYTES to MEGABYTES
        if(size > this.state.maxSize) {
            alert('Fajl ' + file.name + ' je veci od '+ this.state.maxSize +' MB');
            return false;
        }


        // SET PRELOAD
        const reader = new FileReader();
        reader.onload = (e) => {
            if (e.target.result) {
                this.setState({...this.state, preload: e.target.result});
            }
        }
        reader.readAsDataURL(file);

        var formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('bucket', 'options');
        formData.append('full', 'true');
        axios({
            method: 'POST',
            url: server('files'),
            data: formData,
            onUploadProgress: progressEvent => {
                let previewProcess = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({...this.state, loader: previewProcess});
                if(previewProcess === 100 && this.state.preload)  {

                }
            }
        }).then((response) => {
            this.props.editField(this.props.item.field_1, response.data.name, this.props.item)
        });
    }

    render = () => {
        const item = this.props.item;

        if(this.props.admin)     {
            return <div className="d-flex justify-content-between">
                <div className="flex-grow-1 mr-3">
                    <div className="mb-2">
                        Element: <strong> {elements[item.element].name} </strong>
                    </div>
                    {this.state.edit
                        ?  <>
                            <div className="mb-2">
                                Polje (naslov):
                                <Input
                                    path={`option_elements.${item.id}.field_1`}
                                    onBlur={() => this.props.reload()}
                                    value={item.field_1} name="field_1"
                                    maxLength={199} autoComplete="off"  />
                            </div>
                            {this.props.edit}
                        </>
                        : <>
                            <div className="mb-2">  Polje (naslov): <strong> {item.field_1} </strong></div>
                            {this.props.show}
                        </>}
                </div>
                <div>
                    <div className="mb-1">
                        <button
                            onClick={() => this.setState({...this.state, edit: !this.state.edit})}
                            className="btn btn-success btn-sm btn-block">{this.state.edit ? "Vrati" : "Prepravite"}</button>
                    </div>
                    {this.props.buttons}
                </div>
            </div>
        }


        if(!this.props.editField)   {
            return <div>
                <div className="font-weight-bold mb-1"> {item.field_1} {item.required && "*"} </div>
                {item.price &&
                <div> Cena opcije: +<Price price={item.price} /></div>}
                <img src={storage(this.props.order[item.id]?.value)} width={60} className="" alt="option" />
            </div>
        }

        return <div>
            <div className="font-weight-bold mb-1"> {item.field_1} {item.required && "*"} </div>
            <div>
                {this.state.preload &&
                <img src={this.state.preload} width={80} className="mr-3" alt="preview" />}

                <input type="file"
                       name={item.id}
                       onChange={this.upload}
                       //value={this.props.order[item.field_1] ? this.props.order[item.field_1].selectedValue : ""}
                       className="mb-2"
                       required={item.required} />

                {item.price &&
                <div> Cena opcije: +<Price price={item.price} /></div>}
            </div>
        </div>
    };
}
export default ImageOption;