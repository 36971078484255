import React from "react";
import {storage} from "../../providers/helpers";


class Image extends React.Component {

    render = () => {
        let classname = "";
        if(this.props.node.classes)     {
            classname += Object.values(this.props.node.classes).join(" ");
        }
        const mobileSrc = this.props.node.mobileSrc || this.props.node.src;
        return <div className={classname}>
            <img
                className="img-fluid d-none d-md-inline-block"
                src={storage(this.props.node.src, this.props.node.big)}
                width={this.props.node.width} alt={this.props.node.alt} />
            <img
                className="img-fluid d-md-none"
                src={storage(mobileSrc)} width={this.props.node.width}
                alt={this.props.node.alt} />
        </div>
    };

}
export default Image;
